const CONFIG = {
  // FILE_URL : 'http://www.elgroup.kr',
  // FILE_URL : 'http://dev.www.elgroup.kr',
  FILE_URL : () => {
    if (window.location.host === 'localhost:3000') return 'http://dev.www.elgroup.kr'
    else if (window.location.host === 'dev.www.elgroup.kr') return ''
    else return ''
  },
  // API_CALL_URL: 'http://serviceapi.elgroup.kr',
  // API_CALL_URL: 'http://dev.serviceapi.elgroup.kr',
  API_CALL_URL: () => {
    // if (window.location.host === 'www.elgroup.kr') return 'http://serviceapi.elgroup.kr'
    // else return 'http://dev.serviceapi.elgroup.kr'

    if (window.location.host === 'localhost:3000') return 'http://dev.serviceapi.elgroup.kr'
    else if(window.location.host === 'dev.www.elgroup.kr') return 'http://dev.serviceapi.elgroup.kr'
    else return 'https://serviceapi.elgroup.kr'
  },
}

export default CONFIG