import React from "react";
import shop from "../assets/brands/healthy-shop-button.png";
import Mshop from "../assets/brands/mobile-healthy-shop-button.png";

const ShopButton = () => {
  return (
    <div className="shop-button">
      <img className="show-pc" alt="image" src={shop} />
      <img className="show-mobile" alt="image" src={Mshop} />
    </div>
  )
}

export default ShopButton