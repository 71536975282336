import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import FamilyNavigation from "../../components/FamilyNavigation";

const ElRise = () => {

  useEffect(() => {
    window.location.href = "http://elrise.kr"
  }, [])

  return (
    <>
      <Helmet>
        <title>이엘라이즈</title>
        <meta name="description" content="라이프스타일 기업. 화장품, 건강, 생활용품, 패션 브랜드, 상품 소개, 온라인 스토어" />
        <meta name="Keywords" content="(주)이엘라이즈, 이엘라이즈, ELrise, elrise" />
        <meta property="og:title" content="이엘라이즈" />
        <meta property="og:site_name" content="이엘라이즈" />
        <meta property="og:url" content="http://elrise.kr" />
        <meta property="og:description" content="라이프스타일 기업. 화장품, 건강, 생활용품, 패션 브랜드, 상품 소개, 온라인 스토어" />
        <meta name="twitter:title" content="이엘라이즈" />
        <meta name="twitter:description" content="라이프스타일 기업. 화장품, 건강, 생활용품, 패션 브랜드, 상품 소개, 온라인 스토어" />
        <meta name="twitter:domain" content="이엘라이즈" />
        <link rel="canonical" href="http://elrise.kr" />
      </Helmet>
      <div className="family-sub-el-rise">
        <div className="text-wrap">
          <div className="title-1">ELrise</div>
          <div className="title-2">_이엘<span>라이즈</span></div>
          <div className="description"><b>라이프 스타일 기업</b>으로<br className="show-mobile" /> 웰메이드 상품 개발을 통해 <br />생활 문화의 가치를 제고하고<br className="show-mobile" /> 풍요로운 삶을 제안합니다
          </div>
        </div>
        <FamilyNavigation />
      </div>
    </>
  )
}

export default ElRise