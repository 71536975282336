import React from "react";

const defaultProps = {
  zIntex: 1
};

interface I_WhiteSquare {
  zIntex?: number
}

const WhiteSquare = ({zIntex}: I_WhiteSquare) => {
  return <div className="white-square" style={{zIndex: `${zIntex}`}} />
}

WhiteSquare.defaultProps = defaultProps;

export default WhiteSquare