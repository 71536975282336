import React from "react";

const EllispacePrivacy = () => {
  return (
    <div>
      <section className="policy-area">
        <h1>이엘파크 서비스 개인정보<span className="change">처리</span>방침</h1>
        <p>주식회사 ‘이엘파크’(이하 ‘회사’라 합니다.)는 ‘회원’의 개인정보를 적극적으로 보호하며, “정보통신망 이용촉진 및 정보보호 등에 관한 법률”, “개인정보보호법”, “위치정보의 보호 및 이용에 관한 법률” 및 유관기관이 제정한 지침을 준수함으로써 ‘회원’의 권익 보호에 최선을 다하고 있습니다. ‘회사’는 본 개인정보<span className="change">처리</span>방침을 통해 ‘회원’이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있는지, ‘회사’가 ‘회원’의 개인정보를 보호하기 위해 어떠한 노력을 기울이고 있는지를 알려드리고자 합니다.</p>
            <h2>1. 수집/이용하는 개인정보의 항목 및 수집 방법</h2>
                <h3 className="pl10">1) 수집/이용하는 개인정보 항목</h3>
                <p className="pl10">계정 생성 및 로그인 서비스 제공, 원활한 고객상담을 위해 아래의 개인정보를 수집 및 이용합니다.</p>
                    <ol className="list-01 pl25">
                         <li className="mt5">
                            <strong>① 회원 가입 시 또는 하기의 플랫폼에서 게임 서비스 이용 시 아래와 같은 정보가 수집됩니다. 단, 일부 게임의 경우 로그인 서비스만 제공하며 개인정보를 수집 및 저장하지 않습니다.</strong>
                            <ul>
                                <li className="mt5">이엘파크 플랫폼 (단독 서비스) : 회원 ID, 비밀번호, 닉네임</li>
                                <li className="mt5">구글 계정으로 로그인 : 구글회원 식별 정보, 닉네임</li>
                                <li className="mt5">애플 계정으로 로그인 : 애플회원 식별 정보, 닉네임</li>
                            </ul>
                        </li>
                         <li className="mt5">
                            <strong>② 서비스 이용 과정에서 아래와 같은 정보들이 생성되어 수집 될 수 있습니다.</strong>
                            <ul>
                                <li className="mt5">프로필 사진, Email, 이름, 전화번호, 주소</li>
                                <li className="mt5">서비스 이용 기록, 약관 동의 기록, 불량 이용 기록, 접속 로그, 결제 기록, IP Address</li>
                                <li className="mt5">휴대폰전화번호, 모바일 기기 모델명, 통신사 정보, OS 정보 및 버전 기록, IMEI, Android ID, 구글 광고ID(안드로이드 OS만 해당), 접속 네트워크의 종류</li>
                                <li className="mt5">애플 iOS의 경우 IDFA(광고주 식별자)</li>
                            </ul>
                        </li>
                         <li className="mt5">
                            <strong>③ 이벤트 프로모션에 참여하거나 선택형 서비스를 이용하는 경우 별도 동의 하에 아래의 정보를 수집할 수 있습니다.</strong>
                            <ul>
                                <li className="mt5">이름, 주소, 생년월일, 성별, 휴대폰번호, 전화번호, 우편번호, Email, 신분을 확인 할 수 있는 증서</li>
                            </ul>
                        </li>
                    </ol>
                <h3 className="pl10">2) 개인정보 수집 방법</h3>
                    <ol className="list-01 pl25">
                         <li className="mt5"><strong>① 모바일 기기 및 회사의 서비스 가입 시 동의 절차 제공을 통해 수집</strong></li>
                        <li className="mt5"><strong>② 프로모션 및 이벤트 진행을 위해 별도의 동의 절차를 통해 수집</strong></li>
                        <li className="mt5"><strong>③ 회사와 서비스 제공 관련 제휴 관계에 있는 플랫폼을 통해 자동으로 수집</strong></li>
                        <li className="mt5"><strong>④ 1:1 서면 상담 및 전화상담을 통해 ‘회원’의 자발적 제공 또는 필요에 의해 요청 후 수집</strong></li>
                        <li className="mt5"><strong>⑤ 생성정보 수집 툴을 통한 수집</strong></li>
                    </ol>
            <h2>2. 개인정보의 수집 및 이용 목적</h2>
            <p>‘회사’는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
                <h3 className="pl10">1) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 콘텐츠 제공, 구매 및 요금 결제, 물품배송 또는 청구지 등 발송</h3>
                <h3 className="pl10">2) 회원 관리</h3>
                <p className="pl10">콘텐츠 제공에 따른 개인 식별, 중복가입 확인, 불량회원 관리, 비정상 사용자 적발 및 이용제한, 계약이행, 분쟁처리</p>
                <h3 className="pl10">3) 신규 서비스 개발 및 마케팅 광고 활용</h3>
                <p className="pl10">신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계</p>
            <h2>3. 개인정보의 보유 및 이용 기간</h2>
            <p>회사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 및 동법 시행령에 따라 연속하여 1년 동안 이엘파크 서비스를 이용하지 않은 회원(이하 &quot;휴면계정&quot;이라 한다)의 개인정보를 보호하기 위해 계약을 해지하고 개인정보 파기 등 필요한 조치를 취할 수 있습니다. 이 경우, 조치일 30일 전까지 필요한 조치가 취해진다는 사실과 개인정보 보유기간 만료일 및 개인정보의 항목을 &quot;회원&quot;에게 통지합니다. 이용자의 동의 하에 수집된 개인정보는 회원 자격이 유지되는 동안 보유 및 이용되며, 해지를 요청한 경우에는 재생할 수 없는 방법에 의하여 디스크에서 완전히 삭제하며 추후 열람이나 이용이 불가능한 상태로 처리됩니다. 단, 회사는 개인정보 도용 등과 같은 부정이용으로 인한 피해 발생 시 복구와 피해자 보호 등을 위해 회원의 ID는 해지한 날로부터 최대 30일 동안 임시로 보관할 수 있으며 이후에는 재생할 수 없는 방법으로 완전히 삭제합니다. 개별적으로 회원의 동의를 받은 경우나 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우는 예외로 하며, 그 외 다른 목적으로는 절대 사용하지 않습니다.</p>
                <h3 className="pl10">1) 회사 내부 방침에 의한 정보 보유 사유</h3>
                    <ol className="list-01 pl25">
                         <li className="mt5">
                            <strong>① 탈퇴 회원에 대한 개인 정보</strong>
                            <ul>
                                 <li className="mt5">보존 이유 : 약관 및 내부 정책에 따라 부정 이용의 방지를 위함</li>
                                 <li className="mt5">보존 기간 : 3개월</li>
                            </ul>
                        </li>
                         <li className="mt5">
                            <strong>② 고객 상담 신청 기록, 고객 상담 내용 및 서면 양식</strong>
                            <ul>
                                 <li className="mt5">보존 이유 : 이용자 분쟁 관련 상담 진행</li>
                         <li className="mt5">보존 기간 : 3년</li>
                            </ul>
                        </li>
                    </ol>
                <h3 className="pl10">2) 관계 법령에 의한 정보 보유 사유</h3>
                    <ol className="list-01 pl25">
                         <li className="mt5">
                            <strong>① 계약 또는 청약철회 등에 관한 기록</strong>
                            <ul>
                                 <li className="mt5">보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                                 <li className="mt5">보존 기간 : 5년</li>
                            </ul>
                        </li>
                         <li className="mt5">
                            <strong>② 대금 결제 및 재화 등의 공급에 관한 기록</strong>
                            <ul>
                                 <li className="mt5">보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                                 <li className="mt5">보존 기간 : 5년</li>
                            </ul>
                        </li>
                         <li className="mt5">
                            <strong>③ 세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</strong>
                            <ul>
                                 <li className="mt5">보존 이유 : 국세기본법</li>
                                 <li className="mt5">보존 기간 : 5년</li>
                            </ul>
                        </li>
                         <li className="mt5">
                            <strong>④ 소비자의 불만 또는 분쟁처리에 관한 기록</strong>
                            <ul>
                                 <li className="mt5">보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                                 <li className="mt5">보존 기간 : 3년</li>
                            </ul>
                        </li>
                         <li className="mt5">
                            <strong>⑤ 본인확인에 관한 기록</strong>
                            <ul>
                                 <li className="mt5">보존 이유 : 정보통신망 이용 촉진 및 정보보호 등에 관한 법률</li>
                                 <li className="mt5">보존 기간 : 6개월</li>
                            </ul>
                        </li>
                         <li className="mt5">
                            <strong>⑥ 방문에 관한 기록 : 접속 로그, 서비스 이용 기록 등</strong>
                            <ul>
                                 <li className="mt5">보존 이유 : 통신비밀보호법</li>
                                 <li className="mt5">보존 기간 : 3개월</li>
                            </ul>
                        </li>
                    </ol>
            <h2>4. 개인정보의 파기 절차 및 방법</h2>
            <p>회사는 개인정보의 수집 목적 또는 제공 받은 목적이 달성된 때에는 ‘회원’의 개인정보를 지체 없이 파기합니다.</p>
                <h4>1) 파기 절차</h4>
                    <ol className="list-01 pl25">
                         <li className="mt5">
                            <strong>① ‘회원’이 회원 가입 등을 위해 입력한 정보는 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장 된 후 파기 됩니다.<br/>(개인정보<span className="change">처리</span>방침 3조 &apos;개인정보의 보유 및 이용 기간&apos; 참조)</strong>
                        </li>
                    </ol>
                <h4>2) 파기 방법</h4>
                    <ol className="list-01 pl25">
                         <li className="mt5"><strong>① 전자적 파일 형태로 저장 된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</strong></li>
                         <li className="mt5"><strong>② 종이에 출력 된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</strong></li>
                    </ol>
            <h2>5. 개인정보의 공유 및 제공</h2>
            <p>회사는 회원의 개인정보를 수집 시 “2. 개인정보의 수집 및 이용목적”에서 고지한 범위 내에서 사용하며, 원칙적으로 회원의 사전 동의 없이는 회원의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.</p>
                <h4>1) 회원이 사전에 동의한 경우</h4>
                <h4>2) 서비스 제공에 따른 요금 정산을 위해 필요한 경우</h4>
                <h4>3) 통계작성, 학술 연구 또는 시장 조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 가공하여 제공하는 경우</h4>
                <h4>4) 전기통신 기본법, 전기통신 사업법 등 관계 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</h4>
                <h4>5) 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 전기통신기본법, 전기통신사업법, 지방세법, 소비자보호법, 한국은행법, 형사소송법 등 법률에 특별한 규정이 있는 경우</h4>

            <h2>6. 수집한 개인정보의 <span className="change">처리</span>위탁</h2>
            <p>회사는 게임 서비스 제공 및 회원의 편의 증진을 위하여 아래와 같이 개인정보<span className="change">를 처리</span>위탁하고 있습니다. 회사는 관계법령에 따라 개인정보 <span className="change">처리</span>위탁 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정, 운영하고 있습니다.</p>
            <ul className="pl10 mt10">
                 <li className="mt5">수탁업체명 : (주)다우기술</li>
                 <li className="mt5">수탁업무내용 : 문자서비스 전송 시스템 운영</li>
                 <li className="mt5">개인정보 이용 기간 : 문자 전송 완료 시 또는 위탁계약 종료 시</li>
            </ul>
            <ul className="pl10 mt10">
                 <li className="mt5">수탁업체명 : (주)써머스플랫폼</li>
                 <li className="mt5">수탁업무내용 : 문자서비스 전송 시스템 운영</li>
                 <li className="mt5">개인정보 이용 기간 : 문자 전송 완료 시 또는 위탁계약 종료 시</li>
            </ul>

            <h2>7. 회원 및 법정 대리인의 권리와 그 행사방법</h2>
                <h3 className="pl10">1) 회원은 언제든지 회원 본인의 개인정보를 확인, 조회하거나 수정할 수 있으며 설정&gt;계정&gt;&apos;계정삭제+탈퇴신청&apos;에서 수집 및 이용 동의를 철회하거나 탈퇴를 할 수 있습니다.
혹은 개인정보보호책임자에게 전화, 이메일 등을 통해 개인정보 열람, 정정, 삭제, 처리정지, 동의 철회를 요구할 수 있습니다.</h3>
                <h3 className="pl10">2) 회원이 자신의 개인정보에 대한 정정 또는 삭제를 요구하는 경우 회사는 본인 여부를 확인한 후 지체없이 필요한 조치를 취합니다. 또한, 운영정책 제 9장(이용제한 정책)에 해당하는 경우 개인정보 관리 책임자의 판단 하에 회원 ID삭제 등 개인정보를 파기할 수 있습니다.</h3>
                <h3 className="pl10">3) 회사는 만 14세 미만의 아동의 개인정보를 수집ㆍ이용ㆍ제공하는 경우 반드시 법정대리인의 동의를 받습니다.</h3>
                <h3 className="pl10">4) 회원의 개인정보에 대한 열람, 수정은 본인 또는 만 14세 미만 아동의 법정대리인의 요구에 따라 본인 여부 확인 후 가능합니다.</h3>
                <h3 className="pl10">5) 회원이 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 합니다.</h3>
                <h3 className="pl10">6) 회사는 회원의 요청에 의해 해지 또는 삭제된 개인정보에 대해 &quot;개인정보의 보유 및 이용 기간&quot;에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</h3>
            <h2>8. 개인정보 자동 수집 장치의 설치/운영에 관한 사항</h2>
            <p>회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 회원의 첫 가입 시 전화번호 및 기기식별번호 (Android ID 또는 IMEI)를 자동으로 수집합니다. 회원이 전화번호 및 기기식별번호를 자동으로 수집하는 것을 거부하는 경우에는 서비스 이용에 제약이 발생할 수 있습니다. <span className="change">회사는 대표 웹사이트에서 이용자들의 정보를 저장하고 수시로 불러오는 &apos;쿠키(Cookie)&apos;를 사용하고 있지 않습니다.</span></p>
            <h2>9. 개인정보의 기술적, 관리적, 물리적 보호대책</h2>
            <p>회사는 회원의 개인정보를 <span className="change">처리</span>함에 있어 개인정보가 분실, 도난, 유출, 변조, 훼손되지 않고 안전성을 확보하기 위하여 다음과 같은 기술적 및 관리적 대책을 강구하고 있습니다.</p>
                <h4 className="pl10">1) 비밀번호 암호화</h4>
                <p className="pl10">회원의 개인정보는 비밀번호에 의해 철저히 보호되고 있으며, 비밀번호는 암호화 저장 및 관리되고 있습니다. 회원의 비밀번호는 본인만 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서 회원 여러분께서는 비밀번호를 누구에게도 알려주시면 안됩니다.</p>
                <h4 className="pl10">2) 해킹 등에 대비한 대책</h4>
                <p className="pl10">회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 회원의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있으며 암호 알고리즘 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 안정성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.</p>
                <h4 className="pl10">3) <span className="change">처리</span> 직원의 최소화 및 교육</h4>
                <p className="pl10">회사는 회원의 개인정보 관련 <span className="change">처리를</span> 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며 담당자에 대한 수시 교육을 통하여 회사의 개인정보<span className="change">처리</span>방침의 준수를 항상 강조하고 있습니다.</p>
                <h4 className="pl10">4) 개인정보보호전담기구의 운영</h4>
                <p className="pl10">사내 전담기구 등을 통하여 회사의 개인정보<span className="change">처리</span>방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.</p>
                <h4 className="pl10">5) 출입 및 보관 통제</h4>
                <p className="pl10">개인정보를 <span className="change">처리</span> 및 보관하는 지역은 보안구역으로 정의하고 비인가자나 외부인의 출입을 통제하고 있으며, 개인정보를 포함하는 인쇄물, 출력물 수기 기록 및 외장형 저장 매체 등은 잠금장치가 있는 안전한 장소에 보관해 잠금 장치를 해제하지 않고서는 그 내용이나 해당 정보의 유무 확인이 불가하도록 관리하고 있습니다.</p>
                
            <h2>10. 개인정보에 관한 민원서비스</h2>
            <p>회사는 회원의 개인정보와 관련하여 회원 여러분들의 의견을 수렴하고 있으며 불만을 처리하기 위하여 모든 절차와 방법을 마련하고 있습니다. 기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
            <ul className="pl10 mt10">
                 <li className="mt5">한국정보보호진흥은(KISA)내 개인정보침해신고센터(http://www.118.or.kr, 전화 118)</li>
                <li className="mt5">정보보호마크 인증위원회(http://www.eprivacy.or.kr, 전화 02-550-9531~2)</li>
                <li className="mt5">대검찰청 첨단범죄 수사과(http://www.spo.go.kr, 전화 02-3480-2000)</li>
                <li className="mt5">경찰청 사이버테러대응센터(http://www.ctrc.go.kr, 전화 182)</li>
            </ul>
            <h2>11. 기타</h2>
                <h3 className="pl10">1) 회사는 회원에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부 사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다.</h3>
                <h3 className="pl10">2) 회사가 포함하고 있는 링크를 클릭하여 타 사이트의 페이지로 옮겨갈 경우 해당 사이트의 개인정보<span className="change">처리</span>방침은 회사와 무관하므로 새로 방문한 사이트의 정책을 확인하시기 바랍니다.</h3>
                <h3 className="pl10">3) 회원은 제공하신 개인정보를 최신의 상태로 정확하게 입력하여 사고를 예방하여 주시기 바랍니다. 회원이 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 본인에게 있으며, 타인 정보의 도용 등 허위 정보를 입력할 경우 계정의 이용이 제한 또는 삭제 될 수 있습니다.</h3>
                <h3 className="pl10">4) 회원은 개인정보를 보호 받을 권리를 보유하나 이와 동시에 본인의 정보를 스스로 보호하고 또한 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 본인의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약, 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 관련 법령 등에 의해 처벌 받을 수 있습니다.</h3>
                <h3 className="pl10">5) 회원은 개인정보를 입력함에 있어 부정확하거나 잘못 된 정보를 입력하여 발생 되는 서비스 이용상의 불이익 및 물질적 손해는 전적으로 본인에게 책임이 있습니다.</h3>
            <h2>12. 고지의 의무</h2>
            <p>현 개인정보<span className="change">처리</span>방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전에 홈페이지를 통해 변경 이유 및 내용 등을 공지하도록 하겠습니다.</p>
            <h2>&lt;부칙&gt;</h2>
            <p>본 방침은 2022년 7월 1일부터 시행합니다.</p>
            
        </section>

      
    </div>
  )
}

export default EllispacePrivacy
