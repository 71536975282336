/* eslint-disable react/no-unknown-property */
import React, { useRef, useState, WheelEvent } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import _ from 'lodash';

import CONFIG from "../../config";

import Footer from '../../components/Footer';
import PageTitle from "../../components/PageTitle";

const Brands = () => {

  const [showFooter, setShowFooter] = useState(false)
  const [currentVerticalSlideIndex, setCurrentVerticalSlideIndex] = useState(0);
  const [currentHorizontalSlideIndex, setCurrentHorizontalSlideIndex] = useState(0);
  const verticalSliderRef = useRef<any>(null);
  const horizontalSliderRef = useRef<any>(null);
  const verticalSliderOption = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    // beforeChange: (currentSlide: any, nextSlide: any) => {},
    afterChange: (currentSlide: any) => {
      setCurrentVerticalSlideIndex(currentSlide)
    }
  };

  const horizontalSliderOption = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide: any) => {
      setCurrentHorizontalSlideIndex(currentSlide)
    }
  };

  const goToSlide = (page: number) => horizontalSliderRef.current.slickGoTo(page)

  const onWheelHandler = _.throttle((event: WheelEvent<HTMLDivElement>) => {
    if(event.nativeEvent.deltaY < 0) {
      if(currentVerticalSlideIndex === 1 && showFooter) {
        verticalSliderRef.current.slickGoTo(1)
        setTimeout(() => {setShowFooter(false)}, 300)
      } else verticalSliderRef.current.slickPrev()
    }
    else {
      if(currentVerticalSlideIndex === 1) setShowFooter(true)
      verticalSliderRef.current.slickNext()
    }
  }, 300)

  const renderSlideButtons = () => {
    return (
      <div className="slide-button-wrap">
        <div onClick={() => {goToSlide(0)}} className={`slide-button slide-button-1 ${currentHorizontalSlideIndex === 0 ? 'color' : ''}`} />
        <div onClick={() => {goToSlide(1)}} className={`slide-button slide-button-2 ${currentHorizontalSlideIndex === 1 ? 'color' : ''}`} />
        <div onClick={() => {goToSlide(2)}} className={`slide-button slide-button-3 ${currentHorizontalSlideIndex === 2 ? 'color' : ''}`} />
        <div onClick={() => {goToSlide(3)}} className={`slide-button slide-button-4 ${currentHorizontalSlideIndex === 3 ? 'color' : ''}`} />
        <div onClick={() => {goToSlide(4)}} className={`slide-button slide-button-5 ${currentHorizontalSlideIndex === 4 ? 'color' : ''}`} />
      </div>
    )
  }

  return (
    <div className="brands" onWheel={onWheelHandler}>
      <Slider {...verticalSliderOption} ref={verticalSliderRef}>
        <div className="vertical-section vertical-section-1">
          <div style={{ zIndex: '-1' }}>.</div>
          <div className="overlay" />
          <PageTitle title="Brands" description={<p>웰메이드 상품 개발을 통해<br className="show-mobile" /> 생활 문화의 가치를 제고하고<br /> 풍요로운 삶을 제안합니다</p>} />
          <div className="background-video">
            <video autoPlay loop muted playsInline webkit-playsInline x5-playsInline preload="auto">
              <source src={`${CONFIG.FILE_URL()}/storage/video/brand.mp4`} type='video/mp4' />
            </video>
          </div>
        </div>
        <div className="vertical-section vertical-section-2">
          <Slider {...horizontalSliderOption} ref={horizontalSliderRef}>
            <div className="horizontal-section horizontal-section-1">
              <div className="text-wrap white">
                <p className="text-1" style={{backgroundColor: '#BB3792'}}>Healthy</p>
                <div className="text-2">헬시플레이스</div>
                <div className="text-3">건강한 삶을 위한<br /> 균형 잡힌 푸드라이프를 약속합니다</div>
                <Link to="/healthy">
                  <div className="more-button" />
                </Link>
              </div>
              {/* {renderSlideButtons()} */}
            </div>
            <div className="horizontal-section horizontal-section-2">
              <div className="text-wrap white">
                <p className="text-1" style={{backgroundColor: '#CEA95A'}}>Beauty & Fashion</p>
                <div className="text-2">인헤버</div>
                <div className="text-3">처음 바른 피부결 그대로 가볍고 실키하게<br />
                  시간이 흘러도 변하지 않는<br className="show-mobile" /> 메이크업 노하우를 전합니다<br />
                </div>
                <Link to="/inhever">
                  <div className="more-button" />
                </Link>
              </div>
              {/* {renderSlideButtons()} */}
            </div>
            <div className="horizontal-section horizontal-section-3">
              <div className="text-wrap black">
                <p className="text-1" style={{backgroundColor: '#9573EB'}}>Beauty & Fashion</p>
                <div className="text-2">아이빔</div>
                <div className="text-3">내가 만든 나의 브랜드 IBIM<br /> 당신의 아이디어가 곧 브랜드가 됩니다</div>
                <Link to="/ibim">
                  <div className="more-button" />
                </Link>
              </div>
              {/* {renderSlideButtons()} */}
            </div>
            <div className="horizontal-section horizontal-section-4">
              <div className="text-wrap white">
                <p className="text-1" style={{backgroundColor: '#B3C021'}}>Beauty & Fashion</p>
                <div className="text-2">지샤인</div>
                <div className="text-3">시대를 초월한 우아함과 하이엔드 퀄리티<br />SIGNATURE TREND GOLF WEAR <b>G/SHI</b></div>
                <Link to="/gshi">
                  <div className="more-button" />
                </Link>
              </div>
              {/* {renderSlideButtons()} */}
            </div>
            <div className="horizontal-section horizontal-section-5">
              <div className="text-wrap black">
                <p className="text-1" style={{backgroundColor: '#583464'}}>Beauty & Fashion</p>
                <div className="text-2">루트헤어</div>
                <div className="text-3">진정한 젊음 두피에서 답을 찾다<br />두피부터 시작하는<br className="show-mobile" /> 안티에이징 프리미엄 클리닉!</div>
                <Link to="/ruthair">
                  <div className="more-button" />
                </Link>
              </div>
              {/* {renderSlideButtons()} */}
            </div>
          </Slider>
          {renderSlideButtons()}
        </div>
      </Slider>

      {showFooter && <Footer />}
    </div>
  )
}

export default Brands