import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import FamilyNavigation from "../../components/FamilyNavigation";

const ElMedia = () => {

  useEffect(() => {
    window.location.href = "http://elmedia.kr"
  }, [])

  return (
    <>
      <Helmet>
        <title>이엘미디어</title>
        <meta name="description" content="엔터테인먼트 콘텐츠 크리에이션 기업. 예능, 교양, 드라마 등 영상 콘텐츠 제작"/>
        <meta name="Keywords" content="(주)이엘미디어, 이엘미디어, ELmedia, elmedia"/>
        <meta property="og:title" content="이엘미디어"/>
        <meta property="og:site_name" content="이엘미디어"/>
        <meta property="og:url" content="http://elmedia.kr"/>
        <meta property="og:description" content="엔터테인먼트 콘텐츠 크리에이션 기업. 예능, 교양, 드라마 등 영상 콘텐츠 제작"/>
        <meta name="twitter:title" content="이엘미디어"/>
        <meta name="twitter:description" content="엔터테인먼트 콘텐츠 크리에이션 기업. 예능, 교양, 드라마 등 영상 콘텐츠 제작"/>
        <meta name="twitter:domain" content="이엘미디어"/>
        <link rel="canonical" href="http://elmedia.kr"/>
      </Helmet>
      <div className="family-sub-el-media">
        <div className="text-wrap">
          <div className="title-1">ELmedia</div>
          <div className="title-2">_이엘<span>미디어</span></div>
          <div className="description"><b>엔터테인먼트 콘텐츠 크리에이션<br className="show-mobile" /> 기업</b>으로<br className="show-pc" />
            영상 콘텐츠 제작 및 <br className="show-mobile" /> 독자적인 IP 개발을 통해 <br className="show-mobile" /> 글로벌 가치 확장에 주력합니다</div>
        </div>
        <FamilyNavigation />
      </div>
    </>
  )
}

export default ElMedia