/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import axios from 'axios';
import moment from 'moment';
import AOS from 'aos';
import _ from 'lodash';

import CONFIG from '../../config';

import PageTitle from '../../components/PageTitle';
import WhiteSquare from '../../components/WhiteSquare';
import vodClose from '../../assets/images/management-vod-close.png';
import moreButton from '../../assets/images/management-news-more.png';

interface IArtistItem {
  idx: string;
  artist_type: string;
  artist_name: string;
  artist_eng_name: string;
  photo_thumb: string;
  use_detail: string;
}

interface INewsItem {
  idx: string;
  news_thumb_name: string;
  news_title: string;
  press_date: string;
}

interface IVodItem {
  idx: string;
  thumb_name: string;
  vod_title: string;
  vod_youtube: string;
}

const Management = () => {
  const navigate = useNavigate();
  const [showVod, setShowVod] = useState(false);
  const [vodUrl, setVodUrl] = useState('');
  const [artists, setArtists] = useState([]);
  const [news, setNews] = useState([]);
  const [vods, setVods] = useState([]);
  const [newsPageObject, setNewsPageObject] = useState({
    total: 0,
    current: 1,
  });
  const [vodPageObject, setVodPageObject] = useState({
    total: 0,
    current: 1,
  });

  const onClickArtist = (index?: string) => navigate(`/management-artist/${index}`);
  const closeModal = () => {
    setShowVod(false);
  };
  const onClickVod = (value: string) => {
    setVodUrl(value);
    setShowVod(true);
  };

  const fetchNewsData = () => {
    axios
      .get(
        `${CONFIG.API_CALL_URL()}/entertainment/news_list.php?sstart=${(newsPageObject.current - 1) * 6}&slistscale=6`,
      )
      .then((response) => {
        setNews(news.concat(response.data.Result_Data.data));
        setNewsPageObject({
          ...newsPageObject,
          total: response.data.Result_Data.total,
        });
      })
      .catch((error) => console.log(error));
  };

  const fetchVodData = () => {
    axios
      .get(`${CONFIG.API_CALL_URL()}/entertainment/vod_list.php?sstart=${(vodPageObject.current - 1) * 6}&slistscale=6`)
      .then((response) => {
        setVods(vods.concat(response.data.Result_Data.data));
        setVodPageObject({
          ...vodPageObject,
          total: response.data.Result_Data.total,
        });
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    axios
      .get(`${CONFIG.API_CALL_URL()}/entertainment/artist_list.php`)
      .then((response) => {
        setArtists(response.data.Result_Data.data);
        // console.log('data: ', response.data.Result_Data.data);
      })
      .catch((error) => console.log(error));
    fetchNewsData();
    fetchVodData();
  }, []);

  useEffect(() => {
    fetchNewsData();
  }, [newsPageObject.current]);

  useEffect(() => {
    fetchVodData();
  }, [vodPageObject.current]);

  const onClickNewsMore = () => {
    setNewsPageObject({
      ...newsPageObject,
      current: newsPageObject.current + 1,
    });
  };

  const onClickVodMore = () => {
    setVodPageObject({
      ...vodPageObject,
      current: vodPageObject.current + 1,
    });
  };

  const renderProfile = (type: any) => {
    return artists.map((item: IArtistItem) => {
      if (item.artist_type !== type) return false;

      try {
        return (
          <div
            className={`artist ${item.use_detail === 'Y' ? 'pointer' : ''}`}
            key={item.idx}
            onClick={() => {
              if (item.use_detail === 'Y') onClickArtist(item.idx);
            }}
          >
            <img alt="image" src={`${CONFIG.FILE_URL()}/storage/celeb/${item.photo_thumb}`} />
            <div className="name">{item.artist_name}</div>
          </div>
        );
      } catch (e) {
        return null;
      }
    });
  };

  const renderVod = () => {
    return vods.map((item: IVodItem, index: number) => {
      // if(vodPageObject.current * 6 <= index) return false

      return (
        <div
          onClick={() => {
            onClickVod(item.vod_youtube);
          }}
          className="vod"
          key={item.idx}
        >
          <img className="thumbnail" alt="image" src={`${CONFIG.FILE_URL()}/storage/celeb_vod/${item.thumb_name}`} />
          <div className="play-button" />
        </div>
      );
    });
  };

  const renderNews = () => {
    return news.map((item: INewsItem, index: number) => {
      // if(newsPageObject.current * 6 <= index) return false

      return (
        <Link to={`/artist-news/${item.idx}`} key={item.idx}>
          <div className="news-item">
            <div className="image-wrap">
              <img alt="image" src={`${CONFIG.FILE_URL()}/storage/news/management/${item.news_thumb_name}`} />
            </div>
            <div className="title">{item.news_title}</div>
            <div className="date">{moment(item.press_date).format('YYYY.MM.DD')}</div>
          </div>
        </Link>
      );
    });
  };

  useEffect(() => {
    AOS.refreshHard();
  });
  return (
    <div className="management">
      <div className="section-1">
        <div className="background-video">
          <video autoPlay loop muted playsInline webkit-playsInline x5-playsInline preload="auto">
            <source src={`${CONFIG.FILE_URL()}/storage/video/management.mp4`} type="video/mp4" />
          </video>
          {/* <ReactPlayer width="100%" height="100vh" playsinline muted playing loop url={`${CONFIG.FILE_URL()}/storage/video/management.mp4`} /> */}
        </div>
        <div>
          <div className="overlay" />
          <PageTitle
            title="매니지먼트"
            subTitle="Entertainment"
            description={
              <span>
                아티스트 전문 매니지먼트사의
                <br className="show-mobile" /> 체계적인 시스템과 역량을 바탕으로
                <br />
                잠재력 있는 신인을 육성하며
                <br className="show-mobile" /> 다양한 분야의 아티스트 및 크리에이터와
                <br />
                성공적인 파트너십으로 독보적인 성과를
                <br className="show-mobile" /> 만들어가고 있습니다
              </span>
            }
          />
          <WhiteSquare />
        </div>
      </div>
      <div className="section-2">
        <div className="content-wrap">
          <div className="section-title artist-title">ARTIST</div>
          <div className="artists" data-aos="fade-up" data-aos-duration="1000">
            {renderProfile('1')}
          </div>
          {_.filter(artists, { artist_type: '9' }).length !== 0 && (
            <>
              <div className="section-title sportainer-title">SPOTAINER</div>
              <div
                className="artists"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-bottom"
              >
                {renderProfile('9')}
              </div>
            </>
          )}
          <div className="section-title influencer-title">ENTERTAINER</div>
          <div className="artists" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
            {renderProfile('5')}
          </div>
        </div>
      </div>
      <div className="section-3">
        <div className="section-title vod-title">VOD</div>
        <div className="vods" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
          {renderVod()}
          {vodPageObject.current * 6 < vodPageObject.total && (
            <div className="more-button" onClick={onClickVodMore} data-aos="fade-up" data-aos-duration="1000">
              <img alt="image" src={moreButton} />
            </div>
          )}
        </div>
      </div>
      <div className="section-4">
        <div className="section-title news-title">NEWS</div>
        <div className="news" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
          {renderNews()}
        </div>
        {newsPageObject.current * 6 < newsPageObject.total && (
          <div className="more-button" onClick={onClickNewsMore}>
            <img alt="image" src={moreButton} />
          </div>
        )}
      </div>
      {showVod && (
        <div className="vod-modal">
          <div className="vod-overlay" />
          <div className="vod-player show-pc">
            <ReactPlayer url={vodUrl} width={1320} height={625} />
            <div className="relative-wrap">
              <div onClick={closeModal} className="close-button">
                <img alt="image" src={vodClose} />
              </div>
            </div>
          </div>
          <div className="vod-player show-mobile">
            <ReactPlayer url={vodUrl} width={342} height={236} />
            <div className="relative-wrap">
              <div onClick={closeModal} className="close-button">
                <img alt="image" src={vodClose} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Management;
