import React from 'react';

import image2 from '../../assets/brands/Gshine-2.jpg'
import image3 from '../../assets/brands/Gshine-3.jpg'
import image4 from '../../assets/brands/Gshine-4.jpg'
import image5 from '../../assets/brands/Gshine-5.jpg'
import shop from '../../assets/brands/healthy-shop-button.png'

import BackButton from "../../components/BackButton";
import ShopButton from "../../components/ShopButton";

const Gshine = () => {
  return (
    <div className="gshi">
      <div className="section-1">
        <div className="section-1-text-wrap">
          <p className="section-1-title" data-aos="fade-left" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">지샤인</p>
          <p style={{marginBottom: "33px"}} data-aos="fade-left" data-aos-delay="300" data-aos-duration="1000" data-aos-anchor-placement="top-bottom"><b>SIGNATURE TREND GOLF WEAR</b></p>

          <p style={{marginBottom: "33px"}} data-aos="fade-left" data-aos-delay="600" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">정형화된 스포츠웨어를 벗어나<br className="show-mobile" /> 기능성과 트렌드를 함께 아우르는<br />
            새롭고 차별화된 골프웨어 브랜드 <b>G/SHI</b> (지샤인)</p>

          <p style={{marginBottom: "33px"}} data-aos="fade-left" data-aos-delay="900" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">몸의 움직임을 고려한 편안한 착용감과<br className="show-mobile" /> 깔끔한 핏의 실루엣<br />필드와 일상 어디에서나 빛나는<br className="show-mobile" /> 완벽한 골프웨어를 제안합니다</p>
        </div>
      </div>
      <div className="section-2" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
        <div className="brand-logo show-pc" style={{ backgroundImage: `url(${image2})` }} />
        <div className="section-3">
          <div className="section-4">
            <div className="product-image-1" style={{ backgroundImage: `url(${image3})` }} />
            <div className="section-6">
              <div className="product-image-2" style={{ backgroundImage: `url(${image4})` }} />
              <div className="product-image-3" style={{ backgroundImage: `url(${image5})` }} />
            </div>
          </div>
          <div className="brand-logo show-mobile" style={{ backgroundImage: `url(${image2})` }} />
          <div className="section-5">
            <p className="title">지샤인</p>
            <p className="description">기능성과 트렌드를 함께 아우르는<br />새롭고 차별화된 골프웨어 브랜드</p>
            <a href="https://smartstore.naver.com/elrise/category/4190bdbd2a3a4575afcc40db078a9584?cp=1">
              <ShopButton />
            </a>
          </div>
        </div>
      </div>

      <BackButton />
    </div>
  )
}

export default Gshine