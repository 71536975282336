import React from 'react';
import PageTitle from "../../components/PageTitle";
import FamilyNavigation from "../../components/FamilyNavigation";

const Family = () => {

  return (
    <div className="family">
      <PageTitle title="EL Family" description={<p>함께 만드는 공존의 가치<br />EL Family</p>} />
      <FamilyNavigation />
    </div>
  );
};

export default Family;
