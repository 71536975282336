/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import WhiteSquare from "../../components/WhiteSquare";
import section21 from '../../assets/story/story-section-2-1.jpg'
import section22 from '../../assets/story/story-section-2-2.jpg'
import section23 from '../../assets/story/story-section-2-3.jpg'
import section41 from '../../assets/story/story-section-4-1.jpg'
import section42 from '../../assets/story/story-section-4-2.jpg'
import section43 from '../../assets/story/story-section-4-3.jpg'
import section44 from '../../assets/story/story-section-4-4.jpg'
import section4How from '../../assets/story/story-section-4-how.png'
import section4What from '../../assets/story/story-section-4-what.png'
import section4Why from '../../assets/story/story-section-4-why.png'

import CONFIG from "../../config";

interface I_metaInfo {
  [key: string]: {
    subTitle: string;
    description: JSX.Element | JSX.Element[];
  };
}

const metaInfo: I_metaInfo = {
  story: {
    subTitle: '경영철학',
    description: <p>함께 만들어가는 유쾌한 라이프<br />종합엔터테인먼트 미디어그룹<br className="show-mobile" /> 이엘그룹입니다</p>
  },
  history: {
    subTitle: '회사연혁',
    description: <p>끊임없는 도전으로 글로벌 종합엔터테인먼트 기업으로<br/>성장해 나가겠습니다.</p>
  },
  culture: {
    subTitle: '이엘문화',
    description: <p>업계 리더로서의 자부심을 가지고 <br />즐겁게 일하는 이엘 프로페셔널</p>
  },
};

const Story = () => {
  const location = useLocation();
  const { pathname } = location;
  const [selectedTab, setSelectedTab] = useState(1);
  const onClickTab = (index: React.SetStateAction<number>) => setSelectedTab(index);

  return (
    <div className="story">
      <div className="section-1">
        <div className="section-1-background" />
        <PageTitle title={metaInfo[pathname.slice(1)].subTitle}
                   subTitle="EL Story"
                   description={metaInfo[pathname.slice(1)].description}
        />
        <WhiteSquare />
        <div className="tab-wrap">
          <Link to="/story" style={{ textDecoration: "none"}}>
            <div className={`tab ${selectedTab === 1 ? 'selected' : ''}`}
                 onClick={() => {
                   onClickTab(1);
                 }}
            >
              경영철학
            </div>
          </Link>
          <Link to="/history" style={{ textDecoration: "none"}}>
            <div className={`tab ${selectedTab === 2 ? 'selected' : ''}`}
                 onClick={() => {
                   onClickTab(2);
                 }}
            >
              회사연혁
            </div>
          </Link>
          <Link to="/culture" style={{ textDecoration: "none"}}>
            <div className={`tab ${selectedTab === 3 ? 'selected' : ''}`}
                 onClick={() => {
                   onClickTab(3);
                 }}
            >
              이엘문화
            </div>
          </Link>
        </div>
      </div>
      <div className="section-1-5" data-aos="fade-up">
        <video autoPlay loop muted playsInline webkit-playsInline x5-playsInline preload="auto">
          <source
            src={`${CONFIG.FILE_URL()}/storage/video/story.mp4`}
            type="video/mp4"
          />
        </video>
        <div className="background" />
      </div>
      <div className="section-2">
        <div className="green-line" />
        <div className="content-wrap">
          <div className="content content-1" data-aos="fade-up" data-aos-duration="500">
            <p className="title">Insight & Creativity</p>
            <div className="description">
              남다른 <span>시각</span>의<br />
              <span>독창적</span> 컨텐츠
            </div>
            <img alt="image" src={section21} />
          </div>
          <div className="blue-line-1" />
          <div className="content content-2" data-aos="fade-up" data-aos-duration="700">
            <p className="title">Sincerity & Empathy</p>
            <div className="description">
              <span>진심과 공감</span>으로 전하는<br />
              라이프 스타일
            </div>
            <img alt="image" src={section22} />
          </div>
          <div className="blue-line-2" />
          <div className="content content-3" data-aos="fade-up" data-aos-duration="1400">
            <p className="title">Connection & Extension</p>
            <div className="description">
              <span>확장</span>되고 <span>이어지는</span> <br />
              소셜 엔터테인먼트 세상
            </div>
            <img alt="image" src={section23} />
          </div>
        </div>
        <div className="purple-line" />
      </div>
      <div className="section-3">
        <div className="content-wrap" data-aos="fade-up" data-aos-duration="500">
          <p className="text-1">VISION</p>
          <p className="text-2">
            기업의 비전은 마치 등대와도 같습니다<br />
            이엘그룹은 문화 콘텐츠의 영향력과 가치를<br className="show-mobile" /> 깊이 이해합니다<br />
            그렇기에 우리는 생각합니다<br />
            함께 살아가는 세상에 기쁨과 즐거움을 주고<br />
            함께 공감하며 소통할 수 있는 문화를 만드는 것
          </p>
          <div className="divider" />
          <p className="text-3">
            더 나은 공존을 위해 이엘그룹은<br className="show-mobile" /> 계속해서 나아갑니다
          </p>
        </div>
      </div>
      <div className="section-4">
        <div className="content-wrap content-wrap-1">
          <div className="content-sub-wrap" data-aos="fade-up" data-aos-duration="500">
            <div className="title">기업 비전</div>
            <div className="content content-1">
              더 나은 공존 <br />
              <span>A Better Coexistence</span>
            </div>
            <img alt="image" src={section4Why} className="background-text-1" />
          </div>
        </div>
        <div className="content-wrap content-wrap-2">
          <div className="content-sub-wrap" data-aos="fade-up" data-aos-duration="500">
            <div className="title">
              경영 철학
            </div>
            <div className="content content-2">
              좋은 문화를 위한 기여 <br/>
              <span>Makes a Good Culture</span> <br/><br/>

              이엘이 만드는 것은 문화가 되고<br/>
              <p className="show-pc">함께 공감하고 공유하며 더 나은 삶이 되게 하는 것</p>
              <p className="show-mobile">함께 공감하고 공유하며<br /> 더 나은 삶이 되게 하는 것</p>
            </div>
            <img alt="image" src={section4What} className="background-text-2" />
          </div>
        </div>
        <div className="content-wrap content-wrap-3">
          <div className="purple-line" />
          <div className="content-sub-wrap">
            <div className="title">
              경영 원칙
            </div>
            <div className="content content-3" data-aos="fade-up" data-aos-duration="500">
              <div className="item-wrap">
                <img alt="image" src={section41}/>
                <p className="title">최고 전문성</p>
                <div className="divider" />
                <p className="description">우리는 계속해서 <br />
                  발전하고 있는가</p>
              </div>
              <div className="item-wrap" data-aos="fade-up" data-aos-delay="50" data-aos-duration="700">
                <img alt="image" src={section42}/>
                <p className="title">직무 자부심</p>
                <div className="divider" />
                <p className="description">
                  우리는 목표를 알고 <br />
                  일을 즐기고 있는가
                </p>
              </div>
              <div className="item-wrap" data-aos="fade-up" data-aos-duration="500">
                <img alt="image" src={section43}/>
                <p className="title">바른 영향력</p>
                <div className="divider" />
                <p className="description">우리가 만드는 결과가 <br />
                  삶에 유익을 주는가 </p>
              </div>
              <div className="item-wrap" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500">
                <img alt="image" src={section44}/>
                <p className="title">오픈 마인드</p>
                <div className="divider" />
                <p className="description">
                  우리는 의견을 경청하고 <br />
                  협력하고 있는가
                </p>
              </div>
            </div>
            <img alt="image" src={section4How} className="background-text-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
