import React from 'react';

import image2 from '../../assets/brands/roothair-2.jpg'
import image3 from '../../assets/brands/roothair-3.jpg'
import image4 from '../../assets/brands/roothair-4.jpg'
import image5 from '../../assets/brands/roothair-5.jpg'
import shop from '../../assets/brands/healthy-shop-button.png'

import BackButton from "../../components/BackButton";
import ShopButton from "../../components/ShopButton";

const Roothair = () => {
  return (
    <div className="ruthair">
      <div className="section-1">
        <div className="section-1-text-wrap">
          <p className="section-1-title" data-aos="fade-right" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">루트헤어</p>
          <p style={{marginBottom: "33px"}} data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000" data-aos-anchor-placement="top-bottom"><b>두피 노화 방지를 위한 프리미엄 클리닉</b><br />
          외부자극, 스트레스, 출산 등으로 인한 두피의<br className="show-mobile" /> 모든 고민을 해결해주는<br className="show-mobile" /> 두피 안티에이징 케어 클리닉<br />
            루트헤어로 두피부터 시작하는 진정한 젊음을<br className="show-mobile" /> 완성하시길 바랍니다</p>

          <p style={{marginBottom: "33px"}} data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000" data-aos-anchor-placement="top-bottom"><b>모근부터 시작하는 새치 클리닉</b><br />
            나무의 뿌리가 나무의 성장을 좌우하듯이 모발도<br className="show-mobile" /> 뿌리인 모근의 관리가 중요합니다<br />
            완벽한 헤어를 위해서는 모근이 건강해야 합니다<br className="show-mobile" /> 루트헤어 새치클리닉으로 모근부터 관리하세요</p>

          <p  style={{marginTop: "33px"}} data-aos="fade-right" data-aos-delay="900" data-aos-duration="1000" data-aos-anchor-placement="top-bottom"><b>루트헤어 독점 글로벌 특허 콤플렉스 GREYVERSE™</b><br />캐나다 연구소에서 연구개발하여<br className="show-mobile" /> 프랑스 BIO-ECLAB 시험센터에서<br className="show-mobile" /> 새치 다크닝 효과를 입증 받은<br />
            ‘GREYVERSE™를 적용한<br className="show-mobile" /> 루트헤어 에이지리스 클리닉라인<br />
            두피 토탈 안티에이징 케어를 위한<br className="show-mobile" /> 최적의 두피솔루션입니다</p>
        </div>
      </div>
      <div className="section-2" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
        <div className="brand-logo show-pc" style={{ backgroundImage: `url(${image2})` }} />
        <div className="section-3">
          <div className="section-4">
            <div className="product-image-1" style={{ backgroundImage: `url(${image3})` }} />
            <div className="section-6">
              <div className="product-image-2" style={{ backgroundImage: `url(${image4})` }} />
              <div className="product-image-3" style={{ backgroundImage: `url(${image5})` }} />
            </div>
          </div>
          <div className="brand-logo show-mobile" style={{ backgroundImage: `url(${image2})` }} />
          <div className="section-5">
            <p className="title">루트헤어</p>
            <p className="description">두피부터 시작하는<br /> 안티에이징 프리미엄 클리닉</p>
            <a href="https://www.ruthair.co.kr/">
              <ShopButton />
            </a>
          </div>
        </div>
      </div>

      <BackButton />
    </div>
  )
}

export default Roothair