import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import FamilyNavigation from "../../components/FamilyNavigation";

const ElTv = () => {

  useEffect(() => {
    window.location.href = "http://eltv.kr"
  }, [])

  return (
    <>
      <Helmet>
        <title>이엘티비</title>
        <meta name="description" content="스토리 기반의 영상 제작사. 다큐멘터리 및 브랜디드 필름 등 영향력있는 문화 콘텐츠 제작"/>
        <meta name="Keywords" content="(주)이엘티비, 이엘티비, ELtv, eltv"/>
        <meta property="og:title" content="이엘티비"/>
        <meta property="og:site_name" content="이엘티비"/>
        <meta property="og:url" content="http://eltv.kr"/>
        <meta property="og:description" content="스토리 기반의 영상 제작사. 다큐멘터리 및 브랜디드 필름 등 영향력있는 문화 콘텐츠 제작"/>
        <meta name="twitter:title" content="이엘티비"/>
        <meta name="twitter:description" content="스토리 기반의 영상 제작사. 다큐멘터리 및 브랜디드 필름 등 영향력있는 문화 콘텐츠 제작"/>
        <meta name="twitter:domain" content="이엘티비"/>
        <link rel="canonical" href="http://eltv.kr"/>
      </Helmet>
      <div className="family-sub-el-tv">
        <div className="text-wrap">
          <div className="title-1">ELtv</div>
          <div className="title-2">이엘<span>티비_</span></div>
          <div className="description"><b>스토리 기반의 영상 제작사</b>로 <br />
            다큐멘터리 및 브랜디드 필름<br className="show-mobile" /> 제작을 통해 영향력 있는<br className="show-mobile" /> 문화 콘텐츠를 창조합니다</div>
        </div>
        <FamilyNavigation />
      </div>
    </>
  )
}

export default ElTv