import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './common.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';

import Header from './components/Header';
import MobileHeader from './components/MobileHeader';
import Overlay from './components/Overlay';
import Footer from './components/Footer';

import Home from './pages/Home';
import Story from './pages/about/Story';
import Culture from './pages/about/Culture';
import Family from './pages/about/Family';
import Drama from './pages/business/Drama';
import Platform from './pages/business/Platform';
import Ellifun from './pages/Ellifun';
import Ellispace from './pages/Ellispace';
import Management from './pages/business/Management';
import ManagementArtist from './pages/business/ManagementArtist';
import Music from './pages/business/Music';
import News from './pages/News';
import Recruit from './pages/news/Recruit';
import PressRelease from './pages/news/PressRelease';
import TastePark from './pages/TastePark';
import ELnow from './pages/news/ELnow';
import Notice from './pages/news/Notice';
import NoticeDetail from './pages/news/NoticeDetail';
import Contact from './pages/Contact';
import Alliance from './pages/Alliance';
import Brands from './pages/brand/Brands';
import Healthy from './pages/brand/Healthy';
import Inhaver from './pages/brand/Inhaver';
import Roothair from './pages/brand/Roothair';
import Gshine from './pages/brand/Gshine';
import Ivim from './pages/brand/Ivim';
import Investment from './pages/business/Investment';
import TopButton from './components/TopButton';
import ArtistNews from './pages/business/ArtistNews';
import MusicArtist from "./pages/business/MusicArtist";
import Contents from "./pages/business/Contents";

import ElWide from './pages/about/ElWide';
import ElMedia from './pages/about/ElMedia';
import ElPark from './pages/about/ElPark';
import ElRise from './pages/about/ElRise';
import ElTv from './pages/about/ElTv';

import RouteChangeTracker from './components/RouteChangeTracker';
import EllispacePrivacy from './pages/EllispacePrivacy';
import Ia from './pages/Ia';
import CompanyHistory from './pages/about/CompanyHistory';
// import SignUp from './pages/SignUp';
// import Join from './pages/Join';
// import Certification from './pages/Certification';

const App = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const location = useLocation();

  const showFooter = () => {
    const voidPaths = [
      '/',
      '/ia',
      '/ellispace-privacy',
      '/brands',
      '/family',
      '/elwide',
      '/elmedia',
      '/elpark',
      '/elrise',
      '/eltv',
    ];
    return voidPaths.indexOf(location.pathname);
  };

  const showTopButton = () => {
    const voidPaths = [
      '/',
      '/web',
      '/ellispace-privacy',
      '/culture',
      '/brands',
      '/family',
      `/elwide`,
      '/elmedia',
      '/elpark',
      '/elrise',
      '/eltv',
    ];
    return voidPaths.indexOf(location.pathname);
  };

  RouteChangeTracker();

  useEffect(() => {
    AOS.init();
    AOS.refreshHard();
  });

  return (
    <>
      <Helmet>
        <title>ELgroup Homepage</title>
        <meta name="author" content="ELgroup" />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=2.0,user-scalable=yes"
        />
        <meta
          name="description"
          content="종합 엔터테인먼트 미디어 그룹. 미디어 콘텐츠, 매니지먼트, 라이프스타일 브랜드, 소셜 플랫폼"
        />
        <meta
          name="Keywords"
          content="종합 엔터테인먼트 미디어 그룹. 미디어 콘텐츠, 매니지먼트, 라이프스타일 브랜드, 소셜 플랫폼"
        />
        <meta property="og:title" content="이엘그룹" />
        <meta property="og:site_name" content="이엘그룹" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://elgroup.kr/" />
        <meta
          property="og:description"
          content="종합 엔터테인먼트 미디어 그룹. 미디어 콘텐츠, 매니지먼트, 라이프스타일 브랜드, 소셜 플랫폼"
        />
        <meta name="naver-site-verification" content="" />
        <meta name="robots" content="index,follow" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="이엘그룹" />
        <meta
          name="twitter:description"
          content="종합 엔터테인먼트 미디어 그룹. 미디어 콘텐츠, 매니지먼트, 라이프스타일 브랜드, 소셜 플랫폼"
        />
        <meta name="twitter:image" content="../favicon/img/common/social-img.png" />
        <meta name="twitter:domain" content="이엘그룹" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="https://elgroup.kr/" />
      </Helmet>
      {location.pathname !== '/ellispace-privacy' && location.pathname !== '/ia' && (
        <>
          <p>{Boolean(['/ellispace-privacy', '/Ia'].indexOf(location.pathname))}</p>
          <Header setShowOverlay={setShowOverlay} />
          <MobileHeader setShowOverlay={setShowOverlay} />
        </ >
      )}
      {showOverlay && <Overlay />}
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />

          {/* About us */}
          <Route path="/story" element={<Story />} />
          <Route path="/culture" element={<Culture />} />
          <Route path="/history" element={<CompanyHistory />} />
          <Route path="/family" element={<Family />} />

          {/* Business */}
          <Route path="/entertainment" element={<Drama />} />
          <Route path="/drama" element={<Drama />} />
          <Route path="/documentary" element={<Drama />} />
          <Route path="/management" element={<Management />} />
          <Route path="/music" element={<Music />} />
          <Route path="/management-artist/:id" element={<ManagementArtist />} />
          <Route path="/music-artist/:id" element={<MusicArtist />} />
          <Route path="/artist-news/:id" element={<ArtistNews />} />
          <Route path="/news/:id" element={<News />} />
          <Route path="/platform" element={<Platform />} />
          <Route path="/ellifun" element={<Ellifun />} />
          <Route path="/ellispace" element={<Ellispace />} />
          <Route path="/ellipark" element={<TastePark />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/healthy" element={<Healthy />} />
          <Route path="/inhever" element={<Inhaver />} />
          <Route path="/ruthair" element={<Roothair />} />
          <Route path="/gshi" element={<Gshine />} />
          <Route path="/ibim" element={<Ivim />} />
          <Route path="/investment" element={<Investment />} />
          <Route path="/contents" element={<Contents />} />

          {/* News */}
          <Route path="/press-release" element={<PressRelease />} />
          <Route path="/elnow" element={<ELnow />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/notice/:id" element={<NoticeDetail />} />

          <Route path="/contact" element={<Contact />} />

          <Route path="/alliance" element={<Alliance />} />

          <Route path="/recruit" element={<Recruit />} />

          <Route path="/elwide" element={<ElWide />} />
          <Route path="/elmedia" element={<ElMedia />} />
          <Route path="/elpark" element={<ElPark />} />
          <Route path="/elrise" element={<ElRise />} />
          <Route path="/eltv" element={<ElTv />} />

          <Route path="/elWide" element={<ElWide />} />
          <Route path="/elMedia" element={<ElMedia />} />
          <Route path="/elPark" element={<ElPark />} />
          <Route path="/elRise" element={<ElRise />} />
          <Route path="/elTv" element={<ElTv />} />

          <Route path="/web/elWide" element={<ElWide />} />
          <Route path="/web/elRise" element={<ElRise />} />
          <Route path="/web/elPark" element={<ElPark />} />
          <Route path="/web/elMedia" element={<ElMedia />} />
          <Route path="/web/elTV" element={<ElTv />} />

          <Route path={'/ellispace-privacy'} element={<EllispacePrivacy />} />
          {/* <Route path={'/signup'} element={<SignUp />} />
          <Route path={'/join'} element={<Join />} />
          <Route path={'/certification'} element={<Certification />} /> */}

          <Route path={'/ia'} element={<Ia />} />

          <Route path={'*'} element={<Home />} />
        </Routes>
      </div>
      {showFooter() === -1 && <Footer />}
      {showTopButton() === -1 && <TopButton />}
    </>
  );
};

export default App;
