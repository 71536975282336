import React, { useState } from 'react';

import image1 from '../assets/platform/ellifun-section-1-image-1.svg';
import image2 from '../assets/platform/ellifun-section-1-image-2.svg';
import image3 from '../assets/platform/ellifun-section-1-image-3.svg';

import section3Image1 from '../assets/platform/ellifun-section-3-icon-1.png';
import section3Image2 from '../assets/platform/ellifun-section-3-icon-2.png';
import section3Image3 from '../assets/platform/ellifun-section-3-icon-3.png';
import section3Image4 from '../assets/platform/ellifun-section-3-icon-4.png';

import section3Image1Selected from '../assets/platform/ellifun-section-3-icon-1-selected.png';
import section3Image2Selected from '../assets/platform/ellifun-section-3-icon-2-selected.png';
import section3Image3Selected from '../assets/platform/ellifun-section-3-icon-3-selected.png';
import section3Image4Selected from '../assets/platform/ellifun-section-3-icon-4-selected.png';

import mouseIconBlack from '../assets/images/mouse-icon-black.png';

import mobile1 from '../assets/platform/ellifun-mobile-section-1-background.png';
import mobile2 from '../assets/platform/ellifun-mobile-section-2-background.png';
import mobile3 from '../assets/platform/ellifun-mobile-section-3-background.png';
import mobile4 from '../assets/platform/ellifun-mobile-section-4-background.png';

const Ellifun = () => {
  const [selectedMenu, setSelectedMenu] = useState(1);

  const onClickMenu = (index: number) => setSelectedMenu(index);

  return (
    <div className="ellifun">
      <div className="show-pc">
        <div className="section-1">
          <p className="page-title">Platform</p>
          <div className="content-wrap">
            <div className="text-wrap">
              <p className="title">ElliFUN</p>
              <p className="description">
                재미와 나눔이 있는
                <br />
                참여형 라이프스타일 커뮤니티
              </p>
              <div className="circle">공존</div>
              <div className="text-wrap-2">
                <div className="text-wrap-3">
                  <div>어제의 참여자가 오늘의</div>
                  <div>크리에이터가 되는 공간</div>
                </div>
                <div className="divider" />
                <div className="text-wrap-3">
                  <div>모든 활동이 보상 받는</div>
                  <div>수익형 체험 공간</div>
                </div>
              </div>
            </div>
            <div className="image-wrap">
              <div>
                <img alt="image" src={image1} />
              </div>
              <div>
                <img alt="image" src={image3} />
              </div>
              <div>
                <img alt="image" src={image2} />
              </div>
            </div>
          </div>
        </div>
        <div className="section-2">
          <p>#함께하는 커뮤니티</p>
          <p>#참여할수록 쌓이는 포인트</p>
          <p>#온오프라인 모임</p>
          <p>#나도 크리에이터</p>
          <p>#다함께</p>
        </div>
        <div className="section-3">
          <div className="title-1">ElliFUN에서</div>
          <p className="title-2">같이 가치를 나누는 행복한 경험을 함께해요</p>
          <div className="content-wrap">
            <div className="menu-wrap">
              <div
                className={`menu ${selectedMenu === 1 ? 'selected' : ''}`}
                onClick={() => {
                  onClickMenu(1);
                }}
              >
                <div>
                  <img alt="image" src={`${selectedMenu === 1 ? section3Image1Selected : section3Image1}`} />
                </div>
                <div className="menu-title">커뮤니티 개설, 참여</div>
              </div>
              <div
                className={`menu ${selectedMenu === 2 ? 'selected' : ''}`}
                onClick={() => {
                  onClickMenu(2);
                }}
              >
                <div>
                  <img alt="image" src={`${selectedMenu === 2 ? section3Image2Selected : section3Image2}`} />
                </div>
                <div className="menu-title">스토어로 상품 판매</div>
              </div>
              <div
                className={`menu ${selectedMenu === 3 ? 'selected' : ''}`}
                onClick={() => {
                  onClickMenu(3);
                }}
              >
                <div>
                  <img alt="image" src={`${selectedMenu === 3 ? section3Image3Selected : section3Image3}`} />
                </div>
                <div className="menu-title">콘텐츠 굿즈와 공동구매</div>
              </div>
              <div
                className={`menu ${selectedMenu === 4 ? 'selected' : ''}`}
                onClick={() => {
                  onClickMenu(4);
                }}
              >
                <div>
                  <img alt="image" src={`${selectedMenu === 4 ? section3Image4Selected : section3Image4}`} />
                </div>
                <div className="menu-title">다양한 참여로 수익 창출</div>
              </div>
            </div>
            <div className={`text-wrap ${selectedMenu === 1 ? 'selected' : ''}`}>
              <p>ElliFUN 의 커뮤니티는 회원이라면 누구나 개설하고 참여할 수 있어요.</p>
              <p>
                커뮤니티는 무료로 개설할 수 있으며, 커뮤니티의 콘텐츠는 누구나 시청 및 공유가 가능해요. <br />
                커뮤니티 내에서 유료 결제를 해야만 참여 할 수 있는 유료 콘텐츠도 있습니다. <br />
                유료 콘텐츠와 관련된 상품과 굿즈는 스토어에서 만나볼 수 있어요. <br />
                <br />
                단, 커뮤니티에 따라 참여자 수가 제한되거나 연령, 성별의 제한이 있을 수 있으니 참여신청 전에 꼭! 자세한
                내용을 확인하세요.
              </p>
            </div>
            <div className={`text-wrap ${selectedMenu === 2 ? 'selected' : ''}`}>
              <p>ElliFUN 에서는 만19세 이상 성인이라면 누구나 나만의 스토어를 만들 수 있어요</p>
              <p>
                나만의 스토어에 나만의 상품을 등록하고 판매해서 브랜드 스토어로 성장시켜 보세요 <br />
                <br />
                (만19세 미만 미성년자도 법정대리인의 동의만 있다면 스토어를 만들 수 있어요)
              </p>
            </div>
            <div className={`text-wrap ${selectedMenu === 3 ? 'selected' : ''}`}>
              <p>ElliFUN 스토어는 다른 쇼핑몰에서도 구매할 수 있는 상품은 최소화했어요.</p>
              <p>
                커뮤니티 내에서 참여해야만 구매할 수 있는 콘텐츠 굿즈는 오직 ElliFUN에서만 구매할 수 있어요. <br />
                공동구매 상품은 최저가로 한정 수량만 판매해요. <br />
                유료 콘텐츠와 관련된 상품과 굿즈는 스토어에서 만나볼 수 있어요. <br />
                <br />
                여기서 끝이 아니죠!
                <br />
                ElliFUN 스토어에서는 회원 여러분이 판매하는 다양한 상품을 만나볼 수 있어요.
              </p>
            </div>
            <div className={`text-wrap ${selectedMenu === 4 ? 'selected' : ''}`}>
              <p>
                ElliFUN 에서는 나만의 상품을 판매하는 방법 외에도 커뮤니티에 참여,
                <br />
                커뮤니티 공유, 미션 수행, 상품 공유 등의 다양한 활동으로 부가 수익을 얻을 수 있어요.
              </p>
              <p>
                다양한 활동을 통해 얻은 부가 수익으로 커뮤니티의 유료 콘텐츠에 참여하거나, 스토어에서 상품을 구매할 때
                사용할 수 있어요.
                <br />
                열심히 활동하고 참여하면 수익도 얻고, Celeb도 될 수도 있어요!
              </p>
            </div>
          </div>

          <img className="mouse-icon" src={mouseIconBlack} />
        </div>
        <div className="section-4" />
        <div className="section-5">
          <div className="title-1">ElliFUN에서</div>
          <p className="title-2">다양한 활동으로 수익을 창출해보세요</p>
          <div className="content-wrap">
            <div className="content">
              <div className="text-wrap">
                <p>커뮤니티 개설</p>
                <p>함께 하고 싶은 모임을 개설해보세요</p>
              </div>
              <div className="hover-content">
                <div className="overlay" />
                <div className="text-wrap-1">
                  <p>커뮤니티 개설</p>
                  <p>함께 하고 싶은 모임을 개설해보세요</p>
                </div>
                <div className="text-wrap-2">
                  <p>
                    ○ 전문분야의 지식을 공유해보세요
                    <br />
                    <br />
                    ○ 갖고 싶은 물건의 공동구매를 신청해보세요
                    <br />
                    <br />
                    ○ 커뮤니티를 개설하여 얻는 수익은 바로 현금으로
                    <br />
                    <span className="space" />인출할 수 있어요
                  </p>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="text-wrap">
                <p>커뮤니티 및 스토어 상품 공유</p>
                <p>관심있는 커뮤니티를 공유해보세요</p>
              </div>
              <div className="hover-content">
                <div className="overlay" />
                <div className="text-wrap-1">
                  <p>커뮤니티 및 스토어 상품 공유</p>
                  <p>관심있는 커뮤니티를 공유해보세요</p>
                </div>
                <div className="text-wrap-2">
                  <p>
                    ○ 스토어 상품을 공유해보세요 <br />
                    <br />
                    ○ 링크 공유만으로도 수익을 얻을 수 있어요
                    <br />
                    <br />
                    ○ 다른 사람이 공유한 링크를 통해 커뮤니티에
                    <br />
                    <span className="space" />참여하거나 상품을 구매하면 추가 보상까지
                    <br />
                    <span className="space" />드려요!
                  </p>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="text-wrap">
                <p>커뮤니티 콘텐츠 경험후기 및 공유</p>
                <p>
                  재미있는 혹은 감동적인 커뮤니티 콘텐츠를 <br />
                  경험하고 후기를 남겨보세요
                </p>
              </div>
              <div className="hover-content">
                <div className="overlay" />
                <div className="text-wrap-1">
                  <p>커뮤니티 콘텐츠 경험후기 및 공유</p>
                  <p>
                    재미있는 혹은 감동적인 커뮤니티 콘텐츠를 <br />
                    경험하고 후기를 남겨보세요
                  </p>
                </div>
                <div className="text-wrap-2">
                  <p>
                    ○ 후기를 남긴 콘텐츠를 공유해보세요 <br />
                    <br />
                    ○ 다른 사람이 공유한 링크를 통해<br />
                    <span className="space" />콘텐츠를 체험하면 수익을 얻을 수 있어요!
                  </p>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="text-wrap">
                <p>스토어에서 상품 판매</p>
                <p>나만의 스토어를 열고 제품을 등록해서 판매하세요</p>
              </div>
              <div className="hover-content">
                <div className="overlay" />
                <div className="text-wrap-1">
                  <p>스토어에서 상품 판매</p>
                  <p>나만의 스토어를 열고 제품을 등록해서 판매하세요</p>
                </div>
                <div className="text-wrap-2">
                  <p>
                    ○ 나만의 상품을 판매하여 브랜드 스토어로
                    <br />
                    <span className="space" />성장시켜 보세요
                    <br />
                    <br />
                    ○ 브랜드 스토어가 되면 다양한 혜택을 추가로
                    <br />
                    <span className="space" />받을 수 있어요
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-6">
          <div className="content">
            재미와 나눔,<br />
            크리에이터와 참여자가 만나는 ElliFUN<br />
            여러분을 만나기 위해 열심히 준비하고 있어요<br /><br />

            조금만 기다려주세요!
          </div>
        </div>
      </div>
      <div className="show-mobile">
        <img className="mobile-section" src={mobile1} alt="mobile-1" />
        <img className="mobile-section" src={mobile2} alt="mobile-2" />
        <img className="mobile-section" src={mobile3} alt="mobile-3" />
        <img className="mobile-section" src={mobile4} alt="mobile-4" />
      </div>
    </div>
  );
};

export default Ellifun;