import React from "react";

interface I_PageTitle {
  title: string,
  description: JSX.Element|JSX.Element[],
  subTitle?: string
}

const PageTitle = ({title, description, subTitle}: I_PageTitle) => {
  return (
    <div className="page-title-component" data-aos="fade-right" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
      <div className="mobile-bar" />
      {subTitle && <p className="sub-title">{ subTitle }</p>}
      <p className="main-title">{ title }</p>
      <p className="description">{description}</p>
    </div>
  )
}

export default  PageTitle