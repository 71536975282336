import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment"

import PageTitle from "../../components/PageTitle";
import WhiteSquare from "../../components/WhiteSquare";

import moreBlackButton from "../../assets/images/more-black-button.jpg"

import elnow1 from "../../assets/elnow/elnow-1.png"
import elnow2 from "../../assets/elnow/elnow-2.png"
import elnow3 from "../../assets/elnow/elnow-3.jpg"
import elnow4 from "../../assets/elnow/elnow-4.png"
import elnow5 from "../../assets/elnow/elnow-5.png"
import elnow6 from "../../assets/elnow/elnow-6.png"
import elnow7 from "../../assets/elnow/elnow-7.png"
import elnow8 from "../../assets/elnow/elnow-8.png"
import elnow9 from "../../assets/elnow/elnow-9.jpg"
import elnow10 from "../../assets/elnow/elnow-10.png"
import elnow11 from "../../assets/elnow/elnow-11.png"
import elnow12 from "../../assets/elnow/elnow-12.png"
import elnow13 from "../../assets/elnow/elnow-13.png"
import elnow14 from "../../assets/elnow/elnow-14.png"
import elnow15 from "../../assets/elnow/elnow-15.png"
import elnow16 from "../../assets/elnow/elnow-16.png"
import elnow17 from "../../assets/elnow/elnow-17.png"
import elnow18 from "../../assets/elnow/elnow-18.png"
import elnow19 from "../../assets/elnow/elnow-19.png"
import elnow20 from "../../assets/elnow/elnow-20.png"
import elnow21 from "../../assets/elnow/elnow-21.png"
import elnow22 from "../../assets/elnow/elnow-22.png"
import elnow23 from "../../assets/elnow/elnow-23.png"
import elnow24 from "../../assets/elnow/elnow-24.png"
import elnow25 from "../../assets/elnow/elnow-25.png"
import elnow26 from "../../assets/elnow/elnow-26.png"
import elnow27 from "../../assets/elnow/elnow-27.png"
import mElnow from "../../assets/elnow/elnow-mobile.png"


import CONFIG from "../../config";

interface INewsItem {
  "idx": string,
  "press_title": string,
  "press_thumb_name": string,
  "press_date": string
}

const Elnow = () => {

  const [news, setNews] = useState<any>([])
  const [firstNews, setFirstNews] = useState<any>()
  const [pageObject, setPageObject] = useState({
    total: 0,
    current: 1
  })

  const fetchFirstNews = (idx: string) => {
    axios.get(`${CONFIG.API_CALL_URL()}/news/press_info.php?idx=${idx}`)
      .then((response) => {
        setFirstNews(response.data.Result_Data.data)
      })
      .catch((error) => console.log(error));
  }

  const fetchNewsData = () => {
    axios.get(`${CONFIG.API_CALL_URL()}/news/press_list.php?sstart=${(pageObject.current - 1) * 7}&slistscale=7`)
      .then((response) => {
        setNews(news.concat(response.data.Result_Data.data))
        setPageObject({
          ...pageObject,
          total: response.data.Result_Data.total
        })

        if( !firstNews ) fetchFirstNews(response.data.Result_Data.data[0].idx)
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    fetchNewsData()
  }, [])

  useEffect(() => {
    fetchNewsData()
  }, [pageObject.current])

  const onClickMore = () => {
    setPageObject({
      ...pageObject,
      current: pageObject.current + 1
    })
  }

  const renderNews = () => {
    return news.map((item: INewsItem, index: number) => {

      // if(pageObject.current * 7 <= index) return false

      return (
        <Link className={`${index === 0 ? 'show-mobile' : ''}`} to={`/news/${item.idx}`} key={item.idx}>
          <div className="news-item">
            <div className="image-wrap">
              <img alt="image" src={`${CONFIG.FILE_URL()}/storage/news/press/${item.press_thumb_name}`} />
            </div>
            <div className="title">{item.press_title}</div>
            <div className="date">{moment(item.press_date).format('YYYY.MM.DD')}</div>
          </div>
        </Link>
      )
    })
  }

  return (
    <div className="elnow">
      <div className="section-1">
        <PageTitle title="EL Now" subTitle="NEWS" description={<p>이엘그룹의 최신 소식을 만나보세요</p>} />
        <WhiteSquare />
      </div>
      <div className="section-2">
        <div className="section-title">
          <p>EL Now</p>
          <div className="sub-section">
            <div className="divider" />
            <p>이엘그룹의 최신 소식을 만나보세요</p>
          </div>
        </div>

        <div className="show-pc image-wrap">
          <div className="type-1">
            <div className="image-hover-wrap-1" data-aos="fade-up" data-aos-duration="1000">
              <img className="not-hover" alt="image" src={elnow1} />
            </div>
            <div>
              <div className="image-hover-wrap-2" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                <img alt="image" src={elnow2} />
              </div>
              <div className="image-hover-wrap-3" data-aos="fade-up" data-aos-duration="1000">
                <img alt="image" src={elnow3} />
              </div>
            </div>
          </div>

          <div className="type-2" data-aos="fade-up" data-aos-duration="1000">
            <img alt="image" src={elnow4} />
          </div>

          <div className="type-3">
            <div className="image-hover-wrap" data-aos="fade-up" data-aos-duration="1000">
              <img alt="image" src={elnow5} />
            </div>
            <div className="image-hover-wrap" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
              <img alt="image" src={elnow6} />
            </div>
          </div>

          <div className="type-4">
            <div>
              <div className="image-hover-wrap-1" data-aos="fade-up" data-aos-duration="1000">
                <img alt="image" src={elnow7} />
              </div>
              <div className="image-hover-wrap-2" data-aos="fade-up" data-aos-duration="1000">
                <img alt="image" src={elnow8} />
              </div>
            </div>
            <div className="image-hover-wrap-3" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
              <img alt="image" src={elnow9} />
            </div>
          </div>

          <div className="type-1">
            <div className="image-hover-wrap-1" data-aos="fade-up" data-aos-duration="1000">
              <img alt="image" src={elnow10} />
            </div>
            <div>
              <div className="image-hover-wrap-2" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                <img alt="image" src={elnow11} />
              </div>
              <div className="image-hover-wrap-3" data-aos="fade-up" data-aos-duration="1000">
                <img alt="image" src={elnow12} />
              </div>
            </div>
          </div>

          <div className="type-3">
            <div className="image-hover-wrap" data-aos="fade-up" data-aos-duration="1000">
              <img alt="image" src={elnow13} />
            </div>
            <div className="image-hover-wrap" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
              <img alt="image" src={elnow14} />
            </div>
          </div>

          <div className="type-1">
            <div className="image-hover-wrap-1" data-aos="fade-up" data-aos-duration="1000">
              <img className="not-hover" alt="image" src={elnow15} />
            </div>
            <div>
              <div className="image-hover-wrap-2" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
                <img alt="image" src={elnow16} />
              </div>
              <div className="image-hover-wrap-3" data-aos="fade-up" data-aos-duration="1000">
                <img alt="image" src={elnow17} />
              </div>
            </div>
          </div>

          <div className="type-2" data-aos="fade-up" data-aos-duration="1000">
            <img alt="image" src={elnow18} />
          </div>

          <div className="type-3">
            <div className="image-hover-wrap" data-aos="fade-up" data-aos-duration="1000">
              <img alt="image" src={elnow19} />
            </div>
            <div className="image-hover-wrap" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
              <img alt="image" src={elnow20} />
            </div>
          </div>

          <div className="type-2" data-aos="fade-up" data-aos-duration="1000">
            <img alt="image" src={elnow21} />
          </div>

          <div className="type-3">
            <div className="image-hover-wrap" data-aos="fade-up" data-aos-duration="1000">
              <img alt="image" src={elnow22} />
            </div>
            <div className="image-hover-wrap" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
              <img alt="image" src={elnow23} />
            </div>
          </div>

          <div className="type-2" data-aos="fade-up" data-aos-duration="1000">
            <img alt="image" src={elnow24} />
          </div>

          <div className="type-3">
            <div className="image-hover-wrap" data-aos="fade-up" data-aos-duration="1000">
              <img alt="image" src={elnow25} />
            </div>
            <div className="image-hover-wrap" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
              <img alt="image" src={elnow26} />
            </div>
          </div>

          <div className="type-2" data-aos="fade-up" data-aos-duration="1000">
            <img alt="image" src={elnow27} />
          </div>
        </div>

        <div className="show-mobile image-wrap" data-aos="fade-up" data-aos-duration="1000">
          <img alt="image" src={mElnow} />
        </div>
      </div>
    </div>
  )
}

export default Elnow