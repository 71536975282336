import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment"

import PageTitle from "../../components/PageTitle";
import WhiteSquare from "../../components/WhiteSquare";

import moreBlackButton from "../../assets/images/more-black-button.jpg"

import CONFIG from "../../config";

interface INewsItem {
  "idx": string,
  "press_title": string,
  "press_thumb_name": string,
  "press_date": string
}

const PressRelease = () => {

  const [news, setNews] = useState<any>([])
  const [firstNews, setFirstNews] = useState<any>()
  const [pageObject, setPageObject] = useState({
    total: 0,
    current: 1
  })

  const fetchFirstNews = (idx: string) => {
    axios.get(`${CONFIG.API_CALL_URL()}/news/press_info.php?idx=${idx}`)
      .then((response) => {
        setFirstNews(response.data.Result_Data.data)
      })
      .catch((error) => console.log(error));
  }

  const fetchNewsData = () => {

    let start = 0;
    let scale = 0;

    if(pageObject.current === 1) {
      start = 0
      scale = 7
    } else {
      start = ((pageObject.current - 1) * 6) + 1
      scale = 6
    }
    axios.get(`${CONFIG.API_CALL_URL()}/news/press_list.php?sstart=${start}&slistscale=${scale}`)
      .then((response) => {
        setNews(news.concat(response.data.Result_Data.data))
        setPageObject({
          ...pageObject,
          total: response.data.Result_Data.total
        })

        if( !firstNews ) fetchFirstNews(response.data.Result_Data.data[0].idx)
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    fetchNewsData()
  }, [])

  useEffect(() => {
    fetchNewsData()
  }, [pageObject.current])

  const onClickMore = () => {
    setPageObject({
      ...pageObject,
      current: pageObject.current + 1
    })
  }

  const renderNews = () => {
    return news.map((item: INewsItem, index: number) => {
      return (
        <Link className={`${index === 0 ? 'show-mobile' : ''}`} to={`/news/${item.idx}`} key={item.idx}>
          <div className="news-item">
            <div className="image-wrap">
              <img alt="image" src={`${CONFIG.FILE_URL()}/storage/news/press/${item.press_thumb_name}`} />
            </div>
            <div className="title">{item.press_title}</div>
            <div className="date">{moment(item.press_date).format('YYYY.MM.DD')}</div>
          </div>
        </Link>
      )
    })
  }

  return (
    <div className="press-release">
      <div className="section-1">
        <PageTitle title="Press Release" subTitle="News" description={<p>언론에 공개된 이엘그룹 소식을 전해드립니다</p>} />
        <WhiteSquare />
      </div>
      <div className="section-2">
        <div className="section-title">
          <p>Press Release</p>
          <div className="sub-section">
            <div className="divider" />
            <p>언론에 공개된 이엘그룹 소식을 전해드립니다</p>
          </div>
        </div>
        <Link className="show-pc" to={`/news/${news[0]?.idx}`}>
          <div className="first-news-item" data-aos="fade-up" data-aos-duration="1000">
            <div className="image-wrap">
              <img alt="image" src={`${CONFIG.FILE_URL()}/storage/news/press/${news[0]?.press_thumb_name}`} />
            </div>
            <div className="content-wrap">
              <p className="title">{news[0]?.press_title}</p>
              <p className="date">{moment(news[0]?.press_date).format('YYYY.MM.DD')}</p>
              <p className="content">{firstNews?.press_contents}</p>
            </div>
          </div>
        </Link>
        <div className="press-release-news" data-aos="fade-up" data-aos-duration="1000">
          {renderNews()}
        </div>
        {news.length < pageObject.total &&
          <div className="more-button" onClick={onClickMore}>
            <img alt="image" src={moreBlackButton} />
          </div>
        }
      </div>

    </div>
  )
}

export default PressRelease