import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import jobkorea from '../../assets/images/recruit-jobkorea.png';
import saramin from '../../assets/images/recruit-saramin.png';
import wanted from '../../assets/images/recruit-wanted.png';
import gamejob from '../../assets/images/recruit-gamejob.png';
import PageTitle from '../../components/PageTitle';
import WhiteSquare from '../../components/WhiteSquare';

import CONFIG from "../../config";

import COMPANY from '../../company.json';

interface IRecruitItem {
  idx: string;
  company_code: string;
  recruit_title: string;
  start_date: string;
  end_date: string;
  url_jobkorea: string;
  url_saramin: string;
  url_wanted: string;
  url_gamejob: string;
  check_term: string;
}

const Recruit = () => {
  const [selectedTab, setSelectedTab] = useState(99);
  const [recruits, setRecruits] = useState([]);

  const onClickTab = (index: number) => setSelectedTab(index);

  useEffect(() => {
    axios.get(`${CONFIG.API_CALL_URL()}/recruit/list.php?ccode=${selectedTab}`)
      .then((response) => setRecruits(response.data.Result_Data.data))
      .catch((error) => console.log(error));
  }, [selectedTab]);

  const renderRecruit = () => {
    if(!recruits) {
      return <div className="empty-text">등록된 채용공고가 없습니다</div>
    }

    const company = COMPANY as any;

    return recruits.map((item: IRecruitItem) => {
      return (
        <div className="recruit-item" key={item.idx} data-aos="fade-up" data-aos-duration="1000">
          <div>
            <div className="recruit-title">{`[${company[item.company_code]}] ${item.recruit_title}`}</div>
            {item.check_term === "1" && <div className="recruit-date">상시채용</div>}
            {item.check_term === "9" &&
              <div className="recruit-date">
                {moment(item.start_date).format('YYYY.MM.DD')} ~ {moment(item.end_date).format('YYYY.MM.DD')}
              </div>
            }
          </div>
          <div>
            {item.url_jobkorea && (
              <a className="job-site" href={item.url_jobkorea} target="_blank" rel="noopener noreferrer">
                <div className="korea">
                  <img alt="image" src={jobkorea} />
                </div>
                <p>잡코리아</p>
              </a>
            )}
            {item.url_saramin && (
              <a className="job-site" href={item.url_saramin} target="_blank" rel="noopener noreferrer">
                <div className="saram">
                  <img alt="image" src={saramin} />
                </div>
                <p>사람인</p>
              </a>
            )}
            {item.url_wanted && (
              <a className="job-site" href={item.url_wanted} target="_blank" rel="noopener noreferrer">
                <div className="wanted">
                  <img alt="image" src={wanted} />
                </div>
                <p>원티드</p>
              </a>
            )}
            {item.url_gamejob && (
              <a className="job-site" href={item.url_gamejob} target="_blank" rel="noopener noreferrer">
                <div className="game">
                  <img alt="image" src={gamejob} />
                </div>
                <p>게임잡</p>
              </a>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="recruit">
      <div className="section-1">
        <PageTitle title="인재 채용" subTitle="Recruit" description={<p>이엘그룹과 함께 가치를 나눌 수 있는<br className="show-mobile" /> 프로페셔널을 기다립니다</p>} />
        <WhiteSquare />
        <div className="tab-wrap">
          <div
            className={`tab ${selectedTab === 99 ? 'selected' : ''}`}
            onClick={() => {
              onClickTab(99);
            }}
          >
            전체
          </div>
          <div
            className={`tab ${selectedTab === 1 ? 'selected' : ''}`}
            onClick={() => {
              onClickTab(1);
            }}
          >
            이엘와이드
          </div>
          <div
            className={`tab ${selectedTab === 4 ? 'selected' : ''}`}
            onClick={() => {
              onClickTab(4);
            }}
          >
            이엘라이즈
          </div>
          <div
            className={`tab ${selectedTab === 5 ? 'selected' : ''}`}
            onClick={() => {
              onClickTab(5);
            }}
          >
            이엘티비
          </div>
        </div>
      </div>
      <div className="section-2">
        <div className="recruit-list">
          {renderRecruit()}

          {/* <div className="recruit-item"> */}
          {/*   <div> */}
          {/*     <div className="recruit-title">[ELpark] 플랫폼 관리자 경력직 공개채용</div> */}
          {/*     <div className="recruit-date">2022.05.04 ~ 2022.05.15</div> */}
          {/*   </div> */}
          {/*   <div> */}
          {/*     <div className="job-site"> */}
          {/*       <div style={{width: '36px'}}> */}
          {/*         <img alt="image" src={jobkorea} /> */}
          {/*       </div> */}
          {/*       <p>잡코리아</p> */}
          {/*     </div> */}
          {/*     <div className="job-site"> */}
          {/*       <div style={{width: '30px'}}> */}
          {/*         <img alt="image" src={saramin} /> */}
          {/*       </div> */}
          {/*       <p>사람인</p> */}
          {/*     </div> */}
          {/*     <div className="job-site"> */}
          {/*       <div style={{width: '30px'}}> */}
          {/*         <img alt="image" src={gamejob} /> */}
          {/*       </div> */}
          {/*       <p>게임잡</p> */}
          {/*     </div> */}
          {/*   </div> */}
          {/* </div> */}

          {/* <div className="recruit-item"> */}
          {/*   <div> */}
          {/*     <div className="recruit-title">[ELpark] 플랫폼 관리자 경력직 공개채용</div> */}
          {/*     <div className="recruit-date">2022.05.04 ~ 2022.05.15</div> */}
          {/*   </div> */}
          {/*   <div> */}
          {/*     <div className="closed">마감되었습니다.</div> */}
          {/*   </div> */}
          {/* </div> */}

          {/* <div className="recruit-item"> */}
          {/*   <div> */}
          {/*     <div className="recruit-title">[ELpark] 플랫폼 관리자 경력직 공개채용</div> */}
          {/*     <div className="recruit-date">2022.05.04 ~ 2022.05.15</div> */}
          {/*   </div> */}
          {/*   <div> */}
          {/*     <div className="job-site"> */}
          {/*       <div style={{width: '36px'}}> */}
          {/*         <img alt="image" src={jobkorea} /> */}
          {/*       </div> */}
          {/*       <p>잡코리아</p> */}
          {/*     </div> */}
          {/*     <div className="job-site"> */}
          {/*       <div style={{width: '30px'}}> */}
          {/*         <img alt="image" src={saramin} /> */}
          {/*       </div> */}
          {/*       <p>사람인</p> */}
          {/*     </div> */}
          {/*   </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Recruit;
