/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import ost1 from '../../assets/music/ost-1.jpg';
import ost2 from '../../assets/music/ost-2.jpg';
import ost3 from '../../assets/music/ost-3.jpg';
import ost4 from '../../assets/music/ost-4.jpg';
import ost5 from '../../assets/music/ost-5.jpg';
import ost6 from '../../assets/music/ost-6.jpg';
import ost7 from '../../assets/music/ost-7.jpeg';

import album1 from '../../assets/music/album-1.jpg';
import album2 from '../../assets/music/album-2.jpg';
import album3 from '../../assets/music/album-3.jpg';
import album4 from '../../assets/music/album-4.jpg';
import album5 from '../../assets/music/album-5.jpg';
import PageTitle from '../../components/PageTitle';
import WhiteSquare from '../../components/WhiteSquare';

import CONFIG from '../../config';

const Music = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [artists, setArtists] = useState<any>();

  const onClickArtist = (index?: string) => navigate(`/music-artist/${index}`);

  useEffect(() => {
    axios
      .get(`${CONFIG.API_CALL_URL()}/entertainment/artist_list.php?att=7`)
      .then((response) => {
        // console.log(response.data.Result_Data.data);
        setArtists(response.data.Result_Data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const renderArtists = () => {
    if (artists !== undefined) {
      return artists.map((item: any) => {
        return (
          <div
            style={{cursor: `${item.use_detail === 'Y' ? 'pointer' : ''}`}}
            key={item.idx}
            onClick={() => {
              if (item.use_detail === 'Y') onClickArtist(item.idx);
            }}
          >
            <div className="album">
              <div
                className="image-1"
                style={{
                  background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), url(${CONFIG.FILE_URL()}/storage/celeb/${
                    item.photo_thumb
                  })`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              />
              <div>
                <p>{item.artist_eng_name}</p>
                <p>{item.sub_intro}</p>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  return (
    <div className="music">
      <div className="section-1">
        <div className="background-video">
          <video autoPlay loop muted playsInline webkit-playsInline x5-playsInline preload="auto">
            <source src={`${CONFIG.FILE_URL()}/storage/video/music.mp4`} type="video/mp4" />
          </video>
        </div>
        <div className="entertainment-link-menu show-pc">
          <div className="menu-title">
            <div>Q</div>
            <div>menu</div>
          </div>
          <div className="menu-button-wrap">
            <Link to="/documentary">
              <div className={`menu-button ${location.pathname === '/documentary' ? 'checked' : ''}`}>다큐멘터리</div>
            </Link>
            <Link to="/music">
              <div className={`menu-button ${location.pathname === '/music' ? 'checked' : ''}`}>뮤직</div>
            </Link>
          </div>
        </div>
        <div>
          <div className="overlay" />
          <PageTitle
            title="뮤직"
            subTitle="Entertainment"
            description={
              <p>
                아티스트 음반 기획, 드라마/영화 OST
                <br className="show-mobile" /> 제작 및 공연기획 등<br />폭 넓은 뮤직 비즈니스를 제공합니다
              </p>
            }
          />
          <WhiteSquare />
        </div>
      </div>

      <div className="section-2" data-aos="fade-up">
        <div className="menu-bar show-mobile">
          <Link to="/documentary">
            <div className={`${location.pathname === '/documentary' ? 'checked' : ''}`}>다큐멘터리</div>
          </Link>
          <Link to="/music">
            <div className={`${location.pathname === '/music' ? 'checked' : ''}`}>뮤직</div>
          </Link>
        </div>

        <p>OST</p>
        <div className="ost-list">
          <div className="ost">
            <img alt="image" src={ost1} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">드라마</div>
                <div className="title">질투의 화신</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={ost2} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">드라마</div>
                <div className="title">여우각시별</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={ost3} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">드라마</div>
                <div className="title">그냥 사랑하는 사이</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={ost4} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">드라마</div>
                <div className="title">블랙</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={ost5} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">드라마</div>
                <div className="title">러블리 호러블리</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={ost6} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">영화</div>
                <div className="title">골든슬럼버</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={ost7} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">영화</div>
                <div className="title">검은 사제들</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-3" data-aos="fade-up">
        <p>Artist</p>
        <div>{renderArtists()}</div>
      </div>

      <div className="section-4" data-aos="fade-up">
        <p>Albums</p>
        <div className="albums">
          <div className="album">
            <img alt="image" src={album1} />
            <div className="info">
              <div className="info-1">701 A-side</div>
              <div className="info-2">안녕 바다</div>
              <div className="info-3">2018.04.17</div>
              <div className="info-4">TITLE</div>
              <div className="info-5">LOVE CALL</div>
            </div>
          </div>

          <div className="album">
            <img alt="image" src={album2} />
            <div className="info">
              <div className="info-1">701 B-side</div>
              <div className="info-2">안녕 바다</div>
              <div className="info-3">2018.11.02</div>
              <div className="info-4">TITLE</div>
              <div className="info-5">꿈의 실종</div>
            </div>
          </div>

          <div className="album">
            <img alt="image" src={album3} />
            <div className="info">
              <div className="info-1">I must be a fool</div>
              <div className="info-2">My Q</div>
              <div className="info-3">2016.11.29</div>
              <div className="info-4">TITLE</div>
              <div className="info-5">I must be a fool</div>
            </div>
          </div>

          <div className="album">
            <img alt="image" src={album4} />
            <div className="info">
              <div className="info-1">F/W 2015 Issue No.2</div>
              <div className="info-2">My Q</div>
              <div className="info-3">2015.12.8</div>
              <div className="info-4">TITLE</div>
              <div className="info-5">Christmas Love</div>
            </div>
          </div>

          <div className="album">
            <img alt="image" src={album5} />
            <div className="info">
              <div className="info-1">e v e r Y d a y</div>
              <div className="info-2">My Q</div>
              <div className="info-3">2016.10.17</div>
              <div className="info-4">TITLE</div>
              <div className="info-5">e v e r Y d a y</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Music;