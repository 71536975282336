import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import FamilyNavigation from "../../components/FamilyNavigation";

const ElPark = () => {

  useEffect(() => {
    window.location.href = "http://elpark.kr"
  }, [])

  return (
    <>
      <Helmet>
        <title>이엘파크</title>
        <meta name="description" content="엔터테인먼트 소셜 플랫폼 기업. 매니지먼트, 아티스트와 크리에이터 중심의 디지털 유니버스 제작"/>
        <meta name="Keywords" content="(주)이엘파크, 이엘파크, ELpark, elpark"/>
        <meta property="og:title" content="이엘파크"/>
        <meta property="og:site_name" content="이엘파크"/>
        <meta property="og:url" content="http://elpark.kr"/>
        <meta property="og:description" content="엔터테인먼트 소셜 플랫폼 기업. 매니지먼트, 아티스트와 크리에이터 중심의 디지털 유니버스 제작"/>
        <meta name="twitter:title" content="이엘파크"/>
        <meta name="twitter:description" content="엔터테인먼트 소셜 플랫폼 기업. 매니지먼트, 아티스트와 크리에이터 중심의 디지털 유니버스 제작"/>
        <meta name="twitter:domain" content="이엘파크"/>
        <link rel="canonical" href="http://elpark.kr"/>
      </Helmet>
      <div className="family-sub-el-park">
        <div className="text-wrap">
          <div className="title-1">ELpark</div>
          <div className="title-2">이엘<span>파크_</span></div>
          <div className="description"><b>엔터테인먼트 소셜 플랫폼 <br className="show-mobile" />기업</b>으로<br className="show-pc" /> 체계적인<br className="show-mobile" />
            아티스트 매니지먼트 운영 및<br /> 아티스트와 크리에이터 중심의 <br className="show-mobile" /> 전방위 디지털 유니버스를
            <br className="show-mobile" />구현합니다
          </div>
        </div>
        <FamilyNavigation />
      </div>
    </>
  )
}

export default ElPark