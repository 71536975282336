import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import slider0 from '../../assets/cultrue/culture-slider-0.jpg';
import slider1 from '../../assets/cultrue/culture-slider-1.jpg';
import slider2 from '../../assets/cultrue/culture-slider-2.jpg';
import slider3 from '../../assets/cultrue/culture-slider-3.jpg';
import slider4 from '../../assets/cultrue/culture-slider-4.jpg';
import slider5 from '../../assets/cultrue/culture-slider-5.jpg';
import slider6 from '../../assets/cultrue/culture-slider-6.jpg';
import slider7 from '../../assets/cultrue/culture-slider-7.jpg';
import sliderMo0 from '../../assets/cultrue/culture-slider-0-mo.jpg';
import sliderMo1 from '../../assets/cultrue/culture-slider-1-mo.jpg';
import sliderMo2 from '../../assets/cultrue/culture-slider-2-mo.jpg';
import sliderMo3 from '../../assets/cultrue/culture-slider-3-mo.jpg';
import sliderMo4 from '../../assets/cultrue/culture-slider-4-mo.jpg';
import sliderMo5 from '../../assets/cultrue/culture-slider-5-mo.jpg';
import sliderMo6 from '../../assets/cultrue/culture-slider-6-mo.jpg';
import sliderMo7 from '../../assets/cultrue/culture-slider-7-mo.jpg';

import card1 from "../../assets/cultrue/cultrue-section2-1.jpg"
import card2 from "../../assets/cultrue/cultrue-section2-2.jpg"
import card3 from "../../assets/cultrue/cultrue-section2-3.jpg"

import PageTitle from '../../components/PageTitle';
import WhiteSquare from "../../components/WhiteSquare";

interface I_metaInfo {
  [key: string]: {
    subTitle: string;
    description: JSX.Element | JSX.Element[];
  };
}
const metaInfo: I_metaInfo = {
  story: {
    subTitle: '경영철학',
    description: <p>함께 만들어가는 유쾌한 라이프<br />종합엔터테인먼트 미디어그룹<br className="show-mobile" /> 이엘그룹입니다</p>
  },
  history: {
    subTitle: '회사연혁',
    description: <p>끊임없는 도전으로 글로벌 종합엔터테인먼트 기업으로<br/>성장해 나가겠습니다.</p>
  },
  culture: {
    subTitle: '이엘문화',
    description: <p>업계 리더로서의 자부심을 가지고 <br />즐겁게 일하는 이엘 프로페셔널</p>
  },
};
const Culture = () => {
  const location = useLocation();
  const { pathname } = location;
  const [selectedTab, setSelectedTab] = useState(3);
  const onClickTab = (index: React.SetStateAction<number>) => setSelectedTab(index);
  const [sliderOption, setSliderOption] = useState({
    dots: true,
    arrows: false,
    pauseOnHover: false,
    autoplaySpeed: 5000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  })

  return (
    <div className="culture">
      <div className="section-1">
        <PageTitle title={metaInfo[pathname.slice(1)].subTitle}
                   subTitle="EL Story"
                   description={metaInfo[pathname.slice(1)].description}
        />
        <WhiteSquare />
        <div className="tab-wrap">
          <Link to="/story" style={{ textDecoration: 'none' }}>
            <div className={`tab ${selectedTab === 1 ? 'selected' : ''}`}
                 onClick={() => {
                   onClickTab(1);
                 }}
            >
              경영철학
            </div>
          </Link>
          <Link to="/history" style={{ textDecoration: 'none' }}>
            <div className={`tab ${selectedTab === 2 ? 'selected' : ''}`}
                 onClick={() => {
                   onClickTab(2);
                 }}
            >
              회사연혁
            </div>
          </Link>
          <Link to="/culture" style={{ textDecoration: 'none' }}>
            <div className={`tab ${selectedTab === 3 ? 'selected' : ''}`}
                 onClick={() => {
                   onClickTab(3);
                 }}
            >
              이엘문화
            </div>
          </Link>
        </div>
        <Slider {...sliderOption}>
          <div className="slider-image-wrap slide3">
            <img src={slider3} alt="image" className="show-pc" />
            <img src={sliderMo3} alt="image" className="show-mobile" />
          </div>
          <div className="slider-image-wrap slide4">
            <img src={slider4} alt="image" className="show-pc" />
            <img src={sliderMo4} alt="image" className="show-mobile" />
          </div>
          <div className="slider-image-wrap slide1">
            <img src={slider1} alt="image" className="show-pc" />
            <img src={sliderMo1} alt="image" className="show-mobile" />
          </div>
          <div className="slider-image-wrap slide2">
            <img src={slider2} alt="image" className="show-pc" />
            <img src={sliderMo2} alt="image" className="show-mobile" />
          </div>
          <div className="slider-image-wrap slide5">
            <img src={slider5} alt="image" className="show-pc" />
            <img src={sliderMo5} alt="image" className="show-mobile" />
          </div>
          <div className="slider-image-wrap slide6">
            <img src={slider6} alt="image" className="show-pc" />
            <img src={sliderMo6} alt="image" className="show-mobile" />
          </div>
          <div className="slider-image-wrap slide7">
            <img src={slider7} alt="image" className="show-pc" />
            <img src={sliderMo7} alt="image" className="show-mobile" />
          </div>
        </Slider>
      </div>
      <div className="section-2">
        <div className="card card-1" data-aos="fade-up">
          <img alt="image" src={card1} />
          <p className="title"><span>EL FESTA</span></p>
          <div className="divider" />
          <p className="content">이엘그룹 모두와 하나되는 시간 <br /> 여름밤의 루프탑 파티</p>
        </div>
        <div className="card card-2" data-aos="fade-up" data-aos-delay="300" data-aos-duration="500">
          <img alt="image" src={card2} />
          <p className="title"><span>EL PLANTING DAY</span></p>
          <div className="divider" />
          <p className="content">직접 심고 직접 키우는 즐거움 <br /> 건강한 유기농 채소 심기 프로젝트</p>
        </div>
        <div className="card card-3" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
          <img alt="image" src={card3} />
          <p className="title"><span>EL PARTY</span></p>
          <div className="divider" />
          <p className="content">함께라서 더 행복한 이엘의 사내파티</p>
        </div>
      </div>
    </div>
  );
};

export default Culture;
