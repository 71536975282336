import React, { useState, useEffect, useRef } from 'react';
import AOS from 'aos';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

import upArrow from '../../assets/images/management-artist-arrow-up.png';
import downArrow from '../../assets/images/management-artist-arrow-down.png';

import ACTIVITY from '../../activity.json';
import CONFIG from '../../config';
import BackButton from '../../components/BackButton';

const ManagementArtist = () => {
  const { id } = useParams();

  const [artist, setArtist] = useState<any>();
  const [isShowActivity, setIsShowActivity] = useState<any>();
  const [selectedAccordion, setSelectedAccordion] = useState('');

  const artistNameRef = useRef<any>();

  useEffect(() => {
    axios
      .get(`${CONFIG.API_CALL_URL()}/entertainment/artist_info.php?idx=${id}`)
      .then((response) => {
        console.log('response : ', response.data.Result_Data);
        setArtist(response.data.Result_Data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    refactorShowData();
  }, [artist]);

  const onClickAccordion = (selectedName: string) => {
    artistNameRef.current.scrollIntoView(true);

    if (selectedName === selectedAccordion) setSelectedAccordion('');
    else setSelectedAccordion(selectedName);
  };

  const refactorShowData = () => {
    const array = artist?.activity;

    const awards = Boolean(_.find(array, { activity_code: '12' })?.activity_content);
    const advertisement = Boolean(_.find(array, { activity_code: '4' })?.activity_content);
    const filmography =
      Object.values(
        _.omit(array, [_.findIndex(array, { activity_code: '4' }), _.findIndex(array, { activity_code: '12' })]),
      ).filter((item: any) => {
        return Boolean(item.activity_content);
      }).length > 0;

    setIsShowActivity({
      awards,
      advertisement,
      filmography,
    });

    setSelectedAccordion(filmography ? 'filmography' : advertisement ? 'advertisement' : awards ? 'awards' : '');
  };

  const renderContent = (array: any) => {
    const result = _.split(array, '\r\n');

    return result.map((item: string, index: number) => {
      return <p key={index}>{item}</p>;
    });
  };

  const renderActivity = (type: string) => {
    let array = artist?.activity;
    if (type === 'awards') array = [_.find(array, { activity_code: '12' })];
    else if (type === 'advertisement') array = [_.find(array, { activity_code: '4' })];
    else if (type === 'filmography')
      array = Object.values(
        _.omit(array, [_.findIndex(array, { activity_code: '4' }), _.findIndex(array, { activity_code: '12' })]),
      );

    const activity = ACTIVITY as any;

    return array.map((item: any) => {
      if (item.activity_content) {
        return (
          <div className="item-wrap" key={item.idx}>
            <div className="line" />
            <div className="title">{activity[item.activity_code]}</div>
            <div className="content">{renderContent(item.activity_content)}</div>
          </div>
        );
      } else return false;
    });
  };

  return (
    <div className="management-artist">
      <div className="section-1">
        {artist && (
          <>
            <img className="show-pc" src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.main_pc}`} />
            <img className="show-mobile" src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.main_mobile}`} />
          </>
        )}
        <div
          className="name-wrap"
          ref={artistNameRef}
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-anchor-placement="top-bottom"
        >
          <p className="kor-name">{artist?.profile?.artist_name}</p>
          <div className="divider" />
          <p className="eng-name">{artist?.profile?.artist_eng_name}</p>
        </div>
      </div>
      {(isShowActivity?.awards || isShowActivity?.advertisement || isShowActivity?.filmography) && (
        <div className="section-2" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
          {isShowActivity?.filmography && (
            <div className={`accordion-wrap ${selectedAccordion === 'filmography' ? 'selected' : ''}`}>
              <div
                className="top"
                onClick={() => {
                  onClickAccordion('filmography');
                }}
              >
                <p className="title">FILMOGRAPHY</p>
                <img alt="image" src={selectedAccordion === 'filmography' ? upArrow : downArrow} />
              </div>
              <div className="bottom">{renderActivity('filmography')}</div>
            </div>
          )}
          {isShowActivity?.advertisement && (
            <div className={`accordion-wrap ${selectedAccordion === 'advertisement' ? 'selected' : ''}`}>
              <div
                className="top"
                onClick={() => {
                  onClickAccordion('advertisement');
                }}
              >
                <p className="title">ADVERTISEMENT</p>
                <img alt="image" src={selectedAccordion === 'advertisement' ? upArrow : downArrow} />
              </div>
              <div className="bottom">{renderActivity('advertisement')}</div>
            </div>
          )}
          {isShowActivity?.awards && (
            <div className={`accordion-wrap ${selectedAccordion === 'awards' ? 'selected' : ''}`}>
              <div
                className="top"
                onClick={() => {
                  onClickAccordion('awards');
                }}
              >
                <p className="title">AWARDS</p>
                <img alt="image" src={selectedAccordion === 'awards' ? upArrow : downArrow} />
              </div>
              <div className="bottom">{renderActivity('awards')}</div>
            </div>
          )}
        </div>
      )}
      <div className="section-3 show-pc">
        {artist && (
          <>
            <div className="image-wrap">
              <img
                className="image image-1"
                src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.profile_1}`}
                onError={(e: any) => {
                  e.target.style.display = 'none';
                }}
              />
              <div className="flex-wrap">
                <img
                  className="image image-2"
                  src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.profile_2}`}
                  onError={(e: any) => {
                    e.target.style.display = 'none';
                  }}
                />
                <img
                  className="image image-3"
                  src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.profile_3}`}
                  onError={(e: any) => {
                    e.target.style.display = 'none';
                  }}
                />
              </div>
            </div>
            <div className="image-wrap">
              <div className="flex-wrap">
                <img
                  className="image image-5"
                  src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.profile_5}`}
                  onError={(e: any) => {
                    e.target.style.display = 'none';
                  }}
                />
                <img
                  className="image image-6"
                  src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.profile_6}`}
                  onError={(e: any) => {
                    e.target.style.display = 'none';
                  }}
                />
              </div>
              <img
                className="image image-4"
                src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.profile_4}`}
                onError={(e: any) => {
                  e.target.style.display = 'none';
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className="section-3 show-mobile">
        {artist && (
          <>
            <img
              className="image image-1"
              src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.profile_1}`}
              onError={(e: any) => {
                e.target.style.display = 'none';
              }}
            />
            <img
              className="image image-2"
              src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.profile_2}`}
              onError={(e: any) => {
                e.target.style.display = 'none';
              }}
            />
            <img
              className="image image-3"
              src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.profile_3}`}
              onError={(e: any) => {
                e.target.style.display = 'none';
              }}
            />
            <img
              className="image image-4"
              src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.profile_4}`}
              onError={(e: any) => {
                e.target.style.display = 'none';
              }}
            />
            <img
              className="image image-5"
              src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.profile_5}`}
              onError={(e: any) => {
                e.target.style.display = 'none';
              }}
            />
            <img
              className="image image-6"
              src={`${CONFIG.FILE_URL()}/storage/celeb/${artist.photo.profile_6}`}
              onError={(e: any) => {
                e.target.style.display = 'none';
              }}
            />
          </>
        )}
      </div>
      <BackButton />
    </div>
  );
};

export default ManagementArtist;
