import React, { useState } from 'react';
import PageTitle from '../../components/PageTitle';
import WhiteSquare from '../../components/WhiteSquare';

import ellifunSection21 from '../../assets/platform/ellifun-section-2-1.jpg';
import ellifunSection22 from '../../assets/platform/ellifun-section-2-2.jpg';
import ellifunSection23 from '../../assets/platform/ellifun-section-2-3.jpg';

import ellifunSection3 from '../../assets/platform/ellifun-section-3.jpg';
import ellispaceSection2 from '../../assets/platform/ellispace-section-2.jpg';
import MellispaceSection2 from '../../assets/platform/mobile-ellispace-section-2.jpg';

import ellispaceSection41 from '../../assets/platform/ellispace-section-4-1.jpg';
import ellispaceSection42 from '../../assets/platform/ellispace-section-4-2.jpg';
import ellispaceSection43 from '../../assets/platform/ellispace-section-4-3.jpg';

import BackButton from '../../components/BackButton';

const text: any = {
  1: {
    title: 'ElliPARK',
    description: (
      <p className="description">
        <span>재미와 나눔</span>이 있는
        <br /> 참여형 라이프 스타일 커뮤니티
      </p>
    ),
  },

  2: {
    title: 'ElliSPACE',
    description: (
      <p className="description">
        <span>또 다른 세상에서</span>
        <br /> 내가 상상하던 모든 것이 현실이 됩니다
      </p>
    ),
  },
};

const Platform = () => {
  const [selectedTab, setSelectedTab] = useState(1);

  const onClickTab = (index: number) => setSelectedTab(index);

  return (
    <div className="platform">
      <div className="section-1">
        <PageTitle
          title="플랫폼"
          subTitle="Entertainment"
          description={
            <p>
              아티스트와 크리에이터 중심의
              <br className="show-mobile" /> 전방위 디지털 유니버스 구현
            </p>
          }
        />
        <WhiteSquare />
      </div>
      <div className="section-2">
        <div className="title-wrap">
          <div
            className={`title ${selectedTab === 1 ? 'selected' : ''}`}
            onClick={() => {
              onClickTab(1);
            }}
          >
            ElliPARK
          </div>
          <div
            className={`title ${selectedTab === 2 ? 'selected' : ''}`}
            onClick={() => {
              onClickTab(2);
            }}
          >
            ElliSPACE
          </div>
        </div>
        <div className="content-wrap">
          <div
            className={`${selectedTab === 1 ? 'selected' : ''}`}
            onClick={() => {
              onClickTab(1);
            }}
          >
            <p>
              <b>우리가 함께 만들어가는 세상</b>
              <br />
              Celeb과 함께 경험하고 나누는 체험의 공간
            </p>
          </div>
          <div
            className={`${selectedTab === 2 ? 'selected' : ''}`}
            onClick={() => {
              onClickTab(2);
            }}
          >
            <p>
              <b>글로벌 메타버스 커뮤니티</b> <br />
              성장과 보상이 있는 블록체인 개방형 경제 시스템
            </p>
          </div>
        </div>
      </div>
      <div className="section-3">
        <p className="title">{text[selectedTab].title}</p>
        {text[selectedTab].description}
      </div>
      {selectedTab === 1 ? (
        <div>
          <div className="ellifun-section-1" />
          <div className="ellifun-section-2">
            <div className="text-wrap">
              <p>모든 활동이</p>
              <p>
                보상이 되는 <span>수익형 체험 공간</span>
              </p>
            </div>
            <div className="image-wrap">
              <img alt="image" src={ellifunSection21} />
              <img alt="image" src={ellifunSection22} />
              <img alt="image" src={ellifunSection23} />
            </div>
          </div>
          <div className="ellifun-section-3">
            <div className="background" />
            <div className="text-wrap">
              <p>어제의 참여자가</p>
              <p>
                오늘의 <span>크리에이터</span>가 되는 공간
              </p>
            </div>
            <img alt="image" src={ellifunSection3} />
          </div>
          <div className="ellifun-section-4">
            <p>ElliPARK에서</p>
            <p>
              함께 가치를 나누는
              <br className="show-mobile" /> 행복한 경험을 제공합니다
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div className="ellispace-section-1" />
          <div className="ellispace-section-2">
            <img className="show-pc" alt="image" src={ellispaceSection2} />
            <img className="show-mobile" alt="image" src={MellispaceSection2} />
          </div>
          <div className="ellispace-section-3">
            <p>
              상상했던 모든 것이
              <br />
              <span>실현되는 공간</span>
            </p>
          </div>
          <div className="ellispace-section-4">
            <div className="text-wrap">
              <p>게임, 음악, 영화, 공연 등</p>
              <p>
                즐거운 <span>엔터테인먼트 경험 공간</span>
              </p>
            </div>
            <div className="image-wrap">
              <img alt="image" src={ellispaceSection41} />
              <img alt="image" src={ellispaceSection42} />
              <img alt="image" src={ellispaceSection43} />
            </div>
          </div>
        </div>
      )}
      <BackButton />
    </div>
  );
};

export default Platform;
