/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';

import movie1 from '../../assets/platform/investment_S03-1.png';
import movie2 from '../../assets/platform/investment_S03-2.png';
import movie3 from '../../assets/platform/investment_S03-3.png';

import PageTitle from '../../components/PageTitle';
import WhiteSquare from '../../components/WhiteSquare';

import CONFIG from '../../config';

const Contents = () => {

  return (
    <div className="contents">
      <div className="section-1">
        <PageTitle
          title="Investmant"
          subTitle=""
          description={
            <p>
              블록체인, 플랫폼, 핀테크, 콘텐츠 등 다양한 투자 경험과
              <br />
              글로벌 네트워크를 통해 기업의 잠재력을 발굴합니다.
            </p>
          }
        />
        <WhiteSquare />
      </div>

      <div className="section-1_5">
        <div className="section-title">
          <div className="sub-section">
            <p>
              <strong>Contents</strong>
              <br />
              콘텐츠 시장의 경쟁력 강화 및 글로벌 진출을 위한 영화 등 다양한 콘텐츠 사업 투자
            </p>
          </div>
        </div>
      </div>
      <div className="section-2" data-aos="fade-up">

        <p>Movie</p>
        <div className="ost-list">
          <div className="ost">
            <img alt="image" src={movie1} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">영화</div>
                <div className="title">노량: 죽음의 바다</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={movie2} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">영화</div>
                <div className="title">인생은 아름다워(2022)</div>
              </div>
            </div>
          </div>

          <div className="ost">
            <img alt="image" src={movie3} />
            <div className="ost-overlay show-pc">
              <div className="text-motion">
                <div className="genre">영화</div>
                <div className="title">한산: 용의 출현</div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};

export default Contents;
