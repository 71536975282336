import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Masonry from 'react-masonry-css';

import AOS from "aos";


import addEnter10 from '../../assets/entertainment/add_enter_10.png';
import addEnter9 from '../../assets/entertainment/add_enter_9.jpg';
import addEnter8 from '../../assets/entertainment/add_enter_8.jpg';
import addEnter7 from '../../assets/entertainment/add_enter_7.jpg';
import addEnter6 from '../../assets/entertainment/add_enter_6.jpg';
import addEnter5 from '../../assets/entertainment/add_enter_5.jpg';
import addEnter4 from '../../assets/entertainment/add_enter_4.jpeg';
import addEnter3 from '../../assets/entertainment/add_enter_3.jpg';
import addEnter2 from '../../assets/entertainment/add_enter_2.jpg';
import addEnter1 from '../../assets/entertainment/add_enter_1.jpg';

import enter25 from '../../assets/entertainment/enter_25.jpeg';
import enter24 from '../../assets/entertainment/enter_24.jpg';
import enter23 from '../../assets/entertainment/enter_23.jpg';
import enter22 from '../../assets/entertainment/enter_22.jpg';
import enter21 from '../../assets/entertainment/enter_21.jpg';
import enter20 from '../../assets/entertainment/enter_20.jpg';
import enter19 from '../../assets/entertainment/enter_19.jpg';
import enter18 from '../../assets/entertainment/enter_18.jpg';
import enter17 from '../../assets/entertainment/enter_17.jpg';
import enter16 from '../../assets/entertainment/enter_16.jpg';
import enter15 from '../../assets/entertainment/enter_15.jpg';
import enter14 from '../../assets/entertainment/enter_14.jpg';
import enter13 from '../../assets/entertainment/enter_13.jpg';
import enter12 from '../../assets/entertainment/enter_12.jpg';
import enter11 from '../../assets/entertainment/enter_11.jpg';
import enter10 from '../../assets/entertainment/enter_10.jpg';
import enter9 from '../../assets/entertainment/enter_9.jpg';
import enter8 from '../../assets/entertainment/enter_8.jpg';
import enter7 from '../../assets/entertainment/enter_7.jpg';
import enter6 from '../../assets/entertainment/enter_6.jpg';
import enter5 from '../../assets/entertainment/enter_5.jpg';
import enter4 from '../../assets/entertainment/enter_4.jpg';
import enter3 from '../../assets/entertainment/enter_3.jpg';
import enter2 from '../../assets/entertainment/enter_2.jpg';
import enter1 from '../../assets/entertainment/enter_1.jpg';
import digital7 from '../../assets/entertainment/digital_7.jpg';
import digital6 from '../../assets/entertainment/digital_6.jpg';
import digital5 from '../../assets/entertainment/digital_5.jpg';
import digital4 from '../../assets/entertainment/digital_4.jpg';
import digital3 from '../../assets/entertainment/digital_3.jpg';
import digital2 from '../../assets/entertainment/digital_2.jpg';
import digital1 from '../../assets/entertainment/digital_1.jpg';

import docu1 from '../../assets/documentary/docu_1.jpg'
import docu2 from '../../assets/documentary/docu_2.jpg'
import docu3 from '../../assets/documentary/docu_5.png'
import docu4 from '../../assets/documentary/docu_3.jpg'
import docu5 from '../../assets/documentary/docu_4.png'
import docu6 from '../../assets/documentary/docu1.jpeg'
import docu7 from '../../assets/documentary/docu2.jpeg'
import docu8 from '../../assets/documentary/docu4.jpeg'
import docu9 from '../../assets/documentary/docu5.jpeg'
import docu10 from '../../assets/documentary/docu6.jpeg'
import docu11 from '../../assets/documentary/docu7.jpg'
import docu111 from '../../assets/documentary/docu7-1.jpg'
import docu12 from '../../assets/documentary/docu8.jpg'
import docu13 from '../../assets/documentary/docu9.jpg'
import docu14 from '../../assets/documentary/docu10.jpg'


import drama1 from '../../assets/drama/drama_1.jpg'
import drama2 from '../../assets/drama/drama_2.jpg'
import drama3 from '../../assets/drama/drama_3.jpg'
import PageTitle from "../../components/PageTitle";
import WhiteSquare from "../../components/WhiteSquare";

import CONFIG from "../../config";

interface I_metaInfo {
  [key: string]: {
    subTitle: string;
    description: JSX.Element | JSX.Element[];
  };
}

const metaInfo: I_metaInfo = {
  entertainment: { subTitle: '예능/교양', description: <p>다양한 장르와 포맷을 넘나들며 활약해온<br className="show-mobile" /> 국내 최고 수준의 제작진이<br />크리에이티브 역량을 바탕으로 차별화된<br className="show-mobile" /> 예능, 교양, 디지털 콘텐츠를 만듭니다</p>,},
  drama: { subTitle: '드라마', description: <p>국내 최고의 작가 인프라로 원천 IP 확보 및<br /> 그를 기반으로 차별화된<br className="show-mobile" /> 웰메이드 드라마를 만듭니다</p> },
  documentary: { subTitle: '다큐멘터리', description: <p>감독 및 작가의 전문 인프라를 바탕으로<br /> 스토리 기반의 영향력 있는 프로그램을<br className="show-mobile" /> 만듭니다</p> },
};

const Drama = () => {
  const location = useLocation();

  const breakpointColumnsObj = {
    default: 4,
    1200: 6,
    1060: 5,
    960: 4,
    720: 3,
    520: 2
  };

  useEffect(() => {
    AOS.refreshHard();
    // AOS와 Masonry 갱신을 위해 지연시간을 두고 실행
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      // 필요하다면 Masonry 갱신 로직 추가
    }, 100); // 100ms 후 실행
  });

  return (
    <div className="drama">
      <div className="section-1">
        <div className="background-video">
          {location.pathname === '/entertainment' &&
            <video autoPlay loop muted playsInline webkit-playsInline x5-playsInline preload="auto">
              <source
                src={`${CONFIG.FILE_URL()}/storage/video/entertainment.mp4`}
                type="video/mp4"
              />
            </video>
          }
          {location.pathname === '/documentary' &&
            <video autoPlay loop muted playsInline webkit-playsInline x5-playsInline preload="auto">
              <source
                src={`${CONFIG.FILE_URL()}/storage/video/documentary.mp4`}
                type="video/mp4"
              />
            </video>
          }
          {location.pathname === '/drama' &&
            <video autoPlay loop muted playsInline webkit-playsInline x5-playsInline preload="auto">
              <source
                src={`${CONFIG.FILE_URL()}/storage/video/drama.mp4`}
                type="video/mp4"
              />
            </video>
          }
        </div>
        <div className="entertainment-link-menu show-pc">
          <div className="menu-title">
            <div>Q</div>
            <div>menu</div>
          </div>
          <div className="menu-button-wrap">
            <Link to="/documentary">
              <div className={`menu-button ${location.pathname === '/documentary' ? 'checked' : ''}`}>다큐멘터리</div>
            </Link>
            <Link to="/music">
              <div className={`menu-button ${location.pathname === '/music' ? 'checked' : ''}`}>뮤직</div>
            </Link>
          </div>
        </div>
        <div>
          <div className="overlay" />
          <PageTitle title={metaInfo[location.pathname.slice(1)].subTitle} subTitle="Entertainment" description={metaInfo[location.pathname.slice(1)].description} />
          <WhiteSquare />
        </div>
      </div>
      <div className="section-2">
        <div className="menu-bar show-mobile">
          <Link to="/documentary">
            <div className={`${location.pathname === '/documentary' ? 'checked' : ''}`}>다큐멘터리</div>
          </Link>
          <Link to="/music">
            <div className={`${location.pathname === '/music' ? 'checked' : ''}`}>뮤직</div>
          </Link>
        </div>

        {location.pathname === '/entertainment' &&
          <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid"
                   columnClassName="my-masonry-grid_column" data-aos="fade-up" data-aos-duration="1000"
                   data-aos-anchor-placement="top-bottom">
            <div className="image-wrap">
              <img alt="image" src={addEnter10} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={addEnter9} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={addEnter8} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={addEnter7} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={addEnter6} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={addEnter5} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={addEnter4} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={addEnter3} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={addEnter2} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={addEnter1} />
            </div>

            <div className="image-wrap">
              <img alt="image" src={enter25} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter24} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter23} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter22} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter21} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter20} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter19} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter18} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter17} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter16} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter15} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter14} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter13} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter12} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter11} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter10} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter9} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter8} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter7} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter6} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter5} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter4} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter3} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter2} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={enter1} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={digital7} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={digital6} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={digital5} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={digital4} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={digital3} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={digital2} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={digital1} />
            </div>
          </Masonry>
        }

        {location.pathname === '/documentary' &&
          <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid"
                   columnClassName="my-masonry-grid_column" data-aos="fade-up" data-aos-duration="1000"
                   data-aos-anchor-placement="top-bottom">
            <div className="image-wrap">
              <img alt="image" src={docu14} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={docu13} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={docu4} />
            </div>
            <div className="image-wrap show-pc">
              <img alt="image" src={docu11} />
            </div>
            <div className="image-wrap show-mobile">
              <img alt="image" src={docu111} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={docu12} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={docu10} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={docu1} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={docu2} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={docu3} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={docu5} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={docu6} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={docu7} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={docu8} />
            </div>
            <div className="image-wrap">
              <img alt="image" src={docu9} />
            </div>
          </Masonry>
        }

        {location.pathname === '/drama' &&
          <div>
            <div className="show-pc">
              <div className="drama-wrap">
                <img alt="image" src={drama1} data-aos="fade-up" />
                <div className="text-wrap" data-aos="fade-left">
                  <p className="title">아홉수 우리들</p>
                  <div className="staff-wrap">
                    <p>원작 IP 보유</p>
                  </div>
                  <div className="divider" />
                  <p className="content show-pc">
                    네이버 웹툰. 수박양 &lt;아홉수 우리들&gt; 원작 <br /><br />

                    연애, 취업, 돈... <br />
                    무엇 하나 이룬 것도, 제대로 해나가는 것도 없는 것은 세 명의 &apos;우리들&apos;<br />
                    따로 또 같이 맞이하는 달콤 씁쓸한 아홉수.<br />
                    본격 팩트 폭행 로맨틱 코미디가 시작된다!<br /><br />

                    아직 멀게만 느끼고 있을 20대의 우리에게 -<br />
                    막 지나온 시간에 공감 할 30대의 우리에게 -<br />
                    아직 20대의 마음으로 살아가는 40대의 우리에게 -<br /><br />

                    선물 같은 스물아홉살, 우리들이 들려주는 우리 이야기.
                  </p>
                  <p className="content show-mobile">
                    네이버 웹툰. 수박양 &lt;아홉수 우리들&gt; 원작 <br /><br />

                    연애, 취업, 돈... <br />
                    무엇 하나 이룬 것도, 제대로 해나가는 것도 없는 것은 세 명의 &apos;우리들&apos;
                    따로 또 같이 맞이하는 달콤 씁쓸한 아홉수.
                    본격 팩트 폭행 로맨틱 코미디가 시작된다!<br /><br />

                    아직 멀게만 느끼고 있을 20대의 우리에게 -
                    막 지나온 시간에 공감 할 30대의 우리에게 -
                    아직 20대의 마음으로 살아가는 40대의 우리에게 -<br /><br />

                    선물 같은 스물아홉살, 우리들이 들려주는 우리 이야기.
                  </p>
                </div>
              </div>
              <div className="drama-wrap">
                <img alt="image" src={drama2} />
                <img alt="image" src={drama2} data-aos="fade-up" />
                <div className="text-wrap" data-aos="fade-left">
                  <p className="title">보쌈</p>
                  <div className="staff-wrap">
                    <p>편성 <span>MBC</span>
                      기간 <span>2021.05.01. ~ 2021. 07. 04. 20부작</span>
                      연출 <span>권석장</span><br />
                      극본 <span>김지수, 박철</span>
                      출연 <span>정일우, 권유리, 신현수, 이재용, 김태우, 신동미, 이준혁, 송선미, 명세빈, 윤주만 외</span></p>
                  </div>
                  <div className="divider" />
                  <p className="content show-pc">
                    생계형 보쌈꾼 상남자 ‘바우’가<br />
                    실수로 광해군의 딸 옹주 ‘수경’을 보쌈하며 벌어지는<br />
                    파란만장 인생 大역전극!<br /><br />

                    &lt;해를 품은 달&gt;, &lt;돌아온 일지매&gt;, &lt;해치&gt; 등 사극불패를 이어오는<br />
                    ‘사극장인’ 정일우가 야성미 넘치는 거친 남자 ‘바우’로 등장!<br /><br />

                    대체불가 아이돌 ‘소녀시대’의 멤버로 아시아를 평정한 유리가<br />
                    광해군의 딸 화인옹주 ‘수경’으로 분해 ‘사극여신’에 도전!<br />
                    거침없는 ‘조선시대 걸크러쉬’ 매력을 뽐낸다!<br /><br />

                    조선 15대 왕 광해군 치하 실존 시대를 배경으로<br />
                    클래식한 스토리텔링, 다채로운 캐릭터와 흡입력 있는 전개를 더한<br /><br />

                    새로운 퓨전 사극 드라마 ‘보쌈-운명을 훔치다’ !

                  </p>
                  <p className="content show-mobile">
                    생계형 보쌈꾼 상남자 ‘바우’가 실수로 광해군의 딸 옹주 ‘수경’을 보쌈하며 벌어지는
                    파란만장 인생 大역전극!<br /><br />

                    &lt;해를 품은 달&gt;, &lt;돌아온 일지매&gt;, &lt;해치&gt; 등 사극불패를 이어오는
                    ‘사극장인’ 정일우가 야성미 넘치는 거친 남자 ‘바우’로 등장!<br /><br />

                    대체불가 아이돌 ‘소녀시대’의 멤버로 아시아를 평정한 유리가
                    광해군의 딸 화인옹주 ‘수경’으로 분해 ‘사극여신’에 도전!
                    거침없는 ‘조선시대 걸크러쉬’ 매력을 뽐낸다!<br /><br />

                    조선 15대 왕 광해군 치하 실존 시대를 배경으로
                    클래식한 스토리텔링, 다채로운 캐릭터와 흡입력 있는 전개를 더한<br /><br />

                    새로운 퓨전 사극 드라마 ‘보쌈-운명을 훔치다’ !

                  </p>
                </div>
              </div>
              <div className="drama-wrap">
                <img alt="image" src={drama3} />
                <img alt="image" src={drama3} data-aos="fade-up" />
                <div className="text-wrap" data-aos="fade-left">
                  <p className="title">악마가 너의 이름을 부를 때</p>
                  <div className="staff-wrap">
                    <p>편성 <span>tvN</span>
                      기간 <span>2019.07.31. ~ 2019.09.19. 16부작</span>
                      연출 <span>민진기</span><br />
                      극본 <span>노혜영, 고내리</span>
                      출연 <span>정경호, 박성웅, 이설, 이엘, 송강, 김원해, 김형묵 외</span></p>
                  </div>
                  <div className="divider" />
                  <p className="content show-pc">
                    악마에게 영혼을 판 인기 작곡가가 영혼 계약 만료를 통해<br />
                    진정한 사랑과 인생의 참의미를 깨닫는 영혼 담보 코믹 판타지!<br /><br />

                    ‘믿고 보는 배우’ 정경호 X 박성웅, 환상의 브로맨스 케미!<br />
                    차세대 라이징스타로 발돋움한 ‘대세’ 송강의 풋풋한 신인시절 모습까지..!<br /><br />

                    인간과 사랑, 그리고 음악까지 듬뿍 담아낸 판타지 휴먼 드라마<br />
                    ‘악마가 너의 이름을 부를 때’ !<br /><br />

                    &lt;그 여자 작사 그 남자 작곡&gt;, &lt;원스&gt;, &lt;비긴어게인&gt;, &lt;라라랜드&gt;를 이어<br />
                    눈과 귀를 모두 사로잡는 고품격 음악 드라마의 탄생!
                  </p>
                  <p className="content show-mobile">
                    악마에게 영혼을 판 인기 작곡가가 영혼 계약 만료를 통해
                    진정한 사랑과 인생의 참의미를 깨닫는 영혼 담보 코믹 판타지!<br /><br />

                    ‘믿고 보는 배우’ 정경호 X 박성웅, 환상의 브로맨스 케미!
                    차세대 라이징스타로 발돋움한 ‘대세’ 송강의 풋풋한 신인시절 모습까지..!<br /><br />

                    인간과 사랑, 그리고 음악까지 듬뿍 담아낸 판타지 휴먼 드라마
                    ‘악마가 너의 이름을 부를 때’ !<br /><br />

                    &lt;그 여자 작사 그 남자 작곡&gt;, &lt;원스&gt;, &lt;비긴어게인&gt;, &lt;라라랜드&gt;를 이어
                    눈과 귀를 모두 사로잡는 고품격 음악 드라마의 탄생!
                  </p>
                </div>
              </div>
            </div>






            <div className="show-mobile">
              <div className="drama-wrap">
                <div className="text-wrap" data-aos="fade-up">
                  <p className="title">아홉수 우리들</p>
                  <div className="staff-wrap">
                    <p>편성 <span>MBC</span>
                      기간 <span>2022년 편성 예정</span>
                      연출 <span>오다영</span>
                      극본 <span>한분외</span></p>
                  </div>
                  <div className="divider" />
                  <p className="content show-pc">
                    원작 : 네이버 웹툰. 수박양 &lt;아홉수 우리들&gt; 원작 <br /><br />

                    연애, 취업, 돈... <br />
                    무엇 하나 이룬 것도, 제대로 해나가는 것도 없는 것은 세 명의 &apos;우리들&apos;<br />
                    따로 또 같이 맞이하는 달콤 씁쓸한 아홉수.<br />
                    본격 팩트 폭행 로맨틱 코미디가 시작된다!<br /><br />

                    아직 멀게만 느끼고 있을 20대의 우리에게 -<br />
                    막 지나온 시간에 공감 할 30대의 우리에게 -<br />
                    아직 20대의 마음으로 살아가는 40대의 우리에게 -<br /><br />

                    선물 같은 스물아홉살, 우리들이 들려주는 우리 이야기.
                  </p>
                  <p className="content show-mobile">
                    원작 : 네이버 웹툰. 수박양 &lt;아홉수 우리들&gt; 원작 <br /><br />

                    연애, 취업, 돈... <br />
                    무엇 하나 이룬 것도, 제대로 해나가는 것도 없는 것은 세 명의 &apos;우리들&apos;
                    따로 또 같이 맞이하는 달콤 씁쓸한 아홉수.
                    본격 팩트 폭행 로맨틱 코미디가 시작된다!<br /><br />

                    아직 멀게만 느끼고 있을 20대의 우리에게 -
                    막 지나온 시간에 공감 할 30대의 우리에게 -
                    아직 20대의 마음으로 살아가는 40대의 우리에게 -<br /><br />

                    선물 같은 스물아홉살, 우리들이 들려주는 우리 이야기.
                  </p>
                </div>
                <img alt="image" src={drama1} data-aos="fade-up" />
              </div>
              <div className="drama-wrap">
                <div className="text-wrap" data-aos="fade-up">
                  <p className="title">보쌈</p>
                  <div className="staff-wrap">
                    <p>편성 <span>MBC</span>
                      기간 <span>2021.05.01. ~ 2021. 07. 04. 20부작</span>
                      연출 <span>권석장</span><br />
                      극본 <span>김지수, 박철</span>
                      출연 <span>정일우, 권유리, 신현수, 이재용, 김태우, 신동미, 이준혁, 송선미, 명세빈, 윤주만 외</span></p>
                  </div>
                  <div className="divider" />
                  <p className="content show-pc">
                    생계형 보쌈꾼 상남자 ‘바우’가<br />
                    실수로 광해군의 딸 옹주 ‘수경’을 보쌈하며 벌어지는<br />
                    파란만장 인생 大역전극!<br /><br />

                    &lt;해를 품은 달&gt;, &lt;돌아온 일지매&gt;, &lt;해치&gt; 등 사극불패를 이어오는<br />
                    ‘사극장인’ 정일우가 야성미 넘치는 거친 남자 ‘바우’로 등장!<br /><br />

                    대체불가 아이돌 ‘소녀시대’의 멤버로 아시아를 평정한 유리가<br />
                    광해군의 딸 화인옹주 ‘수경’으로 분해 ‘사극여신’에 도전!<br />
                    거침없는 ‘조선시대 걸크러쉬’ 매력을 뽐낸다!<br /><br />

                    조선 15대 왕 광해군 치하 실존 시대를 배경으로<br />
                    클래식한 스토리텔링, 다채로운 캐릭터와 흡입력 있는 전개를 더한<br /><br />

                    새로운 퓨전 사극 드라마 ‘보쌈-운명을 훔치다’ !

                  </p>
                  <p className="content show-mobile">
                    생계형 보쌈꾼 상남자 ‘바우’가 실수로 광해군의 딸 옹주 ‘수경’을 보쌈하며 벌어지는
                    파란만장 인생 大역전극!<br /><br />

                    &lt;해를 품은 달&gt;, &lt;돌아온 일지매&gt;, &lt;해치&gt; 등 사극불패를 이어오는
                    ‘사극장인’ 정일우가 야성미 넘치는 거친 남자 ‘바우’로 등장!<br /><br />

                    대체불가 아이돌 ‘소녀시대’의 멤버로 아시아를 평정한 유리가
                    광해군의 딸 화인옹주 ‘수경’으로 분해 ‘사극여신’에 도전!
                    거침없는 ‘조선시대 걸크러쉬’ 매력을 뽐낸다!<br /><br />

                    조선 15대 왕 광해군 치하 실존 시대를 배경으로
                    클래식한 스토리텔링, 다채로운 캐릭터와 흡입력 있는 전개를 더한<br /><br />

                    새로운 퓨전 사극 드라마 ‘보쌈-운명을 훔치다’ !

                  </p>
                </div>
                <img alt="image" src={drama2} data-aos="fade-up" />
              </div>
              <div className="drama-wrap">
                <div className="text-wrap" data-aos="fade-up">
                  <p className="title">악마가 너의 이름을 부를 때</p>
                  <div className="staff-wrap">
                    <p>편성 <span>tvN</span>
                      기간 <span>2019.07.31. ~ 2019.09.19. 16부작</span>
                      연출 <span>민진기</span><br />
                      극본 <span>노혜영, 고내리</span>
                      출연 <span>정경호, 박성웅, 이설, 이엘, 송강, 김원해, 김형묵 외</span></p>
                  </div>
                  <div className="divider" />
                  <p className="content show-pc">
                    악마에게 영혼을 판 인기 작곡가가 영혼 계약 만료를 통해<br />
                    진정한 사랑과 인생의 참의미를 깨닫는 영혼 담보 코믹 판타지!<br /><br />

                    ‘믿고 보는 배우’ 정경호 X 박성웅, 환상의 브로맨스 케미!<br />
                    차세대 라이징스타로 발돋움한 ‘대세’ 송강의 풋풋한 신인시절 모습까지..!<br /><br />

                    인간과 사랑, 그리고 음악까지 듬뿍 담아낸 판타지 휴먼 드라마<br />
                    ‘악마가 너의 이름을 부를 때’ !<br /><br />

                    &lt;그 여자 작사 그 남자 작곡&gt;, &lt;원스&gt;, &lt;비긴어게인&gt;, &lt;라라랜드&gt;를 이어<br />
                    눈과 귀를 모두 사로잡는 고품격 음악 드라마의 탄생!
                  </p>
                  <p className="content show-mobile">
                    악마에게 영혼을 판 인기 작곡가가 영혼 계약 만료를 통해
                    진정한 사랑과 인생의 참의미를 깨닫는 영혼 담보 코믹 판타지!<br /><br />

                    ‘믿고 보는 배우’ 정경호 X 박성웅, 환상의 브로맨스 케미!
                    차세대 라이징스타로 발돋움한 ‘대세’ 송강의 풋풋한 신인시절 모습까지..!<br /><br />

                    인간과 사랑, 그리고 음악까지 듬뿍 담아낸 판타지 휴먼 드라마
                    ‘악마가 너의 이름을 부를 때’ !<br /><br />

                    &lt;그 여자 작사 그 남자 작곡&gt;, &lt;원스&gt;, &lt;비긴어게인&gt;, &lt;라라랜드&gt;를 이어
                    눈과 귀를 모두 사로잡는 고품격 음악 드라마의 탄생!
                  </p>
                </div>
                <img alt="image" src={drama3} data-aos="fade-up" />
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Drama;
