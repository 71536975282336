import React, { useState, useEffect } from 'react';
import AOS from 'aos';

import PageTitle from '../components/PageTitle';
import WhiteSquare from '../components/WhiteSquare';

import ellifunSection21 from '../assets/platform/ellifun-section-2-1.jpg';
import ellifunSection22 from '../assets/platform/ellifun-section-2-2.jpg';
import ellifunSection23 from '../assets/platform/ellifun-section-2-3.jpg';

import ellifunSection3 from '../assets/platform/ellifun-section-3.jpg';
import ellispaceSection2 from '../assets/platform/ellispace-section-2.jpg';
import MellispaceSection2 from '../assets/platform/mobile-ellispace-section-2.jpg';

import ellispaceSection41 from '../assets/platform/ellispace-section-4-1.jpg';
import ellispaceSection42 from '../assets/platform/ellispace-section-4-2.jpg';
import ellispaceSection43 from '../assets/platform/ellispace-section-4-3.jpg';

import investmentBanner1 from '../assets/platform/investment_S01.png';
import investmentSub01 from '../assets/platform/investment_sub01.png';
import investmentSub02 from '../assets/platform/investment_sub02.png';

import BackButton from '../components/BackButton';

const text: any = {
  1: {
    title: 'ElliPARK',
    description: (
      <p className="description">
        <span>재미와 나눔</span>이 있는
        <br /> 참여형 라이프 스타일 커뮤니티
      </p>
    ),
  },

  2: {
    title: 'ElliSPACE',
    description: (
      <p className="description">
        <span>또 다른 세상에서</span>
        <br /> 내가 상상하던 모든 것이 현실이 됩니다
      </p>
    ),
  },
};

const Ellispace = () => {
  const [selectedTab, setSelectedTab] = useState(1);

  const onClickTab = (index: number) => setSelectedTab(index);

  useEffect(() => {
    AOS.refreshHard();
  });
  // document.addEventListener("DOMContentLoaded", function() {
  //     setTimeout(() => { AOS.refreshHard(); }, 10);
  // });
  document.querySelectorAll('img').forEach((img) => img.addEventListener('load', () => AOS.refreshHard()));

  return (
    <div className="ellispace">
      <div className="section-1">
        <PageTitle
          title="Investmant"
          subTitle=""
          description={
            <p>
              블록체인, 플랫폼, 핀테크, 콘텐츠 등 다양한 투자 경험과
              <br/>
              글로벌 네트워크를 통해 기업의 잠재력을 발굴합니다.
            </p>
          }
        />
        <WhiteSquare />
      </div>

      <div className="section-2 tit-section">
        <div className="section-title">
          <div className="sub-section">
            <p>
              <strong>Platform</strong>
              <br/>
              아티스트와 크리에이터 중심의 전방위 디지털 유니버스 플랫폼 사업 투자
            </p>
          </div>
        </div>
        <div className="platform-margin-base">
          <p className="txt" data-aos="fade-right" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
            <span className="cr_lavender">또 다른 세상</span>에서
            <br />
            <span className="txt_bg">내가 상상했던 모든 것이 현실이 됩니다</span>
          </p>
        </div>

      </div>

      <div className="ellispace-section-2">
        <p className="sub-title" data-aos="fade-right" data-aos-duration="1000"
           data-aos-anchor-placement="top-bottom">
          <span className="title-bar"></span>
          게임, 음악, 영화, 공연 등 즐거운
          <span className="cr_lavender">엔터테인먼트 경험 공간</span>
        </p>
        <img className="show-pc" alt="image" src={investmentSub01} data-aos="fade-up" data-aos-duration="1000" />
        <img
          className="show-mobile"
          alt="image"
          src={MellispaceSection2}
          data-aos="fade-up"
          data-aos-duration="1000"
        />
      </div>

      <div className="section-5">
        <p className="sub-title" data-aos="fade-right" data-aos-duration="1000"
           data-aos-anchor-placement="top-bottom">
          <span className="title-bar"></span>
          재미와 나눔이 있는
          <span className="cr_lavender">참여형 라이프 스타일 커뮤니티</span>
        </p>

        <div className="section-title2" data-aos="fade-up" data-aos-duration="1000">
          <div className="sub-section">
            <p>
              어제의 참여자가&nbsp;
              <span className="cr_lavender">오늘의 크리에이터</span>
              가되고
              <br/>
              모든 활동이 보상이 되는&nbsp;
              <span className="cr_lavender">수익형 체험 공간</span>
            </p>
          </div>
        </div>

      </div>

      <BackButton />
    </div>
  );
};

export default Ellispace;
