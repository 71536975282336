import React, { useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
import PageTitle from "../components/PageTitle";
import WhiteSquare from "../components/WhiteSquare";
import SendButton from "../assets/images/send-button.png"

import CONFIG from "../config";

interface IAllianceObject {
  chkCompany: string,
  offerContents: string,
  offerFile: undefined | File,
  offerFileReal: string,
  offerCompany: string,
  offerBusiness: string,
  offerHomepage: string,
  infoFile: undefined | File,
  infoFileReal: string,
  offerName: string,
  offerPosition: string,
  offerPhone: string,
  offerEmail: string,
  regip: string
}

const Alliance = () => {

  const [modalObject, setModalObject] = useState({
    showModal: false,
    modalText: <p>전송이 완료되었습니다<br />검토 후 연락드리겠습니다</p>
  })
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [emailValidation, setEmailValidation] = useState(true)
  const [showWarning, setShowWarning] = useState({step2: false, step3: false})
  const [allianceObject, setAllianceObject] = useState<IAllianceObject>({
    chkCompany: "1",
    offerContents: "",
    offerFile: undefined,
    offerFileReal: "",
    offerCompany: "",
    offerBusiness: "",
    offerHomepage: "",
    infoFile: undefined,
    infoFileReal: "",
    offerName: "",
    offerPosition: "",
    offerPhone: "",
    offerEmail: "",
    regip: ""
  })

  const onChangeInputHandler = (event: any) => {
    setAllianceObject({
      ...allianceObject,
      [event.target.name]: event.target.name !== 'offerPhone' ? event.target.value :  event.target.value.replace(/^(\d{3})(\d{4})(\d{4})$/, `$1-$2-$3`)
    })
  }

  const fileSizeCheck = (fileSize: number, step: string) => {
    if(fileSize >= 20000000) {
      setShowWarning({ ...showWarning, [step === "1" ? "step2" : "step3"]: true, })
      return false;
    } else {
      setShowWarning({ ...showWarning, [step === "1" ? "step2" : "step3"]: false, })
      return true
    }
  }

  const fileExtensionCheck = (extension: string) => {
    return ['pdf', 'doc', 'docx', 'ppt', 'pptx'].includes(extension)
  }

  const onChangeFileHandler = async (event: any) => {
    if(!fileSizeCheck(event?.target?.files[0]?.size, event.target.name)) return false
    if(!fileExtensionCheck(event.target.files[0].name.split(".").at(-1))) return false

    const formData = new FormData()
    formData.append('uploadFile', event.target.files[0])
    formData.append('fType', event.target.name)

    const response = await axios.post(`${CONFIG.API_CALL_URL()}/contact/upload.php`, formData,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    setAllianceObject({
      ...allianceObject,
      [event.target.name === "1" ? "offerFile" : "infoFile"]: response.data.Result_Data,
      [event.target.name === "1" ? "offerFileReal" : "infoFileReal"]: event.target.files[0].name,
    })
  }

  const onLeaveFocus = () => setEmailValidation(/\S+@\S+\.\S+/.test(allianceObject.offerEmail))

  const validateAllianceObject = () => {
    if(!checkedTerms) {
      setModalObject({
        showModal: true,
        modalText: <p>개인정보 수집 및 이용에 동의해주세요</p>
      })

      return false
    } else if( !allianceObject.offerContents ||
      !allianceObject.offerFile ||
      !allianceObject.offerFileReal ||
      !allianceObject.offerCompany ||
      !allianceObject.offerBusiness ||
      !allianceObject.offerName ||
      !allianceObject.offerPosition ||
      !allianceObject.offerPhone ||
      !allianceObject.offerEmail ||
      !emailValidation
    ) {
      setModalObject({
        showModal: true,
        modalText: <p>필수 정보를 입력해 주세요</p>
      })

      return false
    } else {
      return true
    }
  }

  const onClickSendButton = async () => {
    const result = await validateAllianceObject()

    if (result) {
      const ipData = await fetch('https://geolocation-db.com/json/');
      const locationIp = await ipData.json();

      const cloneObject = _.cloneDeep(allianceObject)
      cloneObject.regip = locationIp.IPv4
      cloneObject.offerPhone = cloneObject.offerPhone.replaceAll("-", "")

      try {
        const response = await axios.post(`${CONFIG.API_CALL_URL()}/contact/offer.php`, cloneObject,{
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setAllianceObject({
          chkCompany: "1",
          offerContents: "",
          offerFile: undefined,
          offerFileReal: "",
          offerCompany: "",
          offerBusiness: "",
          offerHomepage: "",
          infoFile: undefined,
          infoFileReal: "",
          offerName: "",
          offerPosition: "",
          offerPhone: "",
          offerEmail: "",
          regip: ""
        })

        setCheckedTerms(false)
        setModalObject({
          showModal: true,
          modalText: <p>전송이 완료되었습니다<br />검토 후 연락드리겠습니다</p>
        })
      } catch(error) {
        console.log(error)
      }
    }
  }

  const close = () => {
    setModalObject({
      showModal: false,
      modalText: <p></p>
    })
  }

  return (
    <>
      {modalObject.showModal &&
        <div className="alliance-overlay" />
      }
      <div className="alliance">
        <div className="section-1">
          <PageTitle title="제휴 제안" subTitle="Contact" description={<p>이엘그룹과 함께 할 비즈니스를 제안하세요</p>} />
          <WhiteSquare />
        </div>
        <div className="section-2">

          <div className="section-title">
            <div className="sub-section">
              <p>이엘그룹에 관심을 가지고 <br className="mo-br" />제휴 제안을 보내주셔서 감사합니다</p>
            </div>
          </div>
          <div className="step-wrap" data-aos="fade-up" data-aos-duration="1000">
            <div className="step-area step-01">
              <div className="step-title">
                <strong>Step. 01</strong>
              </div>
              <div className="step-cont">
                <strong className="question-title">제휴 제안을 원하는 이엘그룹 계열사를 선택해주세요</strong>

                <div className="question-list-wrap">
                  <div className="item">
                    <strong className="question-sub-title">계열사 선택 <i>*</i></strong>
                    <div className="question-cont">
                      <div className="radio-wrap">

                      <span className="inp-radio">
                        <label htmlFor="rdo01">
                          <input className={`${allianceObject.chkCompany === "1" ? 'selected' : ''}`} type="radio" id="rdo01" name="chkCompany" value="1" onChange={onChangeInputHandler} />
                          <span className="custom-checkbox" />
                          이엘와이드
                        </label>
                      </span>
                        <span className="inp-radio">
                        <label htmlFor="rdo04">
                          <input className={`${allianceObject.chkCompany === "4" ? 'selected' : ''}`} type="radio" id="rdo04" name="chkCompany" value="4" onChange={onChangeInputHandler} />
                          <span className="custom-checkbox" />
                          이엘라이즈
                        </label>
                      </span>
                        <span className="inp-radio">
                        <label htmlFor="rdo05">
                          <input className={`${allianceObject.chkCompany === "5" ? 'selected' : ''}`} type="radio" id="rdo05" name="chkCompany" value="5" onChange={onChangeInputHandler} />
                          <span className="custom-checkbox" />
                          이엘티비
                        </label>
                      </span>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="step-area step-02">
              <div className="step-title">
                <strong>Step. 02</strong>
              </div>
              <div className="step-cont">
                <strong className="question-title">제휴 제안 내용을 입력해주세요</strong>

                <div className="question-list-wrap">
                  <div className="item">
                    <strong className="question-sub-title">내용입력 <i>*</i></strong>
                    <div className="question-cont">

                      <div className="textarea-wrap">
                        <textarea name="offerContents" value={allianceObject.offerContents} onChange={onChangeInputHandler} />
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <strong className="question-sub-title">제안서 첨부 <i>*</i></strong>
                    <div className="question-cont">

                      <div className="file-wrap">
                    <span className="file-box">
                    <label htmlFor="input-file-1">
                      <input type="text" value={allianceObject.offerFileReal} readOnly/>
                      <input type="file" id="input-file-1" className="upload-hidden" name="1" onChange={onChangeFileHandler} />
                      <span className="file-button"><span>파일첨부</span></span>
                    </label>
                    </span>
                        <p className="file-caution">
                          <span className="file-possible-text">pdf, doc, docx, ppt, pptx 파일만 가능</span>
                          {showWarning.step2 ||
                            <span className="cr-black">파일 크기 20mb 이하</span>
                          }
                          {showWarning.step2 &&
                            <span className="cr-red">파일 크기는 20mb 이하만 가능합니다</span>
                          }
                        </p>
                      </div>

                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div className="step-area step-03">
              <div className="step-title">
                <strong>Step. 03</strong>
              </div>
              <div className="step-cont">
                <strong className="question-title">회사에 대해 알려주세요</strong>

                <div className="question-list-wrap">
                  <div className="item">
                    <strong className="question-sub-title bold">회사명 <i>*</i></strong>
                    <div className="question-cont">
                    <span className="input-text">
                      <input type="text" name="offerCompany" value={allianceObject.offerCompany} onChange={onChangeInputHandler} />
                    </span>
                    </div>
                  </div>
                  <div className="item">
                    <strong className="question-sub-title">업종 <i>*</i></strong>
                    <div className="question-cont">
                    <span className="input-text">
                      <input type="text" name="offerBusiness" value={allianceObject.offerBusiness} onChange={onChangeInputHandler} />
                    </span>
                    </div>
                  </div>
                  <div className="item">
                    <strong className="question-sub-title">홈페이지 <span>(선택)</span></strong>
                    <div className="question-cont">
                    <span className="input-text">
                      <input type="text" name="offerHomepage" value={allianceObject.offerHomepage} onChange={onChangeInputHandler} />
                    </span>
                    </div>
                  </div>
                  <div className="item">
                    <strong className="question-sub-title">회사소개서 <span>(선택)</span></strong>
                    <div className="question-cont">
                      <div className="file-wrap">
                      <span className="file-box">
                        <label htmlFor="input-file-2">
                          <input type="text" value={allianceObject.infoFileReal} readOnly />
                          <input type="file" id="input-file-2" className="upload-hidden" name="2" onChange={onChangeFileHandler} />
                          <span className="file-button"><span>파일첨부</span></span>
                        </label>
                      </span>
                        <p className="file-caution">
                          <span className="file-possible-text">pdf, doc, docx, ppt, pptx 파일만 가능</span>
                          {showWarning.step3 ||
                            <span className="cr-black">파일 크기 20mb 이하</span>
                          }
                          {showWarning.step3 &&
                            <span className="cr-red">파일 크기는 20mb 이하만 가능합니다</span>
                          }
                        </p>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div className="step-area step-04">
              <div className="step-title">
                <strong>Step. 04</strong>
              </div>
              <div className="step-cont">
                <strong className="question-title">담당자 정보를 알려주세요</strong>

                <div className="question-list-wrap">

                  <div className="item row2">

                    <div className="row2-box">
                      <strong className="question-sub-title bold">이름 <i>*</i></strong>
                      <div className="question-cont row2">
                      <span className="input-text">
                        <input type="text" name="offerName" value={allianceObject.offerName} onChange={onChangeInputHandler} />
                      </span>
                      </div>
                    </div>
                    <div className="row2-box">
                      <strong className="question-sub-title bold">직책 <i>*</i></strong>
                      <div className="question-cont row2">
                      <span className="input-text">
                        <input type="text" name="offerPosition" value={allianceObject.offerPosition} onChange={onChangeInputHandler} />
                      </span>
                      </div>
                    </div>

                  </div>

                  <div className="item">
                    <strong className="question-sub-title bold">연락처 <i>*</i></strong>
                    <div className="question-cont">
                    <span className="input-text">
                      <input type="text" maxLength={13} name="offerPhone" value={allianceObject.offerPhone} onChange={onChangeInputHandler} />
                    </span>
                    </div>
                  </div>

                  <div className="item">
                    <strong className="question-sub-title bold">이메일 <i>*</i></strong>
                    <div className="question-cont">
                    <span className="input-text">
                      <input type="email" name="offerEmail" value={allianceObject.offerEmail} onChange={onChangeInputHandler} onBlur={onLeaveFocus} />
                    </span>
                      {emailValidation ||
                        <p className="text-caution">
                          올바른 이메일 주소가 아닙니다.
                        </p>
                      }
                    </div>
                  </div>

                  <div className="personal-info-guide">

                    <strong className="personal-sub-title">개인정보 수집 이용에 대한 안내</strong>
                    <div className="personal-box">
                      <ol>
                        <li><i>1.</i><p>목적: 제휴 제안에 따른 연락처 정보 확인</p></li>
                        <li><i>2.</i><p>항목: 회사(기관)명, 담당자명, 전화번호, 메일주소</p></li>
                        <li>
                          <i>3.</i>
                          <p className="bold">
                            보유기간: 제휴 제안 사항 상담을 위해 제안 등록 후, 90일간 보관하며, 이후 해당 정보를 지체 없이 파기합니다.<br/>
                            <span>단, 제휴 제안의 기각 및 거절시에는 해당 정보는 즉시 파기합니다.</span>
                          </p>
                        </li>
                      </ol>
                      <p>위 정보 수집에 대한 동의를 거부할 권리가 있으며, 동의 거부 시에는 제휴 제안 접수가 제한될 수 있습니다.</p>
                    </div>
                    <span className="inp-checkbox">
                    <label htmlFor="chk01">
                      <input className={`${checkedTerms ? 'selected' : ''}`} type="checkbox" id="chk01" onClick={() => {setCheckedTerms(!checkedTerms)}} />
                      <span className="custom-checkbox" />
                      [필수] 개인정보 수집 및 이용에 동의합니다
                    </label>
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="send-button">
              <img alt="image" src={SendButton} onClick={onClickSendButton} />
            </div>
          </div>
        </div>
        {modalObject.showModal &&
          <div className="alert-box">
            {modalObject.modalText}
            <div onClick={close}>확인</div>
          </div>
        }
      </div>
    </>
  )
}

export default Alliance