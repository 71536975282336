import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import PageTitle from '../../components/PageTitle';
import WhiteSquare from '../../components/WhiteSquare';
import arrowIcon from '../../assets/images/notice-section-3-arrow-icon.png';
import moreBlackButton from '../../assets/images/more-black-button.jpg';
import noticePin from '../../assets/images/notice-icon-pin.png';

import CONFIG from '../../config';

interface INoticeItem {
  idx: string;
  notice_date: string;
  notice_title: string;
}

const Notice = () => {
  const [notice, setNotice] = useState<any>([]);
  const [fixedNotice, setFixedNotice] = useState<any>([]);
  const [pageObject, setPageObject] = useState({
    total: 0,
    current: 1,
  });

  const fetchNotice = () => {
    axios
      .get(`${CONFIG.API_CALL_URL()}/news/notice_list.php?sstart=${(pageObject.current - 1) * 5}&slistscale=5`)
      .then((response) => {
        if (response?.data?.Result_Data?.data) {
          if (response?.data?.Result_Data?.fixdata) setFixedNotice(response?.data?.Result_Data?.fixdata);
          setNotice(notice.concat(response?.data?.Result_Data?.data));
          setPageObject({
            ...pageObject,
            total: response.data.Result_Data.total,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const onClickMore = () => {
    setPageObject({
      ...pageObject,
      current: pageObject.current + 1,
    });
  };

  const renderFixedNotice = () => {
    if (fixedNotice.length === 0) return null;

    return fixedNotice.map((item: INoticeItem, index: number) => {
      return (
        <Link to={`/notice/${item.idx}`} key={item.idx}>
          <div className="notice-wrap notice-fixed">
            <div>
              <div className="notice-pin">
                <img src={noticePin} />
              </div>
              <p className="title">{item.notice_title}</p>
            </div>
            <p className="date">{moment(item.notice_date).format('YYYY.MM.DD')}</p>
          </div>
        </Link>
      );
    });
  };

  const renderNotice = () => {
    if (notice.length === 0) return null;

    return notice.map((item: INoticeItem, index: number) => {
      return (
        <Link to={`/notice/${item.idx}`} key={item.idx}>
          <div className="notice-wrap">
            {/* className="notice-fixed" 클래스 여부에따라 공지사항 아이콘 show/hide */}
            <div>
              <div className="arrow-icon">
                <img src={arrowIcon} />
              </div>
              <p className="title">{item.notice_title}</p>
            </div>
            <p className="date">{moment(item.notice_date).format('YYYY.MM.DD')}</p>
          </div>
        </Link>
      );
    });
  };

  useEffect(() => {
    fetchNotice();
  }, []);

  useEffect(() => {
    fetchNotice();
  }, [pageObject.current]);

  return (
    <div className="notice">
      <div className="section-1">
        <PageTitle title="Notice" subTitle="NEWS" description={<p>이엘그룹의 공지사항입니다</p>} />
        <WhiteSquare />
      </div>
      <div className="section-2">
        <div className="section-title">
          <div className="section-title-sub-wrap-1">
            <p>Notice</p>
            <div className="sub-section">
              <div className="divider" />
              <p>이엘그룹의 공지사항입니다</p>
            </div>
          </div>
          <div className="section-title-sub-wrap-2">
            <p className="title">전체</p>
            <p className="count">{pageObject.total}건</p>
          </div>
        </div>
      </div>
      <div className="section-3" data-aos="fade-up" data-aos-duration="1000">
        <div className="notice-list">
          {renderFixedNotice()}
          {renderNotice()}
          {notice.length === 0 && fixedNotice.length === 0 && (
            <div className="empty-message">등록된 공지사항이 없습니다</div>
          )}
        </div>
        {notice.length < pageObject.total && (
          <div className="more-button" onClick={onClickMore}>
            <img alt="image" src={moreBlackButton} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Notice;
