import React, {useState, Dispatch, SetStateAction, useEffect} from "react";
import { useParams } from 'react-router-dom';
import moment from "moment";
import axios from "axios";
import share from '../../assets/images/share-button.png'
import BackButton from "../../components/BackButton";

import CONFIG from "../../config";

interface Props {
  setShowOverlay: Dispatch<SetStateAction<boolean>>
}

interface INews {
  "idx": string,
  "news_title": string,
  "news_contents": string,
  "news_thumb": string,
  "press_date": string
}

const ArtistNews = () => {

  const { id } = useParams();

  const [showAlert, setShowAlert] = useState(false)
  const [news, setNews] = useState<INews>();

  const copyURL = () => {
    // setShowOverlay(true)
    setShowAlert(true)
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  const close = () => {
    setShowAlert(false)
    // setShowOverlay(false)
  }

  useEffect(() => {
      axios.get(`${CONFIG.API_CALL_URL()}/entertainment/news_info.php?idx=${id}`)
        .then((response) => setNews(response.data.Result_Data.data))
        .catch((error) => console.log(error));
  }, [])

  return (
    <>
      {showAlert &&
        <div className="news-overlay" />
      }
      <div className="news-page">
        <div className="title">{news?.news_title}</div>
        <div>
          <div className="date">{moment(news?.press_date).format('YYYY.MM.DD')}</div>
          <div className="share-button" onClick={copyURL}>
            <img alt="image" src={share} />
          </div>
        </div>
        <div>
          <div className="image-wrap">
            <img alt="image" src={`${CONFIG.FILE_URL()}/storage/news/management/${news?.news_thumb}`} />
          </div>
          <pre>{news?.news_contents}</pre>
        </div>
        <BackButton />

        {showAlert &&
          <div className="alert-box">
            <p>URL이 복사되었습니다.</p>
            <div onClick={close}>확인</div>
          </div>
        }
      </div>
    </>
  )
}

export default ArtistNews