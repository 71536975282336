import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as SymbolLogoBlue } from '../assets/images/header-symbol-logo-white.svg';

interface Props {
  setShowOverlay: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ setShowOverlay }: Props) => {
  const location = useLocation();

  const [isFocus, setIsFocus] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleOnFocus = () => {
    setIsFocus(true);
    setShowOverlay(true);
  };

  const handleOutFocus = () => {
    setIsFocus(false);
    setShowOverlay(false);
  };

  const currentHeaderTopMenu = () => {
    const path = location.pathname.slice(0, location.pathname.lastIndexOf('/'));

    if (path === '/news' || path === '/artist-news' || path === '/notice') return 'news';

    switch (location.pathname) {
      case '/story':
      case '/history':
      case '/culture':
      case '/family':
      case '/elwide':
      case '/elmedia':
      case '/elrise':
      case '/eltv':
      case '/elpark':
        return 'about';
      case '/press-release':
      case '/elnow':
      case '/notice':
        return 'news';
      case '/contact':
      case '/alliance':
        return 'contact';
      case '/recruit':
        return 'recruit';
      case '/signup':
        return 'signup';
      case '/':
        return '';
      default:
        return 'business';
    }
  };

  const currentPath = () => {
    return location.pathname.slice(1);
  };

  const isDetailPage = () => {
    const path = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
    return path === '/news' || path === '/artist-news' || path === '/notice';
  };

  const handleScroll = () => {
    const scrollHeight = document.body.scrollTop || document.documentElement.scrollTop;
    setScrollPosition(scrollHeight);
  };

  const investmentSrchKey = ():string  => {
    if(!location.search){
      return '';
    }
    const param = location.search.replace('?', '');
    const paramList = param.split('&');
    const paramKeys: string[] = [];
    const paramValues: string[] = [];

    paramList.forEach((item) => {
      const arr = item.split('=');
      paramKeys.push(arr[0]);
      paramValues.push(arr[1]);
    });

    // 페이지 파라미터에 대한 키 찾기
    const key = paramKeys.indexOf('page');
    return key === -1 ? '' : paramValues[key];
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={`header ${scrollPosition >= 40 || isFocus || isDetailPage() ? 'full' : 'void'}`}>
      <div className="top-menu">
        <Link to="/" onClick={handleOutFocus}>
          <SymbolLogoBlue width="149" height="30" />
        </Link>
        <div className="right" onMouseEnter={handleOnFocus}>
          <div className={`menu ${currentHeaderTopMenu() === 'about' ? 'selected' : ''}`}>
            <Link to="/story" onClick={handleOutFocus}>
              <p>ABOUT US</p>
            </Link>
            <div className="bar" />
          </div>
          <div className={`menu ${currentHeaderTopMenu() === 'business' ? 'selected' : ''}`}>
            <Link to="/documentary" onClick={handleOutFocus}>
              <p>BUSINESS</p>
            </Link>
            <div className="bar" />
          </div>
          <div className={`menu ${currentHeaderTopMenu() === 'news' ? 'selected' : ''}`}>
            <Link to="/press-release" onClick={handleOutFocus}>
              <p>NEWS</p>
            </Link>
            <div className="bar" />
          </div>
          <div className={`menu ${currentHeaderTopMenu() === 'contact' ? 'selected' : ''}`}>
            <Link to="/contact" onClick={handleOutFocus}>
              <p>CONTACT</p>
            </Link>
            <div className="bar" />
          </div>
          <div className={`menu ${currentHeaderTopMenu() === 'recruit' ? 'selected' : ''}`}>
            <Link to="/recruit" onClick={handleOutFocus}>
              <p>RECRUIT</p>
            </Link>
            <div className="bar" />
          </div>
          {/* <div className={`menu ${currentHeaderTopMenu() === 'signup' ? 'selected' : ''}`}>
            <Link to="/signup" onClick={handleOutFocus}>
              <p>SIGN UP</p>
            </Link>
            <div className="bar" />
          </div> */}
        </div>
      </div>
      {isFocus && (
        <div className="bottom-menu" onMouseLeave={handleOutFocus}>
          <div className="category">
            <p className="category-title">ABOUT US</p>
            <Link to="/story">
              <p className={`bold-white ${currentPath() === 'elstory' ? 'selected' : ''} `} onClick={handleOutFocus}>
                EL Story
              </p>
            </Link>
            <Link to="/story">
              <p
                className={`normal-white ${currentPath() === 'story' ? 'selected' : ''}`}
                onClick={handleOutFocus}
              >
                경영철학
              </p>
            </Link>
            <Link to="/history">
              <p
                className={`normal-white ${currentPath() === 'history' ? 'selected' : ''}`}
                onClick={handleOutFocus}
              >
                회사연혁
              </p>
            </Link>
            <Link to="/culture">
              <p
                className={`normal-white ${currentPath() === 'culture' ? 'selected' : ''}`}
                onClick={handleOutFocus}
              >
                이엘문화
              </p>
            </Link>
            <Link to="/family" style={{ cursor: 'initial' }}>
              <p className={`bold-white ${currentPath() === 'family' ? 'selected' : ''} `} onClick={handleOutFocus}>
                EL Family
              </p>
            </Link>
            <a href="http://elwide.kr/">
              <p
                className={`normal-white mt24 ${currentPath() === 'el-wide' ? 'selected' : ''}`}
                onClick={handleOutFocus}
              >
                이엘와이드
              </p>
            </a>
            <a href="http://elrise.kr/">
              <p className={`normal-white ${currentPath() === 'rise' ? 'selected' : ''}`} onClick={handleOutFocus}>
                이엘라이즈
              </p>
            </a>
            <a href="http://eltv.kr/">
              <p className={`normal-white ${currentPath() === 'tv' ? 'selected' : ''}`} onClick={handleOutFocus}>
                이엘티비
              </p>
            </a>
          </div>
          <div className="category business">
            <p className="category-title">BUSINESS</p>
            <div className="nav-row">
              <Link to="/documentary" onClick={handleOutFocus}>
                <p className="bold-white">Entertainment</p>
              </Link>
              <Link to="/documentary" onClick={() => window.location.replace('/documentary')}>
                <p
                  className={`normal-white ${currentPath() === 'documentary' ? 'selected' : ''}`}
                  onClick={handleOutFocus}
                >
                  다큐멘터리
                </p>
              </Link>
              <Link to="/music">
                <p className={`normal-white ${currentPath() === 'music' ? 'selected' : ''}`} onClick={handleOutFocus}>
                  뮤직
                </p>
              </Link>
              {/* <Link to="/platform"> */}
              {/*   <p className={`normal-white ${currentPath() === 'platform' ? 'selected' : ''}`} onClick={handleOutFocus}> */}
              {/*     플랫폼 */}
              {/*   </p> */}
              {/* </Link> */}
            </div>
            <div className="nav-row">
              <Link to="/brands">
                <p className={`bold-white ${currentPath() === 'brands' ? 'selected' : ''}`} onClick={handleOutFocus}>
                  Brands
                </p>
              </Link>
              <Link to="/healthy">
                <p
                  className={`normal-white mt24 ${currentPath() === 'healthy' ? 'selected' : ''}`}
                  onClick={handleOutFocus}
                >
                  헬시플레이스
                </p>
              </Link>
              <Link to="/inhever">
                <p className={`normal-white ${currentPath() === 'inhever' ? 'selected' : ''}`} onClick={handleOutFocus}>
                  인헤버
                </p>
              </Link>
              <Link to="/ibim">
                <p className={`normal-white ${currentPath() === 'ibim' ? 'selected' : ''}`} onClick={handleOutFocus}>
                  아이빔
                </p>
              </Link>
              <Link to="/gshi">
                <p className={`normal-white ${currentPath() === 'gshi' ? 'selected' : ''}`} onClick={handleOutFocus}>
                  지샤인
                </p>
              </Link>
              <Link to="/ruthair">
                <p className={`normal-white ${currentPath() === 'ruthair' ? 'selected' : ''}`} onClick={handleOutFocus}>
                  루트헤어
                </p>
              </Link>
            </div>
            <div className="nav-row">
              <Link to="/investment">
                <p className={`bold-white ${currentPath() === 'investment' && investmentSrchKey() === '' ? 'selected' : ''}`} onClick={handleOutFocus}>
                  Investment
                </p>
              </Link>
              <Link to="/investment?page=platform">
                <p
                  className={`normal-white mt24 ${currentPath() === 'investment' && investmentSrchKey() === 'platform' ? 'selected' : ''}`}
                  onClick={handleOutFocus}
                >
                  Platform
                </p>
              </Link>
              <Link to="/investment?page=blockchain">
                <p
                  className={`normal-white ${currentPath() === 'investment' && investmentSrchKey() === 'blockchain' ? 'selected' : ''}`}
                  onClick={handleOutFocus}
                >
                  Block chain
                </p>
              </Link>
              <Link to="/fintech">
                <p
                  className={`normal-white ${currentPath() === 'fintech' && investmentSrchKey() === 'fintech' ? 'selected' : ''}`}
                  onClick={handleOutFocus}
                >
                  FinTech
                </p>
              </Link>
              <Link to="/investment?page=contents">
                <p
                  className={`normal-white ${currentPath() === 'contents' && investmentSrchKey() === 'contents' ? 'selected' : ''}`}
                  onClick={handleOutFocus}
                >
                  Contents
                </p>
              </Link>
            </div>
          </div>
          {/* <div className="category"> */}
          {/*   <p className="category-title">NEWS</p> */}
          {/*   <Link to="/press-release"> */}
          {/*     <p */}
          {/*       className={`bold-white ${currentPath() === 'press-release' ? 'selected' : ''}`} */}
          {/*       onClick={handleOutFocus} */}
          {/*     > */}
          {/*       Press Release */}
          {/*     </p> */}
          {/*   </Link> */}
          {/* </div> */}
          <div className="category">
            <p className="category-title">NEWS</p>
            <Link to="/press-release">
              <p
                className={`bold-white ${currentPath() === 'press-release' ? 'selected' : ''} `}
                onClick={handleOutFocus}
              >
                Press Release
              </p>
            </Link>
            <Link to="/elnow">
              <p className={`bold-white ${currentPath() === 'elnow' ? 'selected' : ''} `} onClick={handleOutFocus}>
                EL Now
              </p>
            </Link>
            <Link to="/notice">
              <p className={`bold-white ${currentPath() === 'notice' ? 'selected' : ''} `} onClick={handleOutFocus}>
                Notice
              </p>
            </Link>
            {/* <Link to="/notice"> */}
            {/*   <p className={`bold-white ${currentPath() === 'notice' ? 'selected' : ''} `} onClick={handleOutFocus}> */}
            {/*     Notice */}
            {/*   </p> */}
            {/* </Link> */}
          </div>
          <div className="category">
            <p className="category-title">CONTACT</p>
            <Link to="/contact">
              <p className={`bold-white ${currentPath() === 'contact' ? 'selected' : ''} `} onClick={handleOutFocus}>
                오시는 길
              </p>
            </Link>
            <Link to="/alliance">
              <p className={`bold-white ${currentPath() === 'alliance' ? 'selected' : ''} `} onClick={handleOutFocus}>
                제휴 제안
              </p>
            </Link>
          </div>
          <div className="category">
            <p className="category-title">RECRUIT</p>
            <Link to="/recruit">
              <p className={`bold-white ${currentPath() === 'recruit' ? 'selected' : ''} `} onClick={handleOutFocus}>
                인재 채용
              </p>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
