import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import PageTitle from '../components/PageTitle';
import WhiteSquare from '../components/WhiteSquare';

import ellifunSection21 from '../assets/platform/ellifun-section-2-1.jpg';
import ellifunSection22 from '../assets/platform/ellifun-section-2-2.jpg';
import ellifunSection23 from '../assets/platform/ellifun-section-2-3.jpg';

import ellifunSection3 from '../assets/platform/ellifun-section-3.jpg';
import ellispaceSection2 from '../assets/platform/ellispace-section-2.jpg';
import MellispaceSection2 from '../assets/platform/mobile-ellispace-section-2.jpg';

import ellispaceSection41 from '../assets/platform/ellispace-section-4-1.jpg';
import ellispaceSection42 from '../assets/platform/ellispace-section-4-2.jpg';
import ellispaceSection43 from '../assets/platform/ellispace-section-4-3.jpg';

import BackButton from '../components/BackButton';

const text: any = {
  1: {
    title: 'ElliPARK',
    description: (
      <p className="description">
        <span>재미와 나눔</span>이 있는
        <br /> 참여형 라이프 스타일 커뮤니티
      </p>
    ),
  },

  2: {
    title: 'ElliSPACE',
    description: (
      <p className="description">
        <span>또 다른 세상에서</span>
        <br /> 내가 상상하던 모든 것이 현실이 됩니다
      </p>
    ),
  },
};

const TastePark = () => {
  const [selectedTab, setSelectedTab] = useState(1);

  const onClickTab = (index: number) => setSelectedTab(index);

  useEffect(() => {
    AOS.refreshHard();
  });
  return (
    <div className="taste-park">
      <div className="section-1">
        <PageTitle
          title="ElliPARK"
          subTitle="Platform"
          description={
            <p>
              아티스트와 크리에이터 중심의
              <br className="show-mobile" /> 전방위 디지털 유니버스 구현
            </p>
          }
        />
        <WhiteSquare />
      </div>

      <div className="section-2 tit-section">
        <p className="txt" data-aos="fade-right" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
          <span className="cr_lavender">재미와 나눔이</span> 있는 <br />
          <span className="txt_bg">참여형 라이프 스타일 커뮤니티</span>
        </p>
        <strong className="tit" data-aos="fade-left" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
          ElliPARK
        </strong>
      </div>

      <div>
        <div
          className="ellifun-section-1"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="top-bottom"
        />
        <div className="ellifun-section-2">
          <div
            className="text-wrap"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-anchor-placement="top-bottom"
          >
            <p>모든 활동이</p>
            <p>
              보상이 되는 <span>수익형 체험 공간</span>
            </p>
          </div>
          <div
            className="image-wrap"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-anchor-placement="top-bottom"
          >
            <img alt="image" src={ellifunSection21} />
            <img alt="image" src={ellifunSection22} />
            <img alt="image" src={ellifunSection23} />
          </div>
        </div>
        <div className="ellifun-section-3">
          <div className="background" />
          <div
            className="text-wrap"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-anchor-placement="top-bottom"
          >
            <p>어제의 참여자가</p>
            <p>
              오늘의 <span>크리에이터</span>가 되는 공간
            </p>
          </div>
          <img alt="image" src={ellifunSection3} data-aos="fade-up" data-aos-duration="1000" />
        </div>
        <div
          className="ellifun-section-4"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-anchor-placement="top-bottom"
        >
          <p>ElliPARK에서</p>
          <p>
            함께 가치를 나누는
            <br className="show-mobile" /> 행복한 경험을 제공합니다
          </p>
        </div>
      </div>

      <BackButton />
    </div>
  );
};

export default TastePark;
