import React from 'react';

import image2 from '../../assets/brands/inhaver-2.jpg'
import image3 from '../../assets/brands/inhaver-3.jpg'
import image4 from '../../assets/brands/inhaver-4.jpg'
import image5 from '../../assets/brands/inhaver-5.jpg'

import BackButton from "../../components/BackButton";
import ShopButton from "../../components/ShopButton";

const Inhaver = () => {
  return (
    <div className="inhaver">
      <div className="section-1" >
        <div className="section-1-text-wrap">
          <p className="section-1-title" data-aos="fade-right" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">인헤버</p>
          <p style={{marginBottom: "33px"}} data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">본연의 아름다움을 간직하는 브랜드 <b>INHEVER</b></p>

          <p style={{marginBottom: "33px"}} data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">시간이 흘러도 변하지 않는 아름다움에 대한 열망<br /> 어디에서나 빛나는 여배우에서 답을 찾다</p>

          <p style={{marginBottom: "33px"}} data-aos="fade-right" data-aos-delay="900" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">당신의 아름다움이 늘 빛날 수 있도록 <br /> 여배우 메이크업 노하우를 가득 담아<br className="show-mobile" /> 당신께 전합니다</p>
        </div>
      </div>
      <div className="section-2" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
        <div className="brand-logo show-pc" style={{ backgroundImage: `url(${image2})` }} />
        <div className="section-3">
          <div className="section-4">
            <div className="product-image-1" style={{ backgroundImage: `url(${image3})` }} />
            <div className="section-6">
              <div className="product-image-2" style={{ backgroundImage: `url(${image4})` }} />
              <div className="product-image-3" style={{ backgroundImage: `url(${image5})` }} />
            </div>
          </div>
          <div className="brand-logo show-mobile" style={{ backgroundImage: `url(${image2})` }} />
          <div className="section-5">
            <p className="title">인헤버</p>
            <p className="description">본연의 아름다움을 <br />간직하는 브랜드</p>
            <a href="https://smartstore.naver.com/elrise/category/7b0cf22dfe0f483ba35b5e97d5140597?cp=1">
              <ShopButton />
            </a>
          </div>
        </div>
      </div>
      <BackButton />
    </div>
  )
}

export default Inhaver