import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga";

const RouteChangeTracker = () => {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if(window.location.host !== 'localhost:3000' && window.location.host !== 'dev.www.elgroup.kr') {
      if(process.env.REACT_APP_GOOGLE_ANALYTICS_ID) ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
    }
    setInitialized(true)
  }, [])

  useEffect(() => {
    if(initialized) ReactGA.pageview(location.pathname + location.search)
  }, [initialized, location])
}

export default RouteChangeTracker