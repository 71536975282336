import React from 'react';

import image2 from '../../assets/brands/ibim-2.jpg'
import image3 from '../../assets/brands/ibim-3.jpg'
import image4 from '../../assets/brands/ibim-4.jpg'
import image5 from '../../assets/brands/ibim-5.jpg'
import image6 from '../../assets/brands/ibim-6.jpg'
import image7 from '../../assets/brands/ibim-7.jpg'
import image8 from '../../assets/brands/ibim-8.jpg'
import shop from '../../assets/brands/healthy-shop-button.png'

import BackButton from "../../components/BackButton";
import ShopButton from "../../components/ShopButton";

const Ivim = () => {
  return (
    <div className="ivim">
      <div className="section-1">
        <div className="section-1-text-wrap">
          <p className="section-1-title" data-aos="fade-right" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">아이빔</p>
          <p style={{marginBottom: "33px"}} data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000" data-aos-anchor-placement="top-bottom"><b>I brand what I made</b></p>
          <p style={{marginBottom: "33px"}} data-aos="fade-right" data-aos-delay="600" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">당신이 생각해 두었던 아이디어를 보내주세요<br />
            뷰티 크리에이터가 메신저가 되어<br className="show-mobile" /> 당신의 아이디어를 실현시켜 드릴게요<br />
            바라던 메이크업이 이뤄지는 순간<br className="show-mobile" /> 당신의 아이디어가 곧 당신의 브랜드가 됩니다<br />
          </p>
          <p style={{marginBottom: "33px"}} data-aos="fade-right" data-aos-delay="900" data-aos-duration="1000" data-aos-anchor-placement="top-bottom"><b>IBIM</b>이 당신의 아이디어를<br className="show-mobile" /> 브랜드로 만들어 드립니다</p>
        </div>
      </div>
      <div className="section-2" data-aos="fade-up" data-aos-duration="1000">
        <div className="brand-logo show-pc" style={{ backgroundImage: `url(${image2})` }} />
        <div className="section-3">
          <div className="section-4-1">
            <div>
              <div style={{ backgroundImage: `url(${image3})` }} />
              <div style={{ backgroundImage: `url(${image4})` }} />
              <div style={{ backgroundImage: `url(${image5})` }} />
            </div>
            <div>
              <div style={{ backgroundImage: `url(${image6})` }} />
              <div style={{ backgroundImage: `url(${image7})` }} />
              <div style={{ backgroundImage: `url(${image8})` }} />
            </div>
          </div>
          <div className="brand-logo show-mobile" style={{ backgroundImage: `url(${image2})` }} />
          <div className="section-5">
            <p className="title">아이빔</p>
            <p className="description">당신의 아이디어가 곧<br />브랜드가 되는 IBIM</p>
            <a href="https://ibim.kr/index.html">
              <ShopButton />
            </a>
          </div>
        </div>
      </div>

      <BackButton />
    </div>
  )
}

export default Ivim