import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import share from '../assets/images/share-button.png';
import BackButton from '../../components/BackButton';

import CONFIG from '../../config';

interface Props {
  setShowOverlay: Dispatch<SetStateAction<boolean>>;
}

interface INewsItem {
  idx: string;
  notice_contents: string;
  notice_date: string;
  notice_title: string;
  file1_name: string;
  file1_realname: string;
  file2_name: string;
  file2_realname: string;
  file3_name: string;
  file3_realname: string;
}

const NoticeDetail = () => {
  const { id } = useParams();

  const [notice, setNotice] = useState<INewsItem>();

  const copyURL = () => {
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  useEffect(() => {
    axios
      .get(`${CONFIG.API_CALL_URL()}/news/notice_info.php?idx=${id}`)
      .then((response) => {
        setNotice(response.data.Result_Data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <div className="notice-detail">
        <div className="title">{notice?.notice_title}</div>
        <div>
          <div className="date">{moment(notice?.notice_date).format('YYYY.MM.DD')}</div>
        </div>
        <div>
          <pre>{notice?.notice_contents}</pre>
        </div>
        {/* 첨부파일 */}
        {(notice?.file1_name !== null || notice?.file2_name !== null || notice?.file3_name !== null) && (
          <div className="attachment-file">
            <strong className="file-tit">첨부파일</strong>
            <div className="file-wrap">
              {notice?.file1_name !== null && (
                <a href={`${CONFIG.FILE_URL()}/storage/news/notice/${notice?.file1_name}`} className="file-box" download={notice?.file1_realname}>
                  {notice?.file1_realname}
                </a>
              )}
              {notice?.file2_name !== null && (
                <a href={`${CONFIG.FILE_URL()}/storage/news/notice/${notice?.file2_name}`} className="file-box" download={notice?.file2_realname}>
                  {notice?.file2_realname}
                </a>
              )}
              {notice?.file3_name !== null && (
                <a href={`${CONFIG.FILE_URL()}/storage/news/notice/${notice?.file3_name}`} className="file-box" download={notice?.file3_realname}>
                  {notice?.file3_realname}
                </a>
              )}
            </div>
          </div>
        )}

        <BackButton />
      </div>
    </div>
  );
};

export default NoticeDetail;
