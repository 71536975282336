import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga';
import { hydrate, render } from 'react-dom';
import App from './App';
import ScrollToTop from './components/ScrollToTop';

// const root = document.getElementById('root');
//
// if (root.hasChildNodes()) {
//   hydrate(
//     <React.StrictMode>
//       <BrowserRouter>
//         <ScrollToTop />
//         <HelmetProvider>
//           <App />
//         </HelmetProvider>
//       </BrowserRouter>
//     </React.StrictMode>,
//     root,
//   );
// } else {
//   render(
//     <React.StrictMode>
//       <BrowserRouter>
//         <ScrollToTop />
//         <HelmetProvider>
//           <App />
//         </HelmetProvider>
//       </BrowserRouter>
//     </React.StrictMode>,
//     root,
//   );
// }
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
