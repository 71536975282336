import React, { useState, useEffect } from 'react';
import PageTitle from '../components/PageTitle';
import WhiteSquare from '../components/WhiteSquare';
import train from '../assets/images/contact-section-3-train.jpg';
import bus from '../assets/images/contact-section-3-bus.jpg';
import trainContent from '../assets/images/contact-section-3-train-content.jpg';
import busContent from '../assets/images/contact-section-3-bus-content.jpg';
import MtrainContent from '../assets/images/mobile-contact-section-3-train-content.jpg';
import MbusContent from '../assets/images/mobile-contact-section-3-bus-content.jpg';
import marker from '../assets/images/contact-marker.png';

const { kakao } = window;

const address = {
  1: {
    title: '이엘와이드',
    content: <p className="description">서울특별시 마포구 월드컵북로 402(상암동, KGIT센터) 24층</p>,
    // tel: <p className="tel">02-516-5502</p>,
    email: <p className="email">contact@elwide.com</p>,
  },
  4: {
    title: '이엘라이즈',
    content: <p className="description">서울특별시 마포구 월드컵북로 402(상암동, KGIT센터) 24층</p>,
    tel: <p className="tel">070-4283-5505</p>,
  },
  5: {
    title: '이엘티비',
    content: <p className="description">서울특별시 마포구 월드컵북로 54길12 디지털 매직스페이스 8층</p>,
    tel: <p className="tel">0507-1470-5702</p>,
  },
};

const Contact = () => {
  const [selectedTab, setSelectedTab] = useState(1);

  useEffect(() => {
    const mapOptions = {
      center: new kakao.maps.LatLng(37.580259782312055, 126.88683439764758),
      level: 3,
    };

    const mobileMapOptions = {
      center: new kakao.maps.LatLng(37.58018511262319, 126.8888496617747),
      level: 3,
    };

    const map1 = new kakao.maps.Map(document.getElementById('map-div'), mapOptions);
    const map2 = new kakao.maps.Map(document.getElementById('mobile-map-div'), mobileMapOptions);

    const imageSrc = marker; // 마커이미지의 주소입니다
    const imageSize = new kakao.maps.Size(50, 61); // 마커이미지의 크기입니다
    const imageOption = { offset: new kakao.maps.Point(28, 68) };

    map1.setZoomable(false);
    map2.setZoomable(false);

    const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);

    const markerPosition1 = new kakao.maps.LatLng(37.58017614000412, 126.88888929886676);
    const markerPosition2 = new kakao.maps.LatLng(37.58088386541389, 126.8893637366652);

    const marker1 = new kakao.maps.Marker({
      position: selectedTab === 5 ? markerPosition2 : markerPosition1,
      image: markerImage,
    });
    const marker2 = new kakao.maps.Marker({
      position: selectedTab === 5 ? markerPosition2 : markerPosition1,
      image: markerImage,
    });

    marker1.setMap(map1);
    marker2.setMap(map2);
  }, [selectedTab]);

  const onClickTab = (index) => setSelectedTab(index);

  return (
    <div className="contact">
      <div className="section-1">
        <PageTitle title="오시는 길" subTitle="Contact" description={<p>위치 및 문의사항 안내</p>} />
        <WhiteSquare />
        <div className="tab-wrap">
          <div
            className={`tab ${selectedTab === 1 ? 'selected' : ''}`}
            onClick={() => {
              onClickTab(1);
            }}
          >
            이엘와이드
          </div>
          <div
            className={`tab ${selectedTab === 4 ? 'selected' : ''}`}
            onClick={() => {
              onClickTab(4);
            }}
          >
            이엘라이즈
          </div>
          <div
            className={`tab ${selectedTab === 5 ? 'selected' : ''}`}
            onClick={() => {
              onClickTab(5);
            }}
          >
            이엘티비
          </div>
        </div>
      </div>
      <div className="section-2">
        <div className="map-wrap" data-aos="fade-up" data-aos-duration="1000">
          <div id="map-div" className="map show-pc" />
          <div id="mobile-map-div" className="map show-mobile" />
          {selectedTab !== 3 && selectedTab !== 1 && (
            <div className="address-wrap">
              <p className="title">{address[selectedTab].title}</p>
              <div>{address[selectedTab].content}</div>
              <div>{address[selectedTab].tel}</div>
            </div>
          )}
          {selectedTab === 3 && (
            <div className="address-wrap">
              <p className="title">{address[selectedTab].title}</p>
              <div>{address[selectedTab].content}</div>
              <div>{address[selectedTab].tel}</div>
            </div>
            /*
            <div className="address-wrap">
              <div className="flex-wrap">
                <p className="title">{address[selectedTab].title}</p>
                <div>
                  {address[selectedTab].platformTel}
                  {address[selectedTab].managementTel}
                </div>
              </div>
              <div>{address[selectedTab].content}</div>
            </div>
            */

          )}
          {selectedTab === 1 && (
            <div className="address-wrap">
              <p className="title">{address[selectedTab].title}</p>
              <div>{address[selectedTab].content}</div>
              <div>{address[selectedTab].email}</div>
            </div>
          )}
        </div>
      </div>
      <div className="section-3">
        <div className="traffic-wrap" data-aos="fade-up" data-aos-duration="1000">
          <div className="title">
            <img alt="image" src={train} />
            <p>주변 지하철</p>
          </div>
          <img className="content show-pc" alt="image" src={trainContent} />
          <img className="content show-mobile" alt="image" src={MtrainContent} />
        </div>
        <div className="traffic-wrap" data-aos="fade-up" data-aos-duration="1000">
          <div className="title">
            <img alt="image" src={bus} />
            <p>주변 버스</p>
          </div>
          <img className="content show-pc" alt="image" src={busContent} />
          <img className="content show-mobile" alt="image" src={MbusContent} />
        </div>
      </div>
    </div>
  );
};

export default Contact;
