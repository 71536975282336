import React, { useState } from "react";
import { Link } from "react-router-dom";
import plus from "../assets/family/plus-icon.png";

const FamilyNavigation = () => {

  const [hoverItem, setHoverItem] = useState('')

  const onFocus = (value: string) => setHoverItem(value);
  const outFocus = () => setHoverItem('')

  return (
    <div>
      <div className="show-pc">
        <div className="family-navigation" onMouseLeave={outFocus}>
          <a href="http://elwide.kr/" className="button wide">
            <div onMouseEnter={() => {onFocus('wide')}}>이엘와이드</div>
            <div className={`hover-button ${hoverItem === 'wide' ? 'selected' : ''}`}>
              <div className="half-circle">
                <img alt="image" src={plus} />
              </div>
              <p className="eng-name">ELwide</p>
              <p className="kor-name">이엘와이드</p>
              <p className="description">이엘그룹의 지주사</p>
            </div>
          </a>
          <a href="http://elrise.kr/" className="button rise">
            <div onMouseEnter={() => {onFocus('rise')}}>이엘라이즈</div>
            <div className={`hover-button ${hoverItem === 'rise' ? 'selected' : ''}`}>
              <div className="half-circle">
                <img alt="image" src={plus} />
              </div>
              <p className="eng-name">ELrise</p>
              <p className="kor-name">이엘라이즈</p>
              <p className="description">라이프스타일 브랜드&제품 개발</p>
            </div>
          </a>
          <a href="http://eltv.kr/" className="button tv">
            <div onMouseEnter={() => {onFocus('tv')}}>이엘티비</div>
            <div className={`hover-button ${hoverItem === 'tv' ? 'selected' : ''}`}>
              <div className="half-circle">
                <img alt="image" src={plus} />
              </div>
              <p className="eng-name">ELtv</p>
              <p className="kor-name">이엘티비</p>
              <p className="description">스토리기반의 다큐멘터리 제작</p>
            </div>
          </a>
        </div>
      </div>
      <div className="show-mobile">
        <div className="family-navigation">
          <a href="http://elwide.kr/">
            <div className="button wide">이엘와이드 <img alt="image" src={plus} /> </div>
          </a>
          <a href="http://elrise.kr/">
            <div className="button rise">이엘라이즈 <img alt="image" src={plus} /> </div>
          </a>
          <a href="http://eltv.kr/">
            <div className="button tv">이엘티비 <img alt="image" src={plus} /> </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default FamilyNavigation