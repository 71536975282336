import React, { useState, useRef } from 'react';
import Slider from "react-slick";

import image1 from '../assets/images/home-1.jpg'
import image2 from '../assets/images/home-2.jpg'
import image3 from '../assets/images/home-3.jpg'
import imageMo1 from '../assets/images/home-1-mo.jpg'
import imageMo2 from '../assets/images/home-2-mo.jpg'
import imageMo3 from '../assets/images/home-3-mo.jpg'

const Home = () => {

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const sliderRef = useRef<any>();

  const settings = {
    pauseOnHover: false,
    swipe: false,
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // beforeChange: (current:any) => {
    //   setCurrentSlideIndex(current)
    // },
    afterChange: (currentSlide: any) => {
      setCurrentSlideIndex(currentSlide)
    }
  };

  const goToSlide = (page: number) => {
    setCurrentSlideIndex(page)
    sliderRef.current.slickGoTo(page)
  }

  return (
    <div className="home">
      <Slider {...settings} ref={sliderRef}>
        <div className="content-wrap">
          <div className="text-wrap">
            <p className="title title-1">
              <span>E</span>ntertaining<br/> <span>L</span>ife
            </p>
            <div className="divider" />
            <p className="description">함께 만들어가는 유쾌한 라이프 <br/>이엘그룹은 그 즐거움을 세상과 이어갑니다</p>
          </div>
          <img src={image1} className="show-pc" />
          <img src={imageMo1} className="show-mobile" />
        </div>
        <div className="content-wrap">
          <div className="text-wrap">
            <p className="title title-2">
              <span>A Better</span><br/>Coexistence
            </p>
            <div className="divider" />
            <p className="description">공존의 가치를 담아 엔터테인먼트<br />그 이상의 감동을 전합니다</p>
          </div>
          {/* <img src={image2} /> */}
          <img src={image2} className="show-pc" />
          <img src={imageMo2} className="show-mobile" />
        </div>
        <div className="content-wrap">
          <div className="text-wrap">
            <p className="title title-3">
              <span>Makes a </span><br/>Good Culture
            </p>
            <div className="divider" />
            <p className="description">이엘이 만드는 것은 문화가 되고<br /> 더 나은 삶이 되게 합니다</p>
          </div>
          {/* <img src={image3} /> */}
          <img src={image3} className="show-pc" />
          <img src={imageMo3} className="show-mobile" />
        </div>
      </Slider>
      <div className="tab-wrap">
        <p className={`tab ${currentSlideIndex === 0 ? 'selected' : ''}`} onClick={() => {goToSlide(0)}}>Entertaining Life</p>
        <p className={`tab ${currentSlideIndex === 1 ? 'selected' : ''}`} onClick={() => {goToSlide(1)}}>A Better Coexistence</p>
        <p className={`tab ${currentSlideIndex === 2 ? 'selected' : ''}`} onClick={() => {goToSlide(2)}}>Makes a Good Culture</p>
      </div>
       <p className="copyright">copyright © ELgroup. All Rights Reserved.</p>
    </div>
  );
};

export default Home;
