import React from "react";

const Ia = () => {
  return (
    <div>
      aaa
    </div>
  )
}

export default Ia
