import React from 'react';
import { ReactComponent as LogoBlue } from '../assets/images/header-logo-blue.svg';

const Footer = () => {
  return (
    <div className="footer">
      <div>
        <table className="pc-footer">
          <tbody>
            <tr>
              <td>이엘와이드, 이엘라이즈 | 서울특별시 마포구 월드컵북로 402(상암동, KGIT센터) 24F</td>
              <td>이엘티비 | 서울특별시 마포구 월드컵북로 54길 12 디지털 매직 스페이스 8F</td>
              {/* <td>TEL : 02.516.5502</td> */}
            </tr>
            <tr>
              <td>EMAIL : contact@elwide.com</td>
            </tr>
          </tbody>
        </table>
        <div className="mobile-footer">
          <div>
            <p>이엘와이드, 이엘라이즈 | 서울특별시 마포구 월드컵북로 402(상암동, KGIT센터) 24F</p>
          </div>
          <div>
            <p>이엘티비 | 서울특별시 마포구 월드컵북로 54길 12 디지털 매직 스페이스 8F</p>
          </div>
          <div>
            {/* <p>TEL : 02.516.5502</p>
            <p>FAX : 070.750.7101</p> */}
            <p>EMAIL : contact@elwide.com</p>
          </div>
        </div>
      </div>
      <div className="divider" />
      <div>
        <LogoBlue width="79" height="20" />
        <p>© ELgroup. All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
