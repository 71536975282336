import React from "react";
import { useNavigate } from 'react-router-dom';
import Back from "../assets/images/back-button.png";

const BackButton = () => {
  const navigate = useNavigate();

  const historyBack = () => navigate(-1)

  return (
    <div className="back-button" onClick={historyBack}>
      <img alt="more" src={Back} />
    </div>
  )
}

export default BackButton