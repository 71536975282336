import React, { useEffect, useRef } from 'react';
import AOS from "aos";
import Masonry from 'react-masonry-css';
import { useLocation, useParams } from 'react-router-dom';
import PageTitle from "../../components/PageTitle";
import WhiteSquare from "../../components/WhiteSquare";

import section21 from '../../assets/images/investment-section-2-1.png';
import section22 from '../../assets/images/investment-section-2-2.png';
import section31 from '../../assets/images/investment-section-3-1.png';
import section41 from '../../assets/images/investment-section-4-1.png';
import section51 from '../../assets/images/investment-section-5-1.png';
import investmentSub01 from '../../assets/platform/investment_sub01.png';
import MellispaceSection2 from '../../assets/platform/mobile-ellispace-section-2.jpg';
import movie1 from '../../assets/platform/investment_S03-1.png';
import movie2 from '../../assets/platform/investment_S03-2.png';
import movie3 from '../../assets/platform/investment_S03-3.png';

interface SectionTitle {
  title: string;
  desc: string;
}

const SectionTitleComponent = ({title, desc}:SectionTitle): JSX.Element => {
  return (
    <div className="section-title">
      <p>
        <span>{title}</span>
        <span>{desc}</span>
      </p>
    </div>
  );
}

interface CardListImg {
  src: any;
  className: string;
  duration: number;
}

const CardListImgComponent = ({src, className, duration}:CardListImg): JSX.Element => {
  return (
    <img className={className} src={src} alt="image"
         data-aos="fade-up"
         data-aos-duration={duration}
         data-aos-offset="0"
         data-aos-anchor-placement="top-bottom" />
  );
}

interface MasonryGridImg {
  src: any;
}

const MasonryGridImgComponent = ({src}: MasonryGridImg): JSX.Element => {
  return (
    <div className="image-wrap">
      <img alt="image" src={src} />
    </div>
  );
}

const Investment = () => {
  const location = useLocation();
  const sectionPlatformRef = useRef<any>();
  const sectionBlockchainRef = useRef<any>();
  const sectionContentsRef = useRef<any>();

  useEffect(() => {
    if(!location.search){
      return;
    }
    const param = location.search.replace('?', '');
    const paramList = param.split('&');
    const paramKeys: string[] = [];
    const paramValues: string[] = [];

    paramList.forEach((item) => {
      const arr = item.split('=');
      paramKeys.push(arr[0]);
      paramValues.push(arr[1]);
    });

    // 페이지 파라미터에 대한 키 찾기
    const key = paramKeys.indexOf('page');
    // 존재하지 않으면 수행하지 않음
    if(key === -1) {
      return;
    }

    switch(paramValues[key]) {
      case 'platform':
        if(sectionPlatformRef !== undefined) {
          const rect = sectionPlatformRef.current.getBoundingClientRect();
          window.scrollTo(0, rect.top + window.scrollY - 200);
        }
        break;
      case 'blockchain':
        if(sectionBlockchainRef !== undefined) {
          const rect = sectionBlockchainRef.current.getBoundingClientRect();
          window.scrollTo(0, rect.top + window.scrollY - 200);
        }
        break;
      case 'contents':
        if(sectionContentsRef !== undefined) {
          const rect = sectionContentsRef.current.getBoundingClientRect();
          window.scrollTo(0, rect.top + window.scrollY - 200);
        }
        break;
      default:
        break;
    }

  }, [location]);

  useEffect(() => {
    AOS.refreshHard();
  });

  const breakpointColumnsObj = {
    default: 3,
    1200: 6,
    1060: 5,
    960: 4,
    720: 3,
    520: 2,
  };

  return (
    <div className="investment">
      <div className="section-1">
        <PageTitle
          title="Investment"
          subTitle=""
          description={
            <span>
              블록체인, 플랫폼, 핀테크, 콘텐츠 등 다양한 투자 경험과
              <br />
              글로벌 네트워크를 통해 기업의 잠재력을 발굴합니다.
            </span>
          }
        />
      <WhiteSquare />
      </div> {/* //section-1 */}
      <div className="section-body"> {/* 기존 명칭 : tit-section */}
        <div className="section-content-box section-name-platform" ref={sectionPlatformRef}>
          <SectionTitleComponent
            title={'Platform'}
            desc={'아티스트와 크리에이터 중심의 전방위 디지털 유니버스 플랫폼 사업 투자'}/>
          <div className="section-content">
            <div className="sliding-comment-title-box">
              <p className="txt moving-txt-box"
                 data-aos="fade-right"
                 data-aos-duration="1000"
                 data-aos-anchor-placement="top-bottom">
                <span className="cr_lavender">또 다른 세상</span>에서
                <br />
                <span className="txt_bg">내가 상상했던 모든 것이 현실이 됩니다</span>
              </p>
            </div>
            <div className="comment-title-box">
              <p className="effect-comment-box show-pc"
                 data-aos="fade-right"
                 data-aos-duration="1000"
                 data-aos-anchor-placement="top-bottom">
                <span className="title-bar" />
                <span className="text">게임, 음악, 영화, 공연 등 즐거운</span>
                <span className="cr_lavender">엔터테인먼트 경험 공간</span>
              </p>
              <p className="effect-comment-box show-mobile"
                 data-aos="fade-right"
                 data-aos-duration="1000"
                 data-aos-anchor-placement="top-bottom">
                <span className="title-bar" />
                <span className="box">
                  <span className="text">게임, 음악, 영화, 공연 등 즐거운</span>
                  <span className="cr_lavender">엔터테인먼트 경험 공간</span>
                </span>
              </p>
              <img className="show-pc" src={investmentSub01} alt="image"
                   data-aos="fade-up"
                   data-aos-duration="1000" />
              <img
                className="show-mobile" src={MellispaceSection2} alt="image"
                data-aos="fade-up"
                data-aos-duration="1000" />
            </div>
            <div className="title-and-banner-box">
              <div className="comment-title-box">
                <p className="effect-comment-box show-pc"
                   data-aos="fade-right"
                   data-aos-duration="1000"
                   data-aos-anchor-placement="top-bottom">
                  <span className="title-bar" />
                  재미와 나눔이 있는
                  <span className="cr_lavender">참여형 라이프 스타일 커뮤니티</span>
                </p>
                <p className="effect-comment-box show-mobile"
                   data-aos="fade-right"
                   data-aos-duration="1000"
                   data-aos-anchor-placement="top-bottom">
                  <span className="title-bar" />
                  <span className="box">
                    <span className="text">재미와 나눔이 있는</span>
                    <span className="cr_lavender">참여형 라이프 스타일 커뮤니티</span>
                  </span>
                </p>
              </div>

              <div className="creator-profitable-experience-space"
                   data-aos="fade-up"
                   data-aos-duration="1000">
                <div className="sub-section">
                  <p>
                    어제의 참여자가&nbsp;
                    <br />
                    <span className="cr_lavender">오늘의 크리에이터</span>가 되고
                    <br />
                    모든 활동이 보상이 되는&nbsp;
                    <br />
                    <span className="cr_lavender">수익형 체험 공간</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 블록체인 */}
        <div className="section-content-box section-name-blockchain" ref={sectionBlockchainRef}>
          <SectionTitleComponent
            title={'Blockchain'}
            desc={'스마트 컨트랙트, 커스터마이징 툴 등 미래 4차 산업을 위한 IT기술 분야 투자'}/>
          <div className="section-content">
            <div className="industrial-technology-4th">
              <div className="blue-title title">4차 산업 기술 분야 투자</div>
              <div className="content-list">
                <div className="content">
                  <img src={section21} alt="image"/>
                  <p>
                    블록체인의 생성, 폐기, 교환 등 <br />
                    블록체인 스마트 컨트랙트 <br />
                    트랜잭션 기술
                  </p>
                </div>
                <div className="content">
                  <img src={section22} alt="image"/>
                  <p>사용자의 편의성을 극대화한 <br /> 언리얼 5용 NFT 창작툴 개발</p>
                </div>
              </div>
            </div> {/* //industrial-technology-4th */}
            <div className="card-list">
              <div className="card card-1" data-aos="fade-up">
                <div className="text-wrap"
                     data-aos="fade-up"
                     data-aos-duration="1000"
                     data-aos-anchor-placement="top-bottom">
                  <div className="number">
                    <p>1</p>
                    <div className="background back-color1" />
                  </div>
                  <CardListImgComponent className="show-pc" src={section31} duration={1000}/>
                  <div>
                    <p className="title">블록체인 데이터의<br /><span>실시간 소유권 증명</span></p>
                    <p className="text">
                      탈중앙화 시스템을 사용하는 블록체인에서<br />
                      생성된 토큰의 발행, 교환, 폐기 등 트랜젝션을<br />
                      메타버스가 구현되어 있는 중앙화 서버에<br />
                      실시간으로 데이터를 동기화하고 상호 데이터의<br />
                      무결성을 검증하고 유지하는 방안 연구
                    </p>
                  </div>
                </div>
                <CardListImgComponent className="show-mobile" src={section31} duration={1000}/>
              </div>
              <div className="card card-2" data-aos="fade-up" data-aos-delay="300" data-aos-duration="500">
                <div className="text-wrap" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="0"
                     data-aos-anchor-placement="top-bottom">
                  <div className="number">
                    <p>2</p>
                    <div className="background back-color2" />
                  </div>
                  <CardListImgComponent className="show-pc" src={section41} duration={1000}/>
                  <div>
                    <p className="title">NFT 메타데이터의 <span>표준화</span> 및 <br />
                      저장소의 <span>탈중앙화</span> 연구</p>
                    <p className="text">
                      타 플랫폼에서 발행된 NFT와<br />
                      당사에서 발행된 NFT를 자유롭게 사용하기 위한<br />
                      스마트 컨트랙트 표준안을 기반으로<br />
                      서비스의 장애발생 및 종료와 무관하게<br />
                      NFT의 가치가 훼손되지 않도록<br />
                      탈중앙화된 메타데이터 저장소 연구
                    </p>
                  </div>
                </div>
                <CardListImgComponent className="show-mobile" src={section41} duration={1000}/>
              </div>
              <div className="card card-3" data-aos="fade-up" data-aos-delay="400" data-aos-duration="500">
                <div className="text-wrap" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="0"
                     data-aos-anchor-placement="top-bottom">
                  <div className="number">
                    <p>3</p>
                    <div className="background back-color3" />
                  </div>
                  <CardListImgComponent className="show-pc" src={section51} duration={1500}/>
                  <div>
                    <p className="title">메타버스 내 <br /><span>NFT 제작 어플리케이션</span> 개발</p>
                    <p className="text">
                      메타버스를 이용하는 사용자들의<br />
                      다양한 NFT 창작물을 만들기 위한<br />
                      오브젝트 배치, 변형 자동화 등<br />
                      커스터마이징툴 개발
                    </p>
                  </div>
                </div>
                <CardListImgComponent className="show-mobile" src={section51} duration={1500}/>
              </div>
            </div>
            {/* //card-list */}
          </div>
        </div>
        {/* 콘텐츠 */}
        <div className="section-content-box section-name-contents" ref={sectionContentsRef}>
          <SectionTitleComponent
            title={'Contents'}
            desc={'콘텐츠 시장의 경쟁력 강화 및 글로벌 진출을 위한 영화 등 다양한 콘텐츠 사업 투자'}/>
          <div className="section-content">
            <div>
              <Masonry breakpointCols={breakpointColumnsObj}
                       className="masonry-grid"
                       columnClassName="my-masonry-grid_column"
                       data-aos="fade-up"
                       data-aos-duration="1000"
                       data-aos-anchor-placement="top-bottom">
                <MasonryGridImgComponent src={movie1}/>
                <MasonryGridImgComponent src={movie2}/>
                <MasonryGridImgComponent src={movie3}/>
              </Masonry>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Investment