import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as MobileMenu } from '../assets/images/header-mobile-menu.svg';
import { ReactComponent as SymbolLogoBlue } from '../assets/images/header-symbol-logo-white.svg';
import close from '../assets/images/header-mobile-close.png';
import arrowUp from '../assets/images/header-mobile-up.png';
import arrowDown from '../assets/images/header-mobile-down.png';

interface Props {
  setShowOverlay: Dispatch<SetStateAction<boolean>>;
}

const MobileHeader = ({ setShowOverlay }: Props) => {
  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleMenuOpen = () => {
    setShowMenu(true);
    setShowOverlay(true);
  };

  const handleMenuClose = () => {
    setSelectedMenu(0);
    setShowMenu(false);
    setShowOverlay(false);

    // console.log(window.location);
    // console.log(window.location.pathname);
    // if (window.location.pathname === "/entertainment") {
    //   window.location.replace("/entertainment");
    // }
    // if (window.location.pathname === "/drama") {
    //   window.location.reload();
    // }
    // if (window.location.pathname === "/documentary") {
    //   window.location.reload();
    // }
    // if (window.location.pathname === "/entertainment") {
    //   console.log("entertainment");
    // }
    // if (window.location.pathname === "/drama") {
    //   console.log("drama");
    // }
    // if (window.location.pathname === "/documentary") {
    //   console.log("documentary");
    // }
  };

  const onClickMenu = (index: number) => {
    if (index === selectedMenu) setSelectedMenu(0);
    else setSelectedMenu(index);
  };

  const currentPath = () => {
    return location.pathname.slice(1);
  };

  const isDetailPage = () => {
    const path = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
    if (window.location.pathname === '/entertainment') {
      console.log(window.location.pathname);
    }
    if (window.location.pathname === '/drama') {
      console.log(window.location.pathname);
    }
    if (window.location.pathname === '/documentary') {
      console.log(window.location.pathname);
    }
    return path === '/management-artist' || path === '/news' || path === '/artist-news' || path === '/notice';
  };

  const handleScroll = () => {
    const scrollHeight = document.body.scrollTop || document.documentElement.scrollTop;
    setScrollPosition(scrollHeight);
  };

  const investmentSrchKey = ():string  => {
    if(!location.search){
      return '';
    }
    const param = location.search.replace('?', '');
    const paramList = param.split('&');
    const paramKeys: string[] = [];
    const paramValues: string[] = [];

    paramList.forEach((item) => {
      const arr = item.split('=');
      paramKeys.push(arr[0]);
      paramValues.push(arr[1]);
    });

    // 페이지 파라미터에 대한 키 찾기
    const key = paramKeys.indexOf('page');
    return key === -1 ? '' : paramValues[key];
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const enterPage = () => {
    window.location.replace('/entertainment');
  };

  return (
    <div className="mobile-header">
      {showMenu || (
        <div className={`top-menu ${scrollPosition >= 40 || isDetailPage() ? 'full' : 'void'}`}>
          <MobileMenu width={24} height={24} onClick={handleMenuOpen} />
          <Link to="/">
            <SymbolLogoBlue width={109} height={22} />
          </Link>
          <div style={{ width: '24px', height: '24px' }} />
        </div>
      )}
      {showMenu && (
        <div className="left-menu">
          <img className="close-button" alt="image" src={close} onClick={handleMenuClose} />
          <div className="menu-wrap">
            <div
              className="main-menu"
              onClick={() => {
                onClickMenu(1);
              }}
            >
              <p>ABOUT US</p>
              <img alt="image" src={selectedMenu === 1 ? arrowUp : arrowDown} />
            </div>
            {selectedMenu === 1 && (
              <div className="sub-menu">
                <Link to="/story" onClick={handleMenuClose}>
                  <p className={`white ${currentPath() === '' ? 'selected' : ''}`}>EL Story</p>
                </Link>
                <Link to="/story" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'story' ? 'selected' : ''}`}>경영철학</p>
                </Link>
                <Link to="/history" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'history' ? 'selected' : ''}`}>회사연혁</p>
                </Link>
                <Link to="/culture" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'culture' ? 'selected' : ''}`}>이엘문화</p>
                </Link>
                <Link to="/family" onClick={handleMenuClose}>
                  <p className={`white ${currentPath() === 'family' ? 'selected' : ''}`}>EL Family</p>
                </Link>
                <a href="http://elwide.kr/" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'el-wide' ? 'selected' : ''}`}>이엘와이드</p>
                </a>
                <a href="http://elrise.kr/" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'el-rise' ? 'selected' : ''}`}>이엘라이즈</p>
                </a>
                <a href="http://eltv.kr/" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'el-tv' ? 'selected' : ''}`}>이엘티비</p>
                </a>
              </div>
            )}
          </div>
          <div className="menu-wrap">
            <div
              className="main-menu"
              onClick={() => {
                onClickMenu(2);
              }}
            >
              <p>BUSINESS</p>
              <img alt="image" src={selectedMenu === 2 ? arrowUp : arrowDown} />
            </div>
            {selectedMenu === 2 && (
              <div className="sub-menu">
                <Link to="/documentary" onClick={handleMenuClose}>
                  <p className="white">Entertainment</p>
                </Link>
                <Link
                  to="/documentary"
                  onClick={() => {
                    handleMenuClose();
                    window.location.replace('/documentary');
                  }}
                >
                  <p className={`gray ${currentPath() === 'documentary' ? 'selected' : ''}`}>다큐멘터리</p>
                </Link>
                <Link to="/music" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'music' ? 'selected' : ''}`}>뮤직</p>
                </Link>
                {/* <Link to="/platform" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'platform' ? 'selected' : ''}`}>플랫폼</p>
                </Link> */}
                <Link to="/brands" onClick={handleMenuClose}>
                  <p className={`white ${currentPath() === 'brands' ? 'selected' : ''}`}>Brands</p>
                </Link>
                <Link to="/healthy" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'healthy' ? 'selected' : ''}`}>헬시플레이스</p>
                </Link>
                <Link to="/inhever" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'inhever' ? 'selected' : ''}`}>인헤버</p>
                </Link>
                <Link to="/ibim" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'ibim' ? 'selected' : ''}`}>아이빔</p>
                </Link>
                <Link to="/gshi" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'gshi' ? 'selected' : ''}`}>지샤인</p>
                </Link>
                <Link to="/ruthair" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'ruthair' ? 'selected' : ''}`}>루트헤어</p>
                </Link>
                <Link to="/investment" onClick={handleMenuClose}>
                  <p className={`white ${currentPath() === 'investment' && investmentSrchKey() === '' ? 'selected' : ''}`}>Investment</p>
                </Link>
                <Link to="/investment?page=platform" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'investment' && investmentSrchKey() === 'platform' ? 'selected' : ''}`}>Platform</p>
                </Link>
                <Link to="/investment?page=blockchain" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'investment' && investmentSrchKey() === 'blockchain' ? 'selected' : ''}`}>Block chain</p>
                </Link>
                <Link to="/" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'FinTech' && investmentSrchKey() === 'fintech' ? 'selected' : ''}`}>FinTech</p>
                </Link>
                <Link to="/investment?page=contents" onClick={handleMenuClose}>
                  <p className={`gray ${currentPath() === 'investment' && investmentSrchKey() === 'contents' ? 'selected' : ''}`}>Contents</p>
                </Link>
              </div>
            )}
          </div>
          {/* <div className="menu-wrap"> */}
          {/*   <div className="main-menu"> */}
          {/*     <p onClick={() => {onClickMenu(3)}}>NEWS</p> */}
          {/*     <img alt="image" src={selectedMenu === 3 ? arrowUp : arrowDown} /> */}
          {/*   </div> */}
          {/*   { selectedMenu === 3 && */}
          {/*     <div className="sub-menu"> */}
          {/*       <Link to="/press-release" onClick={handleMenuClose}> */}
          {/*         <p className={`white ${currentPath() === 'press-release' ? 'selected' : ''}`}>Press release</p> */}
          {/*       </Link>  */}
          {/*          <Link to="/EL Now" onClick={handleMenuClose}> */}
          {/*            <p className={`white ${currentPath() === 'EL Now' ? 'selected' : ''}`}>EL Now</p> */}
          {/*          </Link> */}
          {/*     </div> */}
          {/*   } */}
          {/* </div> */}
          {/* <div className="menu-wrap">
            <div className="main-menu">
              <Link to="/press-release" onClick={handleMenuClose}>
                <p className={`${currentPath() === 'press-release' ? 'selected' : ''}`}>NEWS</p>
              </Link>
            </div>
          </div> */}
          <div className="menu-wrap">
            <div
              className="main-menu"
              onClick={() => {
                onClickMenu(3);
              }}
            >
              <p>NEWS</p>
              <img alt="image" src={selectedMenu === 3 ? arrowUp : arrowDown} />
            </div>
            {selectedMenu === 3 && (
              <div className="sub-menu">
                <Link to="/press-release" onClick={handleMenuClose}>
                  <p className={`white ${currentPath() === 'press-release' ? 'selected' : ''}`}>Press Release</p>
                </Link>
                <Link to="/elnow" onClick={handleMenuClose}>
                  <p className={`white ${currentPath() === 'elnow' ? 'selected' : ''}`}>EL Now</p>
                </Link>
                <Link to="/notice" onClick={handleMenuClose}>
                  <p className={`white ${currentPath() === 'notice' ? 'selected' : ''}`}>Notice</p>
                </Link>
                {/* <Link to="/notice" onClick={handleMenuClose}> */}
                {/*   <p className={`white ${currentPath() === 'notice' ? 'selected' : ''}`}>Notice</p> */}
                {/* </Link> */}
              </div>
            )}
          </div>
          <div className="menu-wrap">
            {/* <div className="main-menu">
              <Link to="/contact" onClick={handleMenuClose}>
                <p className={`${currentPath() === 'contact' ? 'selected' : ''}`}>CONTACT</p>
              </Link>
            </div> */}
            <div
              className="main-menu"
              onClick={() => {
                onClickMenu(4);
              }}
            >
              <p>CONTACT</p>
              <img alt="image" src={selectedMenu === 4 ? arrowUp : arrowDown} />
            </div>
            {selectedMenu === 4 && (
              <div className="sub-menu">
                <Link to="/contact" onClick={handleMenuClose}>
                  <p className={`white ${currentPath() === 'contact' ? 'selected' : ''}`}>오시는 길</p>
                </Link>
                <Link to="/alliance" onClick={handleMenuClose}>
                  <p className={`white ${currentPath() === 'alliance' ? 'selected' : ''}`}>제휴 제안</p>
                </Link>
              </div>
            )}
          </div>
          <div className="menu-wrap">
            {/* <div className="main-menu">
              <Link to="/recruit" onClick={handleMenuClose}>
                <p className={`${currentPath() === 'recruit' ? 'selected' : ''}`}>RECRUIT</p>
              </Link>
            </div> */}
            <div
              className="main-menu"
              onClick={() => {
                onClickMenu(5);
              }}
            >
              <p>RECRUIT</p>
              <img alt="image" src={selectedMenu === 5 ? arrowUp : arrowDown} />
            </div>
            {selectedMenu === 5 && (
              <div className="sub-menu">
                <Link to="/recruit" onClick={handleMenuClose}>
                  <p className={`white ${currentPath() === 'recruit' ? 'selected' : ''}`}>인재 채용</p>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
