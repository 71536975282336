import React from 'react';

import image2 from '../../assets/brands/healthy-2.jpg'
import image3 from '../../assets/brands/healthy-3.jpg'
import image4 from '../../assets/brands/healthy-4.jpg'
import image5 from '../../assets/brands/healthy-5.jpg'

import BackButton from "../../components/BackButton";
import ShopButton from "../../components/ShopButton";

const Healthy = () => {
  return (
    <div className="healthy">
      <div className="section-1">
        <div className="section-1-text-wrap">
          <p className="section-1-title" data-aos="fade-left" data-aos-duration="1000">헬시플레이스</p>
          <p style={{marginBottom: "33px"}} data-aos="fade-left" data-aos-delay="300" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">건강한 몸과 마음에서 비롯되는<br className="show-mobile" /> 진정한 아름다움을 위한<br />
            균형 잡힌 헬스&푸드 라이프스타일을 제안합니다</p>

          <p style={{marginBottom: "33px"}} data-aos="fade-left" data-aos-delay="600" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">복잡하고 어려운 건강관리가 아닌<br />
            일상에서도 간편하지만 맛있게 할 수 있는<br className="show-mobile" /> 즐거운 건강관리</p>

          <p style={{marginBottom: "33px"}} data-aos="fade-left" data-aos-delay="900" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">나를 가꾸고 사랑하는 건강한 일상의 시작<br className="show-mobile" /> 헬시플레이스입니다</p>
        </div>
      </div>
      <div className="section-2" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
        <div className="brand-logo show-pc" style={{ backgroundImage: `url(${image5})` }} />
        <div className="section-3">
          <div className="section-4">
            <div className="product-image-1" style={{ backgroundImage: `url(${image2})` }} />
            <div className="section-6">
              <div className="product-image-2" style={{ backgroundImage: `url(${image3})` }} />
              <div className="product-image-3" style={{ backgroundImage: `url(${image4})` }} />
            </div>
          </div>
          <div className="brand-logo show-mobile" style={{ backgroundImage: `url(${image5})` }} />
          <div className="section-5">
            <p className="title">헬시플레이스</p>
            <p className="description">건강하고 균형 잡힌 <br />푸드라이프를 약속합니다</p>
            <a href="http://healthyplace.kr/">
              <ShopButton />
            </a>
          </div>
        </div>
      </div>
      <BackButton />
    </div>
  )
}

export default Healthy