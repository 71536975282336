import React, {useState, Dispatch, SetStateAction, useEffect} from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import share from '../assets/images/share-button.png'
import BackButton from "../components/BackButton";

import CONFIG from "../config";

interface Props {
  setShowOverlay: Dispatch<SetStateAction<boolean>>
}

interface INewsItem {
  "idx": string,
  "press_title": string,
  "press_contents": string,
  "press_thumb": string,
  "press_date": string
}

const News = () => {

  const { id } = useParams();

  const [showAlert, setShowAlert] = useState(false)
  const [news, setNews] = useState<INewsItem>()

  const close = () => setShowAlert(false)
  const copyURL = () => {
    setShowAlert(true)
    const el = document.createElement('input');
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  useEffect(() => {
    axios.get(`${CONFIG.API_CALL_URL()}/news/press_info.php?idx=${id}`)
      .then((response) => setNews(response.data.Result_Data.data))
      .catch((error) => console.log(error));
  }, [])

  return (
    <>
      {showAlert &&
        <div className="news-overlay" />
      }
      <div className="news-page">
        <div className="title">{news?.press_title}</div>
        <div>
          <div className="date">{moment(news?.press_date).format('YYYY.MM.DD')}</div>
          <div className="share-button" onClick={copyURL}>
            <img alt="image" src={share} />
          </div>
        </div>
        <div>
          <div className="image-wrap">
            <img alt="image" src={`${CONFIG.FILE_URL()}/storage/news/press/${news?.press_thumb}`} />
          </div>
          <pre>{news?.press_contents}</pre>
        </div>
        <BackButton />
        {showAlert &&
          <div className="alert-box">
            <p>URL이 복사되었습니다.</p>
            <div onClick={close}>확인</div>
          </div>
        }
      </div>
    </>
  )
}

export default News