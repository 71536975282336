import React, { useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import FamilyNavigation from "../../components/FamilyNavigation";

const ElWide = () => {

  useEffect(() => {
    window.location.href = "http://elwide.kr"
  }, [])

  return (
    <>
      <Helmet>
        <title>이엘와이드</title>
        <meta name="description" content="이엘그룹 지주회사, 그룹사 소개, 주요사업, 투자, 사회공헌"/>
        <meta name="Keywords" content="(주)이엘와이드, 이엘와이드, ELwide, elwide"/>
        <meta property="og:title" content="이엘와이드"/>
        <meta property="og:site_name" content="이엘와이드"/>
        <meta property="og:url" content="http://elwide.kr"/>
        <meta property="og:description" content="이엘그룹 지주회사, 그룹사 소개, 주요사업, 투자, 사회공헌"/>
        <meta name="twitter:title" content="이엘와이드"/>
        <meta name="twitter:description" content="이엘그룹 지주회사, 그룹사 소개, 주요사업, 투자, 사회공헌"/>
        <meta name="twitter:domain" content="이엘와이드"/>
        <link rel="canonical" href="http://elwide.kr"/>
      </Helmet>
      <div className="family-sub-el-wide">
        <div className="text-wrap">
          <div className="title-1">ELwide</div>
          <div className="title-2">이엘<span>와이드_</span></div>
          <div className="description"><b>이엘그룹의 지주사</b>로<br className="show-mobile" /> 그룹 성장 전략 수립 및<br className="show-mobile" /> 미래 가치를 위한 전략적 투자와 <br />
            신규 사업 기획을 통해<br className="show-mobile" /> 엔터테인먼트 사업 강화를<br className="show-mobile" /> 주도합니다</div>
        </div>
        <FamilyNavigation />
      </div>
    </>
  )
}

export default ElWide